import { DictString } from 'app/shared/models';
import { WebLazyRelation } from './web-lazy-relation.model';
import { WebPointerStatus } from './web-pointer-status.enum';
import { WebRelationPointer } from './web-relation-pointer.model';


export interface IWebObject {

  guidId: string;
  typeGuidId: string;
  siteGuidId: string;
  sysId: string;
  defaultLanguageValuesLanguageGuidId: string;
  parentGuidId: string;
  environmentGuidId: string;
  collectionObject: boolean;
  createdDateTime: string;
  changedDateTime: string;
  web2Type: string;
  web2Status: WebPointerStatus;
  web2LoadTemplate: 'Base' | 'List' | 'Complete';

  members: any;
  languageMembers: any;
  lazyRelations: DictString<WebLazyRelation>;
  relations: DictString<WebRelationPointer | WebRelationPointer[]>;
  subTypes: DictString<WebObject>;

  unsupportedWeb2Type: string;
  
  $originalWebObject: IWebObject;
}
export class WebObject implements IWebObject {

  guidId: string;
  typeGuidId: string;
  siteGuidId: string;
  sysId: string;
  defaultLanguageValuesLanguageGuidId: string;
  parentGuidId: string;
  environmentGuidId: string;
  collectionObject: boolean;
  createdDateTime: string;
  changedDateTime: string;
  web2Type: string;
  web2Status: WebPointerStatus;
  web2LoadTemplate: 'Base' | 'List' | 'Complete';

  members: any;
  languageMembers: any;
  lazyRelations: DictString<WebLazyRelation>;
  relations: DictString<WebRelationPointer | WebRelationPointer[]>;
  subTypes: DictString<WebObject>;

  unsupportedWeb2Type: string;

  $originalWebObject: IWebObject;

  constructor(item?: Partial<WebObject>) {
    Object.assign(this, item);

    this.members = this.members || {};

    for (const key of Object.keys(this.relations || {})) {
      if (Array.isArray(this.relations[key])) {
        this.relations[key] = (this.relations[key] as WebRelationPointer[])
        .map((rp: WebRelationPointer) => {
          return new WebRelationPointer(rp);
        });
      } else {
        this.relations[key] = new WebRelationPointer(this.relations[key] as WebRelationPointer);
      }
    }

    for (const key of Object.keys(this.subTypes || {})) {
      this.subTypes[key] = !this.subTypes[key]?.unsupportedWeb2Type ? new WebObject(this.subTypes[key]) : this.subTypes[key];
    }

    this.updateOriginalWebObject();
  }

  addRelationPointer(relationName: string, relationPointer: WebRelationPointer, collectionRelation: boolean): void {
    if (collectionRelation) {
      this.relations[relationName] = this.relations[relationName] || [];
      const existingRP = (this.relations[relationName] as WebRelationPointer[]).find(item => item.relationGuidId === relationPointer.relationGuidId);
      if (!existingRP) {
        (this.relations[relationName] as WebRelationPointer[]).push(relationPointer);
      }
    } else {
      this.relations[relationName] = relationPointer;
    }
  }

  addRelationPointers(relationName: string, relationPointers: WebRelationPointer[]): void {
    this.relations[relationName] = this.relations[relationName] || [];
    for (const rp of relationPointers || []) {
      const existingRP = (this.relations[relationName] as WebRelationPointer[]).find(item => item.relationGuidId === rp.relationGuidId);
      if (!existingRP) {
        (this.relations[relationName] as WebRelationPointer[]).push(rp);
      }
    }
  }

  getRelationPointer(relationName: string, objectGuidId?: string): WebRelationPointer | WebRelationPointer[] {
    const relationPointerOrPointers = this.relations ? this.relations[relationName] : null;
    if (objectGuidId) {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.find((wrp: WebRelationPointer) => {
          return wrp.childGuidId === objectGuidId;
        });
      } else {
        return relationPointerOrPointers?.childGuidId === objectGuidId ? relationPointerOrPointers : null;
      }
    } else {
      return relationPointerOrPointers;
    }
  }

  getRelation(relationName: string, objectGuidId?: string): WebObject | WebObject[] {
    const relationPointerOrPointers = this.relations ? this.relations[relationName] : null;
    if (objectGuidId) {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.find((wrp: WebRelationPointer) => {
          return wrp.childGuidId === objectGuidId;
        })?.web2Object;
      } else {
        return relationPointerOrPointers?.childGuidId === objectGuidId ? relationPointerOrPointers?.web2Object : null;
      }
    } else {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.map((rp: WebRelationPointer) => {
          return rp.web2Object;
        });
      } else {
        return relationPointerOrPointers?.web2Object;
      }
    }
  }

  getSubType(subTypeName: string): WebObject {
    return this.subTypes ? this.subTypes[subTypeName] : null;
  }

  updateOriginalWebObject() {
    this.$originalWebObject = JSON.parse(JSON.stringify(this));
    delete this.$originalWebObject.$originalWebObject;
  }
}
