import { CaseUtils } from 'app/shared/utils';
import { WebCenterTypeMember, WebCenterTypeMemberSpecialValue, WebCenterTypeMemberSpecialValueType } from './web-center-type-member.model';
import { WebCenterTypeRelation } from './web-center-type-relation.model';


export class WebCenterType {

  guidId: string;
  typeGuidId: string;
  name: string;
  rootTypeGuidId: string;
  tick: number;
  web2Name: string;
  web2Ready: boolean;
  isLanguageType: boolean;

  collections: any[];
  members: WebCenterTypeMember[];
  relations: WebCenterTypeRelation[];
  subTypeGuidIds: string[];

  $customWorkspaceNavigatorModuleSuffix: string;

  constructor(item?: Partial<WebCenterType>) {
    Object.assign(this, item);

    this.members = (this.members || []).map((ctm: WebCenterTypeMember) => {
      return new WebCenterTypeMember(ctm);
    });

    this.relations = (this.relations || []).map((ctr: WebCenterTypeRelation) => {
      return new WebCenterTypeRelation(ctr);
    });

    this.addAdditionalClientSideInfo();
  }

  // getRelationsByTypeTreeRelationGuidId(typeTreeRelationGuidId: string): TypeTreeRelation[] {
  //   return this.relations.filter((ttr: TypeTreeRelation) => {
  //     return ttr.typeTreeRelationGuidId === typeTreeRelationGuidId;
  //   });
  // }

  private addAdditionalClientSideInfo() {
    // need to add an extra $key to the member objects -> lowercase name
    this.members = (this.members || [])
    .map((m: WebCenterTypeMember) => {
      m.$key = CaseUtils.camelize(m.name, true);
      return m;
    });

    for (const relation of this.relations || []) {
      for (const member of relation.relationMembers || []) {
        member.$key = (member.name || '').toLowerCase();
        this.mapServerTypesAndTexts(member);
      }
    }

    // then, I map out the server types to HTML types...or special types
    for (const member of this.members || []) {
      this.mapServerTypesAndTexts(member);
    }
    
    // for (const relation of this.relations || []) {
    //   if (relation.relationMembers) {
    //     for (const member of relation.relationMembers || []) {
    //       this.mapServerTypesAndTexts(member);
    //     }
    //   }
    // }
  }

  private mapServerTypesAndTexts(member: WebCenterTypeMember) {
    if (/*member.specialMemberType && */member.specialValues && member.specialValuesType === WebCenterTypeMemberSpecialValueType.Normal) {
      member.$fieldType = 'dropdown';
      member.$fieldOptions = (member.specialValues || [])
      .map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (/*member.specialMemberType && */member.specialValues && member.specialValuesType === WebCenterTypeMemberSpecialValueType.IsFlags) {
      member.$fieldType = 'multi-select-flags';
      member.$fieldOptions = (member.specialValues || [])
      .map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (member.specialMemberType === 'DateTime') {
      member.$fieldType = 'date';
    } else if (member.specialMemberType === 'WizardJson' || member.specialMemberType === 'WizardUI') {
      member.$fieldType = 'json';
    } else if (member.specialMemberType) {
      member.$fieldType = (member.specialMemberType || '').toLowerCase();
    } else {
      switch (member.nativeType) {
        case 'System.String':
          member.$fieldType = 'text';
          break;
        case 'System.Int32':
        case 'System.Int64':
        case 'System.Decimal':
        case 'System.Double':
          member.$fieldType = 'number';
          break;
        case 'System.Boolean':
          member.$fieldType = member.allowNull ? 'checkbox3' : 'checkbox';
          break;
      }
    }
  }

}
