export const RFM_TREECHILD = 'TreeChild';
export const ENVIRONMENT_GUIDID = 'f9b36743-cf07-4e4f-bb2b-bd11565af1d7';

export interface RelationDataRequest {
  guidId: string;
  relationFilterMode?: typeof RFM_TREECHILD;
  siteGuidId: string;
  typeTreeRelationGuidId: string;
  wildcardSearch: string; // not implemented
  environmentGuidId: string;
}
