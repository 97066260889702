import { GuidUtils } from '../utils/guid.utils';
import { SiteTree } from './site-tree.model';
import { TreeRelationSource } from './tree-relation-source.model';

/*
"Real" TreeSource - contains relations between different objects, is not readonly.
Can be Structural/None structural. The difference beween a structural and none structural is the nature of it.
Structural contains only site/users/Groups and also permissions and stuff.
A none structural is for each root object (tank), and caontains all relations between that tank.
So tank->Product, tank->model, tank->activeorder
"Design" treeSource - system-compiled trees that is built from the real treesources.
Are help trees to be able to not compute the real treesources (structural) all the time. Are READONLY.
*/
export const enum TreeType {
  FullTree = 0,  // FULL tree for a specific object. USE IT ONLY FOR ADMIN PURPOSES
  DesignTree = 1 // System-Compiled / Generated / Operational Tree for a specific object.
                 // Contains only the sites under the specific object
}

/*
USER - Is the specific unique DesignTree that is built with only LogicSItes that the user has permissions to.
And one any specific site the calculated permission (that is calculated when we combine Groups and stuff)
STRUCTURAL - is the specific unique DesignTree that is all the filter trees used for that specific site
(so this is what you are going to build the filter trees that is on the Tank & Order Page)
FILTER - This is a combination when you Query "Give me all logicType = Tank that somwhere belong to designTreeGuidId = X) - then
the WebAPI uses that specific designTree for that site, identifies all Tanks and dynamicly creats a new "FilterGuidIdDesignTree" that adds the relations as if they were there.
*/
export const enum DesignTreeSourceType {
  NotDesignTree = 0,
  User = 1, // if it's a tree for a USER object
  Structural = 2, // if it's a tree for a SITE object
  Filter = 3 // relationships between all objects of a specific type and their parents (sites)
}

export class TreeSource {

  treeGuidId: string;
  // typeGuidId: string;
  // profileGuidId: string;
  set siteGuidId (value: string) {
    this._siteGuidId = value;
    this.isDirty = true;
  }
  get siteGuidId(): string { return this._siteGuidId; }
  private _siteGuidId: string;

  treeRelationSources: TreeRelationSource[];
  tick: number;
  active: boolean;
  //guidId: string
  // isNew: boolean;
  isDirty: boolean;
  siteTree: SiteTree;
  webTreeType: TreeType;
  designTreeSourceType: DesignTreeSourceType;




  constructor(
    treeGuidId: string,
    siteGuidId: string,
    parentGuidId: string,
    parentTypeGuidId: string,
    childGuidId: string,
    childTypeGuidId: string,
  ) {
    this.treeGuidId = treeGuidId ? treeGuidId : GuidUtils.new();
    this.siteGuidId = siteGuidId;

    let tr = new TreeRelationSource(parentGuidId, parentTypeGuidId, childGuidId, childTypeGuidId);
    this.treeRelationSources = [tr];

    this.active = true;
    this.isDirty = true;
  }

  /**
   * @deprecated
   */
  setNewFlags() {
    this.active = true;
    this.isDirty = true;
    for (const trs of this.treeRelationSources) {
      trs.active = true;
      trs.isNew = true;
      trs.isDirty = true;
    }
  }

  /**
   * @deprecated
   */
  setDirtyFlags(active: boolean, isNew: boolean) {
    this.isDirty = true;
    this.treeRelationSources[0].active = active;
    this.treeRelationSources[0].isNew = isNew;
    this.treeRelationSources[0].isDirty = true;
  }

  toJSON() {
    const obj = Object.assign({}, this);

    for (const key of Object.keys(obj)) {
      if (key[0] === '_') {
        obj[key.substring(1)] = this[key];
        delete obj[key];
      }
    }

    return obj;
  }

  deactivate(): void {
    for (const trs of this.treeRelationSources) {
      trs.deactivate();
    }
    this.active = false;
    this.isDirty = true;
  }

  addOrUpdateRelation(trs: TreeRelationSource) {
    this.treeRelationSources = this.treeRelationSources || [];

    const existingIndex = this.treeRelationSources.findIndex((r: TreeRelationSource) => {
      return r.guidId === trs.guidId;
    });
    if (existingIndex > 0) {
      this.treeRelationSources.splice(existingIndex, 1);
    }

    this.treeRelationSources.push(trs);

    this.isDirty = true;
  }

  deactivateRelation(trs: TreeRelationSource) {
    if (trs) {
      trs.deactivate();

      this.isDirty = true;
    }
  }

  removeRelation(trs: TreeRelationSource) {
    if (trs) {
      this.treeRelationSources = this.treeRelationSources || [];

      const existingIndex = this.treeRelationSources.findIndex((r: TreeRelationSource) => {
        return r.guidId === trs.guidId;
      });
      if (existingIndex > 0) {
        this.treeRelationSources.splice(existingIndex, 1);
      }

      this.isDirty = true;
    }
  }

  getRootRelation(): TreeRelationSource {
    return (this.treeRelationSources || []).find((trs: TreeRelationSource) => {
      return trs.isRootObject;
    });
  }

  getRelationByGuidId(guidId: string): TreeRelationSource {
    return (this.treeRelationSources || []).find((trs: TreeRelationSource) => {
      return trs.guidId === guidId;
    });
  }

  getRelationByChildGuidId(childGuidId: string): TreeRelationSource {
    return (this.treeRelationSources || []).find((trs: TreeRelationSource) => {
      return trs.childGuidId === childGuidId;
    });
  }

  getRelationsByTypeTreeRelationGuidId(typeTreeRelationGuidId: string): TreeRelationSource[] {
    return (this.treeRelationSources || []).filter((trs: TreeRelationSource) => {
      return trs.typeTreeRelationGuidId === typeTreeRelationGuidId &&
        trs.active;
    });
  }

  getDirectChildRelationsByTypeGuidId(parentGuidId: string, childTypeGuidId: string): TreeRelationSource[] {
    return (this.treeRelationSources || []).filter((trs: TreeRelationSource) => {
      return trs.parentGuidId === parentGuidId &&
        trs.childTypeGuidId === childTypeGuidId;
    });
  }

}
