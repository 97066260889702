import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ConfirmDialogModule } from 'app/shared/dialogs/confirm/confirm.dialog.module';
import { InfoDialogModule } from 'app/shared/dialogs/info/info.dialog.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TreeModule } from 'primeng/tree';
import { ButtonComponentModule } from '../button/button.component.module';
import { SearchInputModule } from '../search-input/search-input.module';
import { SearchTreeComponent } from './components';
import { TreeComponent } from './tree.component';


@NgModule({
  declarations: [
    SearchTreeComponent,
    TreeComponent,
  ],
  exports: [
    SearchTreeComponent,
    TreeComponent,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    ConfirmDialogModule,
    ContextMenuModule,
    InfoDialogModule,
    NgxLoadingModule,
    SearchInputModule,
    TreeModule,
  ],
  providers: [],
})
export class TreeComponentModule { }

