import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { format } from 'date-fns';
import { SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Dropdown } from 'primeng/dropdown';



const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormFieldComponent),
  multi: true
};

@Component({
  selector: 'lc-form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FormFieldComponent implements ControlValueAccessor, OnInit {

  static knownFieldTypes = [
    'checkbox',
    'checkbox3',
    'color',
    'date',
    'datetime',
    'dropdown',
    'link',
    'multi-select',
    'multi-select-flags',
    'number',
    'password',
    'relation',
    'textarea',
    'text',
    'warehouselocationarticletype'
  ];

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild(Calendar) calendar: Calendar;
  @ViewChild(Dropdown) dropdown: Dropdown;
  @ViewChild('inputElement') inputElement: any;

  @Input() appendTo: any;
  @Input() autoFocus: boolean;
  @Input() disabled: boolean;
  @Input() dropdownGroup: boolean;
  @Input() dropdownShowClear: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() set maxDate(value: Date | string) {
    this._maxDate = this.getDateValue(value);
  }
  get maxDate(): Date | string {
    return this._maxDate;
  }
  private _maxDate: Date | string;
  @Input() maxLength: number;
  @Input() maxNumber: number;

  @Input() set minDate(value: Date | string) {
    this._minDate = this.getDateValue(value);
  }
  get minDate(): Date | string {
    return this._minDate;
  }
  private _minDate: Date | string;
  @Input() minLength: number;
  @Input() minNumber: number;

  @Input() options: SelectItem[];
  @Input() optionLabel: string;
  @Input() pattern: string;
  @Input() placeholder: string;
  @Input() relationEditActive: boolean;
  @Input() relationLinkActive: boolean;
  @Input() required: boolean;
  @Input() textAreaRows: number;
  @Input() type: 'checkbox' | 'checkbox3' | 'color' | 'date' | 'datetime' | 'dropdown' | 'link' | 'multi-select' | 'multi-select-flags' | 'number' | 'password' | 'relation' | 'textarea' | 'text';

  @Output() labelIconRightClick: EventEmitter<void>;
  @Output() onBlur: EventEmitter<any>;
  @Output() relationLinkClick: EventEmitter<void>;
  @Output() relationEditClick: EventEmitter<void>;
  @Output() valueLinkClick: EventEmitter<void>;

  onChange: (x: any) => {};
  onTouched: () => {};

  dateValue: Date;
  dropdownValue: string;
  locale: any;
  multiSelectFlagsValue: number[];
  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.labelIconRightClick = new EventEmitter();
    this.onBlur = new EventEmitter();
    this.relationLinkClick = new EventEmitter();
    this.relationEditClick = new EventEmitter();
    this.valueLinkClick = new EventEmitter();

    this.locale = {
      firstDayOfWeek: 1,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su','Mo','Tu','We','Th','Fr','Sa'],
      monthNames: [ 'January','February','March','April','May','June','July','August','September','October','November','December' ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Wk'
    };
  }

  private getDateValue(value: Date | string): Date {
    if (value) {
      if (value instanceof Date) {
        return value;
      } else {
        return new Date(value + (value.indexOf('T') < 0 ? 'T00:00:00Z' : ''));
      }
    } else {
      return undefined;
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value != value) {
      this.value = value;

      if (this.value && (this.type || '').indexOf('date') >= 0) {
        this.dateValue = this.getDateValue(this.value);
        this.updateValueWithDate(this.dateValue);
      } else if (this.value && this.type === 'color') {
        setTimeout(() => {
          this.onChange(this.value); // this is required for the color picker to display the initial value...
        });
      } else if (this.type === 'checkbox') {
        this.value = this.value === 'true' || this.value === true;
      } else if (this.type === 'dropdown') {
        const option = (this.options || []).find((item: any) => {
          return item.value != null ? item.value == this.value : item === this.value;
        });
        this.dropdownValue = option ? option[this.optionLabel || 'label'] : undefined;
      } else if (this.type === 'multi-select-flags') {
        this.multiSelectFlagsValue = this.getMultiSelectValue(this.value);
      }
      
      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {
    // this.type = this.type === 'datetime' ? 'date' : this.type;

    setTimeout(() => {
      if (this.type === 'textarea') {
        this.disableKeysPropagation();
      }

      if (this.autoFocus) {
        this.tryToFocusInputElement();
      }
    }, 500);
  }

  tryToFocusInputElement(): void {
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  updateValueWithNumber(value: string) {
    this.onChange(value != null ? parseFloat(value) : value);
    this.cdr.markForCheck();
  }

  updateValueWithDate(dt: Date) {
    this.value = dt ? format(dt, 'yyyy-MM-dd') : undefined;

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  disableKeysPropagation() {
    if (this.inputElement) {
      this.inputElement.nativeElement.addEventListener('keydown', (event) => {
        const keyCode = event.keyCode;

        const KEY_ENTER = 13;
        const KEY_LEFT = 37;
        const KEY_UP = 38;
        const KEY_RIGHT = 39;
        const KEY_DOWN = 40;
        const KEY_PAGE_UP = 33;
        const KEY_PAGE_DOWN = 34;
        const KEY_PAGE_HOME = 36;
        const KEY_PAGE_END = 35;
        const preventKeyCodes = [KEY_ENTER, KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN, KEY_PAGE_UP, KEY_PAGE_DOWN, KEY_PAGE_HOME, KEY_PAGE_END];

        if (preventKeyCodes.indexOf(keyCode) >= 0) {
            // this stops any other handlers from executing keyboard navigation
            event.stopPropagation();
        }
      });
    }
  }

  showPicker() {
    if (this.dropdown) {
      this.dropdown.show();
      this.cdr.markForCheck();
    } else if (this.calendar) {
      this.calendar.showOverlay();
      this.cdr.markForCheck();
    }
  }

  private getMultiSelectValue(value: number): number[] {
    if (value) {
      const multiSelectFlagsValue = [];
      for (const option of this.options || []) {
        if ((value & option.value) === option.value) {
          multiSelectFlagsValue.push(option.value);
        }
      }
      return multiSelectFlagsValue;
    } else {
      return undefined;
    }
  }

  setValueFromMultiSelectFlagsValue(selectedValues: number[]) {
    this.value = undefined;
    for (const selectedValue of selectedValues || []) {
      this.value |= selectedValue;
    }

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

}
