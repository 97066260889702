import { DictString, SolutionType } from 'app/shared/models';
import { JsonUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api/selectitem';
import { FilterStringFilter } from './filter-string/filter-string-filter.model';
import { WebObject } from './web-base';
import { WorkspaceSolutionTypeMember } from './workspace-solution-type-member.model';

interface StyleMap {
  members: WorkspaceSolutionTypeMember[];
  [key: string]: any;
}

export class WorkspaceSolutionType extends SolutionType {

  workspaceGuidId: string;

  canAdd: boolean;
  canDelete: boolean;
  mainLabelPlaceholder: string;
  mainLabelMember: any;

  // specialActions: SelectItem[]; // renamed
  runSystemActions: SelectItem[];
  runSystemReports: SelectItem[];

  search: {
    isSearchable: boolean;
    filterStringFilter?: FilterStringFilter;
  };
  style: DictString<StyleMap>;

  constructor(item?: Partial<WorkspaceSolutionType>) {
    super(item);

    if (item) {      
      this.search = this.search || { isSearchable: false };
      this.style = this.style || {};
      // OLD STYLE FORMAT:
      const anyThis = this as any;
      for (const key of Object.keys(anyThis.styleMembers || {})) {
        anyThis.style[key] = anyThis.style[key] || { members: [] };
        Object.assign(
          anyThis.style[key],
          {
            members: anyThis.styleMembers[key] || []
          }
        );
      }
      delete anyThis.styleMembers;

      // NEW STYLE FORMAT:
      for (const key of Object.keys(this.style || {})) {
        if (this.style[key] && this.style[key].members) {
          Object.assign(
            this.style[key],
            {
              members: (this.style[key].members || []).map((i: WorkspaceSolutionTypeMember) => {
                const wstm = new WorkspaceSolutionTypeMember(i);
                return wstm;
              })
            }
          );

          if (this.style[key].members?.length && this.style[key].rowColorMember) {
            this.style[key].rowColorMember = this.style[key].members.find((m: WorkspaceSolutionTypeMember) => {
              return m.guidId === this.style[key].rowColorMember.guidId;
            });
          }
        }
      }

      // regression: to make existing solutionTypes (with canAdd = undefined) default to canAdd = true
      this.canAdd = this.canAdd !== false;
      this.canDelete = this.canDelete == null ? this.style?.form?.canDelete : this.canDelete;

      this.runSystemActions = this.runSystemActions || anyThis.specialActions;
      delete anyThis.specialActions;
    } else {
      this.search = { isSearchable: false };
      this.style = {};
    }
  }

  static new(
    workspaceGuidId: string,
    centerTypeGuidId: string,
    name: string,
  ) {
    const newItem = new WorkspaceSolutionType();

    newItem.workspaceGuidId = workspaceGuidId;
    newItem.baseSolutionTypeGuidId = centerTypeGuidId;
    newItem.name = name;

    return newItem;
  }

  getMainMemberLabel(webObject: WebObject, userLanguageGuidId?: string): string {
    let label = '';

    if (this.mainLabelPlaceholder) {
      const mainLabelPlaceholderReplacements = (this.mainLabelPlaceholder.match(/{{([^}]*)}}/g) || [])
      .map((pathPlaceholder: string) => {
        const from = pathPlaceholder;
        const path = from.replace(/{{/g, '').replace(/}}/g, '');
        let to = null;
        
        if (userLanguageGuidId) {
          const membersPathSubString = path.substring(path.indexOf('members.'));
          const key = membersPathSubString.substring('members.'.length);
          const newFieldPath = path.replace(membersPathSubString, `languageMembers.${key}.${userLanguageGuidId}`);

          to = JsonUtils.deepFind(webObject, newFieldPath || '');
        }

        if (!to) {
          to = JsonUtils.deepFind(webObject, path || '') || '';
        }
        return to != null ? {
          from: from,
          to: to,
        } : null;
      }).filter((item: any) => {
        return item;
      });

      if (mainLabelPlaceholderReplacements?.length) {
        label = this.mainLabelPlaceholder;
        for (const mainLabelPlaceholderReplacement of mainLabelPlaceholderReplacements || []) {
          label = label.replace(mainLabelPlaceholderReplacement.from, mainLabelPlaceholderReplacement.to);
        };
      } else {
        label = '';
      }
    } else if (!Array.isArray(this.mainLabelMember) && typeof this.mainLabelMember === 'object') {
      const wstm = new WorkspaceSolutionTypeMember(this.mainLabelMember);
      const memberPath = wstm.getWeb2ObjectPath();
      label = JsonUtils.deepFind(webObject, memberPath || '') || webObject?.members?.name || webObject?.guidId;
    } else {
      label = webObject?.members?.name || webObject?.guidId
    }

    return label;
  }
  
}
