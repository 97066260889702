import { WebCenterType, WebObject } from 'app/center-v2/core/models';
import { DictString } from 'app/shared/models';

export class AdvancedSearchResponseType {
  foundCount: number;
  web2Objects: WebObject[];

  $webCenterType?: WebCenterType;
  $tabHeader?: string;

  constructor(item?: Partial<AdvancedSearchResponseType>) {
    Object.assign(this, item);

    this.web2Objects = (this.web2Objects || []).map((x: WebObject) => {
      return new WebObject(x);
    });
  }
}

export class AdvancedSearchResponse {
  
  totalFoundCount: number;
  resultTypes: DictString<AdvancedSearchResponseType>;
  web2CenterTypes: WebCenterType[];

  constructor(item?: Partial<AdvancedSearchResponse>) {
    Object.assign(this, item);

    this.web2CenterTypes = (this.web2CenterTypes || []).map((x: WebCenterType) => {
      return new WebCenterType(x);
    });

    for (const key of Object.keys(this.resultTypes || {})) {
      this.resultTypes[key] = new AdvancedSearchResponseType(this.resultTypes[key]);
      this.resultTypes[key].$webCenterType = this.web2CenterTypes.find((wct: WebCenterType) => { return wct.typeGuidId === key; });
    }
  }
}
