import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { FormFieldComponent } from '../../form-field/form-field.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete'

@Component({
  selector: 'lc-grid-celleditor-auto-complete',
  templateUrl: 'grid-celleditor-auto-complete.component.html',
  styleUrls: ['grid-celleditor-auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorAutoComplete implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild(AutoComplete) autoComplete: AutoComplete;

  params: any;

  cellValueChanged: Subject<void>;
  originalValue: any;

  searchResults: any[];

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.cellValueChanged = new Subject<any>();
    this.cellValueChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe(() => { this.onCellValueChanged(); });

    this.searchCallback = this.searchCallback.bind(this);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.autoComplete.focusInput();
    }, 50);
  }

  agInit(params): void {
    this.params = params;

    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.options = GridUtils.getParamValue(this.params, this.params.options);

    this.originalValue = this.params.value;

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  onCellValueChanged() {
    if (this.params.value !== this.originalValue) {
      this.params.newValue = this.params.value;
      this.params.oldValue = this.originalValue;

      let fieldSplit = this.params.colDef.field.split('.');
      const fieldKey = fieldSplit[fieldSplit.length - 1];
      const fieldPath = fieldSplit.slice(0, fieldSplit.length - 1).join('.');
      const fieldParent = fieldPath ? JsonUtils.deepFind(this.params.data, fieldPath) : this.params.data;
      fieldParent[fieldKey] = this.params.value;

      if (this.params?.cellValueChanged) {
        this.params.cellValueChanged(this.params);
      }
    }
    this.originalValue = this.params.value;

    if (this.params.stopEditing) {
      this.params.stopEditing();
    }
  }

  searchCallback(searchResults: any[]): void {
    this.searchResults = searchResults || [];
    this.cdr.markForCheck();
  }


}
