
export enum WorkspaceSolutionTypeRunSystemAction {
  PicklistRelease = 'e67d8c0f-83b0-4896-bf34-3dabe2d1162c',
  PicklistOrderForceComplete = '30277cd6-2d7e-43a8-8bc8-a1c4f62bc669',
  PicklistOrderRemove = '36ea6a4a-4a81-4fec-ab2d-051f363e32de',
  CustomerOrderRemove = '1691a9c9-f92c-45ba-bce5-c2a62c7326a9',

  SomethingRemoveReservation = '8f0716ed-f79b-4813-8b21-0079a2f7d77e',

  WarehouseLocationArticleForget = 'd9af36b0-c4df-4059-b6ff-1513cf6eac30',
  WarehouseLocationArticleStockMove = '4ec9c3ca-b443-4e1a-a0fa-acce8d2a6ef0',
}
