<div *ngIf="value != null"
  class="cell-level">

  <div class="cell-level-bar"
    [style.width]="value + '%'"
    [style.background]="params?.getCurrentLevelColor ? params.getCurrentLevelColor(params) : 'gainsboro'">
  </div>

  <div class="cell-level-text"
    [innerHTML]="(value + '%') | highlight:params?.filter ? params.filter() : ''">
  </div>

</div>