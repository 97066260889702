import { classToClass } from 'class-transformer';
import { DataSource } from './data-source.model';
import { LogicObject } from './logic-object.model';
import { TreeRelationSource } from './tree-relation-source.model';
import { TreeSource } from './tree-source.model';
import { TypeTreeRelationEnum } from './type-tree-relation.enum';
import { TypeEnum } from './type.enum';


export class Commit {

  webDataSources: DataSource[];
  webTreeSources: TreeSource[];
  commentText: string;

  constructor() {
    this.webDataSources = [];
    this.webTreeSources = [];
  }

  static buildCommitForObjectUpdate(
    logicObject: LogicObject,
    commentText: string,
    associatedAddress?: LogicObject
  ): Commit {
    const commit = new Commit();

    if (
      logicObject.webDataSource &&
      (logicObject.webDataSource.isNew || logicObject.webDataSource.isDirty)
    ) {
      commit.webDataSources.push(classToClass(logicObject.webDataSource));
    }
    if (associatedAddress) {
      commit.webDataSources.push(classToClass(associatedAddress.webDataSource));
    }

    if (logicObject.webTreeSource && logicObject.webTreeSource.isDirty) {
      const ts = classToClass(logicObject.webTreeSource);
      ts.treeRelationSources = ts.treeRelationSources.filter((trs: TreeRelationSource) => {
        return trs.isNew || trs.isDirty;
      });

      commit.webTreeSources.push(ts);
    }

    commit.commentText = commentText;

    return commit;
  }

  static buildCommitForSetAsAdminUser(
    site: LogicObject,
    siteAddressGuidId: string,
    user: LogicObject,
    userAddressGuidId: string,
  ): Commit {
    const commit = new Commit();

    // Remove old Admin User if any
    const oldTRS = site.webTreeSource.getRelationsByTypeTreeRelationGuidId(TypeTreeRelationEnum.SiteAdminUser)
    .find((trs: TreeRelationSource) => {
      return trs.parentGuidId === site.getGuidId() && trs.childGuidId === user.getGuidId();
    });
    site.webTreeSource.deactivateRelation(oldTRS);

    // Set new Admin User
    const newTRS = new TreeRelationSource(
      site.getGuidId(),
      site.getTypeGuidId(),
      user.getGuidId(),
      user.getTypeGuidId(),
    );
    newTRS.typeTreeRelationGuidId = TypeTreeRelationEnum.SiteAdminUser;
    site.webTreeSource.addOrUpdateRelation(newTRS);

    // update sites' reference to user address
    if (userAddressGuidId !== siteAddressGuidId) {
      const existingAddressTRS = site.webTreeSource.getRelationsByTypeTreeRelationGuidId(TypeTreeRelationEnum.SiteAddress)
      .find((trs: TreeRelationSource) => {
        return trs.parentGuidId === site.getGuidId();
      });

      if (existingAddressTRS) {
        existingAddressTRS.childGuidId = userAddressGuidId;

        site.webTreeSource.addOrUpdateRelation(existingAddressTRS);
      } else {
        // Set new treeRelationSource pointing to the User address
        const newAddressTRS = new TreeRelationSource(
          site.getGuidId(),
          site.getTypeGuidId(),
          userAddressGuidId,
          TypeEnum.Address,
        );
        site.webTreeSource.addOrUpdateRelation(newAddressTRS);
      }
    }

    commit.webTreeSources.push(site.webTreeSource);

    commit.commentText = '[User] set admin user.'

    return commit;
  }

}
