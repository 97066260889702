import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';




@Component({
  selector: 'lc-prompt-dialog',
  templateUrl: './prompt.dialog.html',
  styleUrls: ['./prompt.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptDialog extends BaseDialog {

  title: string;
  label: string;
  required: boolean = true;
  type: string;
  value: string;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.title = data.title;
    this.label = data.label;
    this.required = data.required;
    this.type = data.type;
    this.value = data.value;
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.close(this.value);
      form.reset();
    }
  }

}
