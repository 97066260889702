import { Injectable } from '@angular/core';
import { Device } from 'app/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterService } from '../api/api-center.service';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private urlSuffixPlaceholder = 'api/v0/device/{what}';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
  ) { }

  list(siteGuidId: string, solutionProfileGuidId: string): Observable<Device[]> {
    return this.apiService.post<Device[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      {
        siteGuidId: siteGuidId,
        solutionProfileGuidId: solutionProfileGuidId
      }
    )
    .pipe(
      map((response: any) => {
        return response && response.devices ? response.devices : null;
      })
    );
  }

}
