import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceWidgetService } from 'app/center-v2/shared/services';
import { WebSkin } from 'app/center/shared/models';
import { UserSession } from 'app/shared/models';
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { Observable, range, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, retryWhen, zip } from 'rxjs/operators';
import { DateUtils } from '../../utils/date.utils';
import { SessionService } from '../app';
import { AppService } from '../app/app.service';
import { NotificationService } from '../app/notification.service';
import { ActionCode, ApiResponse } from './api-response.model';



class NotReallyAnError {
  constructor(
    public message?: string
  ) {

  }
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  siteGuidId: string;
  solutionEnvironmentGuidId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.siteGuidId = params.siteGuidId;
      this.solutionEnvironmentGuidId = params.solutionEnvironmentGuidId;
    });
  }

  get<T>(baseUrl: string, endpoint: string): Observable<T> {
    const fullUrl = baseUrl + endpoint;

    return this.http.get(fullUrl)
      .pipe(
        map((response: ApiResponse<T>) => {
          return this.handleResponse(response);
        }),
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  post<T>(baseUrl: string, endpoint: string, body: any, options?: any): Observable<T> {
    const fullUrl = baseUrl + endpoint;
    const workspace = this.workspaceWidgetService.getWorkspace();

    const sessionGuidId = this.sessionService.getSessionGuidId();
    let dto;
    if (body instanceof FormData) {
      dto = body;
      dto = dto.append('webDateTime', DateUtils.nowAsISOString()) || dto;

      if (sessionGuidId) {
        dto = dto.append('sessionGuidId', sessionGuidId) || dto;
      }
    } else {
      const dtoString = JSON.stringify(body, this.jsonReplacer);

      dto = JSON.parse(dtoString);

      dto = Object.assign(dto, {
        sessionGuidId: sessionGuidId,
        siteGuidId: dto.siteGuidId || this.siteGuidId,
        solutionEnvironmentGuidId: dto.solutionEnvironmentGuidId || this.solutionEnvironmentGuidId,
        solutionProfileGuidId: dto.solutionProfileGuidId || (workspace ? workspace.solutionProfile.guidId : undefined),
        webDateTime: DateUtils.nowAsISOString(),
        workspaceGuidId: dto.workspaceGuidId || (workspace ? workspace.guidId : undefined),
        adminCallGuidId: dto.adminCallGuidId || ((dto.workspaceGuidId || workspace?.guidId) ? undefined : GuidUtils.new()),
      });
    }

    if (options) {
      return this.http.post(fullUrl, dto, options)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
    } else {
      return this.http.post(fullUrl, dto)
      .pipe(
        map((response: ApiResponse<T>) => {
          return this.handleResponse(response);
        }),
        retryWhen(this.incrementalBackOffRetry),
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
    }
  }

  private handleResponse<T>(response: ApiResponse<T>) {
    response = new ApiResponse(response);
    if (response.success) {
      return response.value;
    } else {
      if (response.actionCode === ActionCode.NoSessionAccess) {
        this.redirectToLogin();

        this.sessionService.get()
        .subscribe((session: UserSession) => {
          if (session) {
            this.notificationService.info(
              this.translateService.instant('Session Expired'),
              this.translateService.instant('Please login again.'),
            );
          }
        });

        throw new NotReallyAnError('No Access');
      } else if (response.actionCode === ActionCode.SetPassword) {
        this.router.navigate(['/v2/auth/set-password']);
        throw new NotReallyAnError('Set Password');
      } else if (response.actionCode === ActionCode.Retry) {
        throw new NotReallyAnError('Retry');
      } else if (response.actionCode === ActionCode.StudioInvalidReservation) {
        throw new Error('Invalid Solution Reservation.');
      } else if ((response.value as any)?.notAllowedReason || (response.value as any)?.failedReason || response?.failedReason) {
        this.notificationService.error(
          this.translateService.instant('Error'),
          this.translateService.instant((response.value as any)?.notAllowedReason || (response.value as any)?.failedReason || response?.failedReason),
        );
        throw new NotReallyAnError('Server Error');
      } else {
        console.warn(response.exceptionString || '<empty exceptionString>');
        throw new Error('A system error has occured.');
      }
    }
  }

  private redirectToLogin() {
    this.sessionService.getWebSkin()
    .subscribe((webSkin: WebSkin) => {
      const clModule = webSkin.customLogin || 'v2/auth';
      if (window.location.pathname.indexOf('/login') >= 0) {
        this.router.navigate([`/${clModule}/login`]); // logout
      } else {
        this.router.navigate([`/${clModule}/login`], { queryParams: { fromUrl: window.location.pathname + window.location.search } }); // logout
      }
    });

  }

  private incrementalBackOffRetry(errors: Observable<any>): Observable<any> {
    return errors
    .pipe(
      zip(range(1, 4)),
      mergeMap(([error, i]) => {
        let stopRetrying: boolean = error.message !== 'Retry' || i > 3;
        if (stopRetrying) {
          return throwError(error.message !== 'Retry' ? error : 'Server Error');
        } else {
          return timer(i * 1000);
        }
      })
    );
  }

  private handleError(error: any): Observable<any> {
    if (error.status === 0) {
      return throwError(this.translateService.instant('ERROR.NO_CONNECTION'));
    } else {
      const errorMsg = (error.json ? error.json().message : error.message) || 'Server error';
      if (!(error instanceof NotReallyAnError)) {
        this.appService.showDefaultErrorToast(errorMsg, true);
      }
      return throwError(errorMsg);
    }
  }

  private jsonReplacer(name: string, val: any) {
    if (
      (name || '').toString().indexOf('$') === 0 ||
      val === undefined
    ) {
      return undefined; // remove from result
    } else {
        return val; // return as is
    }
  }

}
