import { NgModule } from '@angular/core';
import { HighlightPipe } from 'app/shared/pipes';
import { CleanGuidIdPipe } from './cleanguidid.pipe';
import { LocalISOStringPipe } from './localisostring.pipe';




@NgModule({
  declarations: [
    CleanGuidIdPipe,
    HighlightPipe,
    LocalISOStringPipe,
  ],
  exports: [
    CleanGuidIdPipe,
    HighlightPipe,
    LocalISOStringPipe,
  ],
  imports: [
  ],
})
export class PipesModule { }
