import { WebSkin } from '../../center/shared/models';


export class WebSkinUtils {

  private static readonly DEFAULT_SKIN: WebSkin = {
    guidId: 'dd7cc557-1e84-49a1-8e10-bd3e8d318137',
    appTitle: 'LogicCenter',
    theme: 'mobilelogic',
    language: 'en',
  } as WebSkin;

  // HARDCODED SKINS
  static db: WebSkin[] = [
    WebSkinUtils.DEFAULT_SKIN,
    {
      guidId: 'b2274d06-b907-4017-87b9-567393e16093',
      appTitle: 'Keyportal',
      theme: 'keyportal',
      language: 'en',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    },
    {
      guidId: '638f04be-1416-4284-b4f0-ecffc5a833f1', // Preem
      appTitle: 'Preem',
      theme: 'preem',
      language: 'se',
      customLogin: 'volymkoll',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    }
  ];
  // HARDCODED SKINS

  static getWebSkinForCurrentUrl(url: string): WebSkin {
    let webSkin: WebSkin;
    if (
      url.indexOf('keyportal.se') >= 0
      // || url.indexOf('localhost') >= 0
    ) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === 'b2274d06-b907-4017-87b9-567393e16093'; // topfuel
      });
    } else if (
      url.indexOf('volymkoll.se') >= 0
      // || url.indexOf('localhost') >= 0
    ) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === '638f04be-1416-4284-b4f0-ecffc5a833f1'; // Preem
      });
    }
    return webSkin || this.DEFAULT_SKIN;
  }

}
