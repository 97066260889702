import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { WebSkin } from 'app/center/shared/models';
import { WebSkinUtils } from 'app/shared/utils';
import * as Cookies from 'js-cookie';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSession } from '../../models';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly cookieSessionKey = 'lc_session_guid';
  private readonly storageSessionKey = 'lc_session';
  private readonly storageParentSessionKey = 'lc_parent_session';

  private session: UserSession;

  constructor(
    private storageService: StorageService,
  ) {
  }

  instant(): UserSession {
    return this.session;
  }

  get(): Observable<UserSession> {
    let request;
    if (this.session) {
      request = of(this.session);
    } else {
      request = this.storageService.get(this.storageSessionKey);
    }

    return request
    .pipe(
      map((session: UserSession) => {
        this.session = session;

        if (this.session) {
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: this.session.guidId,
              email: this.session.email
            });
          });
        }

        return this.session;
      })
    );
  }


  getSessionGuidId(): string {
    const sessionGuidId = Cookies.get(this.cookieSessionKey);
    if (this.session && this.session.guidId !== sessionGuidId) {
      this.session.guidId = sessionGuidId;
      this.set(this.session).subscribe();
    }
    return sessionGuidId;
  }

  set(session: UserSession): Observable<UserSession> {
    return this.storageService.set(this.storageSessionKey, session)
    .pipe(
      map((s: UserSession) => {
        this.session = s;

        const isLCDomain = document.location.href.indexOf('logiccenter.com') >= 0;
        const cookieOptions = { expires: 7 };
        Object.assign(cookieOptions, isLCDomain ? { domain: 'logiccenter.com' } : {});
        if (this.session?.guidId) {
          Cookies.set(this.cookieSessionKey, this.session.guidId, cookieOptions);
        } else {
          Cookies.remove(this.cookieSessionKey, cookieOptions);
        }

        return this.session;
      })
    );
  }

  getWebSkin(): Observable<WebSkin> {
    return this.get()
    .pipe(
      map((session: UserSession) => {
        let webSkin = (WebSkinUtils.db || []).find((item: WebSkin) => {
          return session && item.guidId === session.webSkinGuidId;
        });

        return webSkin ? webSkin : WebSkinUtils.getWebSkinForCurrentUrl(window.location.href);
      })
    );
  }

}
