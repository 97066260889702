import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { DialogModule } from 'primeng/dialog';
import { GdprDialog } from './gdpr.dialog';



@NgModule({
  declarations: [
    GdprDialog,
  ],
  exports: [
    GdprDialog,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    DialogModule,
  ],
})
export class GdprModule { }

