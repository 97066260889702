import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { InputTextModule } from 'primeng/inputtext';
import { SearchInputComponent } from './search-input.component';



@NgModule({
  declarations: [
    SearchInputComponent,
  ],
  exports: [
    SearchInputComponent,
  ],
  imports: [
    AppSharedV2Module,
    InputTextModule,
  ],
  providers: [],
})
export class SearchInputModule { }

