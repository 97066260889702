import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { WebCenterType, WebLoadMap, WebObject, WebResponse, WorkspaceSolutionType } from 'app/center-v2/core/models';
import { GenericService } from 'app/center-v2/shared/services';
import { PicklistTypePicklistOrderBlockedType } from 'app/center-v2/template-solutions/pick-system/models/picklist-type-picklist-order-blocked-type.enum';
import { TypeEnum } from 'app/shared/models';
import { CaseUtils } from 'app/shared/utils';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { PickSystemService } from 'app/center-v2/template-solutions/pick-system/services';


@Component({
  selector: 'lc-grid-cellrenderer-detail-blocked-order',
  templateUrl: 'grid-cellrenderer-detail-blocked-order.component.html',
  styleUrls: ['grid-cellrenderer-detail-blocked-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererDetailBlockedOrder implements ICellRendererAngularComp, OnDestroy {

  params: any;

  parsedTargetBlockedText: { text?: string, webObject?: WebObject }[];
  webCenterTypes: WebCenterType[];

  constructor(
    private cdr: ChangeDetectorRef,
    private pickSystemService: PickSystemService,
    private genericService: GenericService,
  ) {

  }

  agInit(params: any): void {
    this.params = params;

    if (this.params?.data?.members?.blockedReasonText) {
      this.params.data.members.blockedReasonText = this.params.data.members.blockedReasonText.replace(/\r\n/g, '<br>');
    }

    if (this.params?.data?.members?.targetBlockedText) {
      this.params.data.members.targetBlockedText = this.params.data.members.targetBlockedText.replace(/\r\n/g, '<br>');

      // targetBlockedText may contain replacement placeholders in it...so go and find them
      const webObjectPlaceholderReplacements = (this.params.data.members.targetBlockedText.match(/\[\[([^\]]*)\]\]/g) || [])
      .map((pathPlaceholder: string) => {
        const from = pathPlaceholder;
        const fromJson = from.replace(/\[\[/g, '').replace(/\]\]/g, '');
        return {
          from: from,
          fromJson: fromJson,
          fromObject: CaseUtils.toCamel(JSON.parse(fromJson)),
        };
      }).filter((item: any) => {
        return item;
      });

      if (webObjectPlaceholderReplacements?.length) {
        // If there's some replacements, we need to parse them and /get the corresponding webObjects
        const solutionType: WorkspaceSolutionType = (this.params?.solutionTypes || []).find((st: WorkspaceSolutionType) => {
          return st.baseSolutionTypeGuidId === TypeEnum.Article;
        });

        zip(
          ...webObjectPlaceholderReplacements.map((replacement: any) => {

            const webLoadMap = WebLoadMap.buildFromWorkspaceSolutionType(solutionType, 'style.form.members');
            return this.genericService.getV2(replacement.fromObject.guidId, webLoadMap?.web2LoadMapCenterTypes?.length ? webLoadMap : null, true)
            .pipe(
              map((response: WebResponse) => {
                replacement.toWebObject = response.getObject() as WebObject;
                replacement.to = solutionType.getMainMemberLabel(replacement.toWebObject);

                this.webCenterTypes = this.webCenterTypes || [];
                this.webCenterTypes.push(...response.getAllCenterTypes()); // TODO: remove the duplicates?

                return replacement;
              })
            );
          })
        ).subscribe((replacements: any[]) => {
          this.parsedTargetBlockedText = this.params.data.members.targetBlockedText.split(/\[\[([^\]]*)\]\]/)
          .map((substr: string) => {
            const replacement = (replacements || []).find((r: any) => {
              return r.fromJson === substr;
            });
            if (replacement) {
              return { text: replacement.to, webObject: replacement.toWebObject };
            } else if (substr) {
              return { text: substr };
            }
          }).filter((item: any) => {
            return item;
          });

          this.cdr.markForCheck();
        });
      } else {
        // If no replacements to be made, just print the text
        this.parsedTargetBlockedText = [{ text: this.params.data.members.targetBlockedText }];
      }
    }
  }

  ngOnDestroy() {

  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  webObjectViewClick(webObject: WebObject) {
    if (this.params?.webObjectViewClick && webObject) {
      this.params.webObjectViewClick(this.params, webObject, this.webCenterTypes);
    }
  }

  releaseToFree(asyncComplete: () => void) {
    if (this.params.data.members.picklistTypePicklistOrderBlockedType === PicklistTypePicklistOrderBlockedType.ManualBlockedFromFree) {
      this.pickSystemService.unblockPicklistOrder(this.params.picklistTypeGuidId, this.params.data.web2Object.guidId)
      .subscribe(() => {
        if (this.params?.refreshGrid) {
          this.params.refreshGrid();
        }

        asyncComplete();
        this.cdr.markForCheck();
      }, (error: any) => {
        asyncComplete();
        this.cdr.markForCheck();
      });
    }
  }

}
