<lc-form-field *ngIf="params"
  class="cell-field"
  [disabled]="params.disabled"
  [editMode]="true"
  (keydown.tab)="$event.stopPropagation()"
  [maxNumber]="params.maxNumber"
  [minNumber]="params.minNumber"
  [(ngModel)]="params.value"
  (ngModelChange)="cellValueChanged.next()"
  [options]="params.options"
  [type]="params.type || 'text'">
</lc-form-field>