import { EnrollmentProfileSubKey } from 'app/shared/models';
import { WebObject } from './web-base/web-object.model';
import { ClientType } from 'app/center/mobile/models';
import { WebRelationPointer } from './web-base';

export enum EnrollmentRequestStatus {
  Active = 0,
  Set = 1,
  Delivered = 10, 
}

export class EnrollmentRequest extends WebObject {

  members: {
    enrollmentRequestCode: string;
    runClientType: ClientType;
    hardwareType: string;
    hardwareIdentifier: string;
    status: EnrollmentRequestStatus;

    selectedEnrollmentSubKey?: string;
  }

  relations: {
    selectedEnrollmentProfile?: WebRelationPointer;
  }

}
