import { DictString } from 'app/shared/models/dict.model';


export class ViewData<T> {
  guidId: string;
  typeGuidId: string;
  values: T;
  relation: DictString<any>;

  tick: number;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
