  import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Dialog } from 'primeng/dialog';
import { Tree } from 'primeng/tree';
import { WorkspaceWidgetService } from './center-v2/shared/services';
import { WebSkin } from './center/shared/models';
import { BaseComponentV2 } from './shared/components/base/base-v2.component';
import { BaseDialog, InfoDialog } from './shared/dialogs';
import { AppService, CheckVersionService, LanguageTextsService, PluginLoaderService } from './shared/services';
import { SessionService } from './shared/services/app';
import { NavigationHistoryService } from './shared/services/app/navigation-history.service';


// HACK to fix https://github.com/primefaces/primeng/issues/8237 (and other older issue)
// TODO remove when this is fixed by primeng
Tree.prototype.isNodeLeaf = (node) => node.leaf;

// REMOVE ON FINAL primeng 9 (I HOPE)
Dialog.prototype.appendContainer = function() {
  if (this.appendTo) {
      if (this.appendTo === 'body')
        document.body.appendChild(this.wrapper); // document.body.appendChild(this.container);
      else {
        if (this.isElement(this.appendTo))
          this.appendTo.appendChild(this.wrapper);
        else if (this.appendTo.el && this.appendTo.el.nativeElement)
          this.appendTo.el.nativeElement.appendChild(this.wrapper);
      }
  }
}
Dialog.prototype.restoreAppend = function() {
  if (this.wrapper && this.appendTo) {
      this.el.nativeElement.appendChild(this.wrapper);
  }
}
Dialog.prototype.close = function(event: Event) {
  this.maskVisible = false;
  this.visible = false;
  this.visibleChange.emit(false);
  event.preventDefault();
}
Dialog.prototype.enableModality = function() {
  if (this.closable && this.dismissableMask) {
      this.maskClickListener = this.renderer.listen(this.wrapper, 'dblclick', (event: any) => {
          if (!this.container.isSameNode(event.target) && !this.container.contains(event.target)) {
              this.close(event);
          }
      });
  }

  if (this.modal) {
    if (document.body.classList) {
      document.body.classList.add('ui-overflow-hidden');
    } else {
      document.body.className += ' ' + 'ui-overflow-hidden';
    }
  }
}
Object.defineProperty(Dialog.prototype, 'visible', {
  set: function visible(value: boolean) {
    this._visible = value;
    this.maskVisible = value;
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponentV2 implements OnInit {

  @ViewChild(InfoDialog, { static: true}) infoDialog: BaseDialog;

  currentUrl: string;
  isBusy: boolean;
  webSkin: WebSkin;

  constructor (
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private checkVersionService: CheckVersionService,
    private navigationHistoryService: NavigationHistoryService,
    private pluginLoaderService: PluginLoaderService,
    private router: Router,
    sessionService: SessionService,
    private titleService: Title,
    private translateService: TranslateService,
    private textService: LanguageTextsService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    super(cdr, sessionService);

    this.appService.listenToBusyChanges()
    .subscribe((isBusy: boolean) => {
      this.isBusy = isBusy;
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.checkVersionService.check();

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) { // lazy loaded route started
          this.appService.setBusy(true);
        } else if (event instanceof RouteConfigLoadEnd) { // lazy loaded route ended
          this.appService.setBusy(false);
        } else if (event instanceof NavigationStart) {
          this.currentUrl = event.url;

          this.loadPluginsIfNeeded(event.url);
        } else if (event instanceof NavigationEnd) {
          if (environment.production) {
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
          }

          this.currentUrl = event.urlAfterRedirects;
          this.navigationHistoryService.routeChanged(this.currentUrl);

          this.updateThisSession(true);
        } else if (event instanceof NavigationError) {
          if (event.error && event.error.message && event.error.message.indexOf('Loading chunk') >= 0) {
            this.handleLoadingChunkError(event);
          }
        }
        this.cdr.markForCheck();
      })
    );

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');
    this.textService.setDefaultLang('en');
  }

  protected refresh() {
    this.sessionService.getWebSkin()
    .subscribe((webSkin: WebSkin) => {
      this.webSkin = webSkin;

      const workspace = this.workspaceWidgetService.getWorkspace();
      if (workspace) {
        this.titleService.setTitle('[LC] ' + workspace.name);
      } else {
        this.titleService.setTitle(this.webSkin.appTitle);
      }

      this.setThemes();

      this.textService.use(this.webSkin.language);
      this.translateService.use(this.webSkin.language)
      .subscribe(() => {
        this.cdr.markForCheck();
      });
    });
  }

  private setThemes() {
    const htmlElement = document.documentElement;
    const url = this.currentUrl || '';
    const primeNgThemeName = url.indexOf('v2') >= 0 ||
    url.indexOf('printing') >= 0 ?
      'nova-light' :
      'omega';
    htmlElement.className = `theme-${primeNgThemeName} theme-${this.webSkin.theme}`;

    this.cdr.markForCheck();
  }

  private loadPluginsIfNeeded(url: string) {
    // USED ONLY ON V1
    if (
      url.indexOf('/center/forecast') === 0 ||
      url.indexOf('/center/gps-devices') === 0 ||
      url.indexOf('/center/preem-orders') === 0 ||
      url.indexOf('/center/tanks') === 0 ||
      url.indexOf('/center/tasks') === 0 ||
      url.indexOf('/center/topfuel-orders') === 0
    ) {
      this.pluginLoaderService.load('leaflet').subscribe();

      if (url.indexOf('/center/gps-devices') < 0) {
        this.pluginLoaderService.load('echarts').subscribe();
      }
    }
  }

  private handleLoadingChunkError(ne: NavigationError) {
    this.checkVersionService.check();

    this.appService.setBusy(false);
    this.infoDialog.show({
      title: 'Network Error',
      message: 'Sorry we were unable to load the page you were trying to visit.',
      buttonText: 'Retry',
    });
    this.infoDialog.onClose = () => {
      this.router.navigateByUrl(ne.url)
    };
    this.cdr.markForCheck();
  }

}
