import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';


@Component({
  selector: 'lc-grid-cellrenderer-level',
  templateUrl: 'grid-cellrenderer-level.component.html',
  styleUrls: ['grid-cellrenderer-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererLevel implements AgRendererComponent {

  params: any;

  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }

  agInit(params): void {
    this.params = params;

    this.value = this.params?.node?.group ? undefined : this.params.value || 0;
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

}
