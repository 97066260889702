import { DictString } from 'app/shared/models';
import { WebCenterType, WebObject, WebRelationPointer } from '.';



export class WebResponse {

  private web2Object?: WebObject;
  private web2Objects?: WebObject[];
  private web2CenterTypes?: WebCenterType[];
  private web2Relation?: DictString<WebRelationPointer | WebRelationPointer[]>;
  private web2ObjectSubType?: DictString<WebObject>;

  constructor(item?: Partial<WebResponse>) {
    Object.assign(this, item);

    this.web2Object = this.web2Object ? new WebObject(this.web2Object) : undefined;
    this.web2Objects = this.web2Objects ? this.web2Objects.map((x: WebObject) => {
      return new WebObject(x);
    }) : undefined;
    this.web2CenterTypes = (this.web2CenterTypes || []).map((x: WebCenterType) => {
      return new WebCenterType(x);
    });

    for (const key of Object.keys(this.web2Relation || {})) {
      if (this.web2Relation[key] && Array.isArray(this.web2Relation[key])) {
        this.web2Relation[key] = ((this.web2Relation[key] || []) as WebRelationPointer[]).map((item: WebRelationPointer) => {
          return new WebRelationPointer(item);
        });
      } else if (this.web2Relation[key]) {
        this.web2Relation[key] = new WebRelationPointer(this.web2Relation[key] as WebRelationPointer);
      }
    }

    for (const key of Object.keys(this.web2ObjectSubType || {})) {
      if (this.web2ObjectSubType[key]) {
        this.web2ObjectSubType[key] = new WebObject(this.web2ObjectSubType[key]);
      }
    }
  }

  getObject(guidId?: string): WebObject | WebObject[] {
    return !guidId || this.web2Object?.guidId === guidId ?
      (this.web2Objects || this.web2Object) :
      (this.web2Objects.find((wo: WebObject) => {
        return wo.guidId === guidId;
      }) || this.web2Object);
  }

  getObjectSubType(typeName?: string): WebObject {
    const subTypeDict = this.web2ObjectSubType || {};
    return typeName ? subTypeDict[typeName] :
      Object.keys(subTypeDict).length ? subTypeDict[Object.keys(subTypeDict)[0]] :
      null;
  }

  getAllCenterTypes(): WebCenterType[] {
    return this.web2CenterTypes;
  }

  addCenterType(webCenterType: WebCenterType) {
    this.web2CenterTypes.push(webCenterType);
  }

  getCenterType(typeGuidId?: string): WebCenterType {
    typeGuidId = typeGuidId || this.web2Object?.typeGuidId;

    return (this.web2CenterTypes || [])
    .find((ct: WebCenterType) => {
      return ct.typeGuidId === (typeGuidId || this.web2CenterTypes[0].typeGuidId);
    });
  }

  getRelationPointer(relationName: string): WebRelationPointer | WebRelationPointer[] {
    return this.web2Relation ? this.web2Relation[relationName] : null;
  }

  getRelation(relationName: string): WebObject | WebObject[] {
    const relationPointerOrPointers = this.web2Relation ? this.web2Relation[relationName] : null;
    if (Array.isArray(relationPointerOrPointers)) {
      return relationPointerOrPointers.map((rp: WebRelationPointer) => {
        return rp.web2Object;
      });
    } else {
      return relationPointerOrPointers ? relationPointerOrPointers.web2Object : null;
    }
  }
}
