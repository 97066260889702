import { Pipe, PipeTransform } from '@angular/core';
import { GuidUtils } from '../utils';

@Pipe({ name: 'cleanguidid' })
export class CleanGuidIdPipe implements PipeTransform {

  transform(guidId: string): string {
    return GuidUtils.clean(guidId);
  }
}
