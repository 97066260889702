<div class="flex-row">
  <div class="flex-col flex-1">
    <lc-form-field
      [label]="'Blocked DateTime' | translate"
      [ngModel]="params?.data?.members?.blockedDateTime"
      type="date">
    </lc-form-field>

    <lc-form-field
      [label]="'Blocked Reason' | translate"
      [ngModel]="params?.data?.members?.blockedReasonText"
      type="text">
    </lc-form-field>
  </div>

  <div class="flex-col flex-1">
    <label class="label">
      {{ 'Target Blocked' | translate }}
    </label>

    <div *ngIf="params?.data?.members?.targetBlockedText && parsedTargetBlockedText?.length"
      class="value">
      <span *ngFor="let item of parsedTargetBlockedText">
        <span *ngIf="item.text"
          [innerHTML]="item.text">
        </span>

        <i *ngIf="item.webObject"
          class="link fas fa-link"
          style="margin: 0 .3rem;"
          (click)="webObjectViewClick(item.webObject)">
        </i>
      </span>
    </div>

    <div *ngIf="params?.data?.members?.targetBlockedText && !parsedTargetBlockedText?.length">
      ({{ 'Loading' | translate }})
    </div>

    <div *ngIf="!params?.data?.members?.targetBlockedText">
      <span class="no-value">-</span>
    </div>
  </div>

  <div *ngIf="params?.data?.members?.picklistTypePicklistOrderBlockedType === 256"
    class="flex-col"
    style="width: 160px">
    <lc-button
      [async]="true"
      (click)="releaseToFree($event)"
      [label]="'Release to Free'"
      type="danger">
    </lc-button>
  </div>
</div>