import { SolutionTypeSubMemberType } from "app/shared/models";
import { CaseUtils, GuidUtils, JsonUtils } from "app/shared/utils";
import { WorkspaceSolutionTypeMember } from "../workspace-solution-type-member.model";
import { WorkspaceSolutionType } from "../workspace-solution-type.model";

export class WebLoadMapCenterTypeRelation {
  relationName: string;
  web2CustomLoadMapCenterType: any;

  constructor(item?: Partial<WebLoadMapCenterTypeRelation>) {
    Object.assign(this, item);
  }
}

export class WebLoadMapCenterType {

  guidId: string;
  centerTypeGuidId: string;
  web2Relations: WebLoadMapCenterTypeRelation[];

  constructor(item?: Partial<WebLoadMapCenterType>) {
    Object.assign(this, item);

    this.guidId = GuidUtils.emptyGuid();
    this.web2Relations = (this.web2Relations || []).map((x: WebLoadMapCenterTypeRelation) => {
      return new WebLoadMapCenterTypeRelation(x);
    });
  }

}

export class WebLoadMap {

  includeObjectSite: boolean;
  web2LoadMapCenterTypes: WebLoadMapCenterType[];

  constructor(item?: Partial<WebLoadMap>) {
    Object.assign(this, item);

    this.web2LoadMapCenterTypes = (this.web2LoadMapCenterTypes || []).map((x: WebLoadMapCenterType) => {
      return new WebLoadMapCenterType(x);
    });
  }

  static buildFromWorkspaceSolutionType(solutionType: WorkspaceSolutionType, membersKey: string): WebLoadMap {
    const webLoadMap = new WebLoadMap();

    const members = JsonUtils.deepFind(solutionType, membersKey, true, []) as WorkspaceSolutionTypeMember[];
    for (const member of members || []) {
      const pathSplit = (member.path || '').split('.');
      if (pathSplit?.length && !!pathSplit[0]) {
        for (let i = 0; i < pathSplit.length; i++) {
          const pathNode = pathSplit[i];
          if (
            member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Relation ||
            member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Collection
          ) {
            const centerTypeGuidId = member.subMembers[i.toString()].fromTypeGuidId;
            webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(pathNode));
          } else if (member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Site) {
            webLoadMap.includeObjectSite = true;
          }
        }
      }

      if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Relation) {
        const centerTypeGuidId = member.subMembers[member.subLevels].fromTypeGuidId;
        webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.name.replace(member.path.replace(/\./g, ' '), '').replace(/ /g, '')));
        if (member.fallbackRelationTypeName) {
          webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.fallbackRelationTypeName));
        }
      } else if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Site) {
        webLoadMap.includeObjectSite = true;
      }
    }

    let mainLabelPlaceholder = solutionType?.mainLabelPlaceholder;
    let placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
    while (placeholderRelationsIndex >= 0) {
      // if the main label placeholder has relations in it, we need to loadMap them as well...
      mainLabelPlaceholder = mainLabelPlaceholder.substring(placeholderRelationsIndex + 12);
      const relationName = mainLabelPlaceholder.substring(0, mainLabelPlaceholder.indexOf('.'));
      webLoadMap.addCenterTypeRelation(solutionType.baseSolutionTypeGuidId, relationName);

      placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
    };

    return webLoadMap;
  }

  addCenterTypeRelation(centerTypeGuidId: string, relationName: string) {
    let existingCenterType = this.web2LoadMapCenterTypes.find((item: WebLoadMapCenterType) => {
      return item.centerTypeGuidId === centerTypeGuidId;
    });

    if (!existingCenterType) {
      existingCenterType = new WebLoadMapCenterType({ centerTypeGuidId: centerTypeGuidId });
      this.web2LoadMapCenterTypes.push(existingCenterType);
    }

    let existingCenterTypeRelation = existingCenterType.web2Relations.find((item: WebLoadMapCenterTypeRelation) => {
      return item.relationName === relationName;
    });

    if (!existingCenterTypeRelation) {
      existingCenterType.web2Relations.push(new WebLoadMapCenterTypeRelation({ relationName: relationName }));
    }
  }
}
