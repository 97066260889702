import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { SelectSiteDialogModule } from 'app/shared/dialogs/select-site/select-site.dialog.module';
import { ButtonComponentModule } from '../button/button.component.module';
import { OwnerSiteComponent } from './owner-site.component';


@NgModule({
  declarations: [
    OwnerSiteComponent,
  ],
  exports: [
    OwnerSiteComponent,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    SelectSiteDialogModule,
  ],
})
export class OwnerSiteModule { }
