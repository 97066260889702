export class ViewSolutionEvents {
  // !! for some reason Patrik couldn't make this object serialize as camelCase !!
  GuidId: string;
  DeviceEventSolutionGuidId: string;
  EventGuidId: string;
  EventName: string;
  EventSysVersion: number;
  EnvironmentGuidId: string;
  SiteGuidId: string;
  SiteName: string;
  DeviceGuidId: string;
  DeviceId: string;
  SolutionDeviceUserGuidId: string;
  SolutionDeviceUserName: string;
  TickDateTime: string;
  Values: any;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
