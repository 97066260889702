import { DictString } from './dict.model';



export class SiteGuidIdAndName {
  guidId: string;
  name: string;
}

export class SiteTree {
  fromSiteGuidId: string;
  toSiteGuidId: string;
  treeTick: number;
  levelCount: number;
  sites: DictString<SiteGuidIdAndName>;

  constructor(item?: Partial<SiteTree>) {
    Object.assign(this, item);
  }

  getOrderedSitesArray(): SiteGuidIdAndName[] {
    let result = [];

    let siteKeys = Object.keys(this.sites);
    siteKeys.sort((a, b) => {
      return parseInt(a.toString(), 10) - parseInt(b.toString(), 10);
    });

    for (let i = 0; i < siteKeys.length; i++) {
      let siteKey = siteKeys[i];

      result.push(this.sites[siteKey]);
    }

    return result;
  }
}

