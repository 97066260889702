import { v4 as uuidv4, validate, NIL } from 'uuid';

export class GuidUtils {

  static clean(guid: string) {
    return (guid || '').replace(/-/g, '')
  }
  
  static emptyGuid() {
    return NIL;
  }
  
  static isNullOrEmpty(value: string) {
    return !value || value === NIL;
  }

  static isEqual(guid1: string, guid2: string) {
    return (guid1 || '').replace(/-/g, '') === (guid2 || '').replace(/-/g, '');
  }

  static isValid(guid: string) {
    return validate(guid);
  }

  static new() {
    return uuidv4();
  }

}