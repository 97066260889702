import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Message, MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserSession } from '../../models';
import { StorageUtils } from '../../utils';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly localSessionKey = 'lc_session';
  private readonly localParentSessionKey = 'lc_parent_session';

  private buildDateTime: string;
  private busySubject = new Subject<boolean>();
  private solutionProfileSubject: BehaviorSubject<string>;


  constructor(
    private messageService: MessageService
  ) {
    this.busySubject = new Subject();

    this.solutionProfileSubject = new BehaviorSubject(undefined);
  }

  showDefaultErrorToast(error: any, sendToSentry?: boolean) {
    if (error) {
      const msg = {
        severity: 'error',
        summary: 'Oops! Something wrong happened...',
        detail: error,
      };
      this.messageService.add(msg);

      if (sendToSentry) {
        Sentry.captureException(error);
      }
    }
  }

  showToast(msg: Message) {
    if (msg && (msg.summary || (msg.detail && msg.detail !== 'null'))) {
      if (msg.severity === 'error') {
        this.messageService.clear();
      }
      this.messageService.add(msg);
    }
  }

  listenToBusyChanges(): Observable<boolean> {
    return this.busySubject.asObservable();
  }

  setBusy(isBusy: boolean) {
    setTimeout(() => {
      this.busySubject.next(isBusy);
    });
  }

  listenToSolutionProfileChangeMessage(): Observable<string> {
    return this.solutionProfileSubject.asObservable();
  }

  emitSolutionProfileChangeMessage(selectedSolutionProfileGuidId: string) {
    this.solutionProfileSubject.next(selectedSolutionProfileGuidId);
  }

  // TODO: Remove once it's no longer being referenced...
  getSession(): UserSession {
    let sessionString: string = StorageUtils.getItem('local', this.localSessionKey);
    let session: UserSession = sessionString ? JSON.parse(sessionString) : null;

    if (session) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: session.guidId,
          email: session.email
        });
      });
    }

    return session;
  }

  setBuildDateTime(dt: string) {
    this.buildDateTime = dt;
  }

  getBuildDateTime(): string {
    return this.buildDateTime;
  }

}
