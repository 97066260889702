import { SelectItem } from 'primeng/api/selectitem';

export class WebCenterTypeMemberSpecialValue {
  guidId: string;
  value: any;
  name: string;
  readOnly: boolean;
}

export enum WebCenterTypeMemberWeb2Access {
  None = 0,
  Read = 1,
  Write = 2,
  AdminRead = 4,
  AdminWrite = 8,
}

export enum WebCenterTypeMemberSpecialValueType {
  Normal = 0,
  IsFlags = 1,
}
export class WebCenterTypeMember {
  guidId: string;
  name: string;
  nativeType: string;
  nativeSize: number;
  required: boolean;
  isUnique: boolean;
  languageMember: boolean;
  specialMemberType: string;
  specialValues: WebCenterTypeMemberSpecialValue[];
  specialValuesType: WebCenterTypeMemberSpecialValueType;
  uoM: string;
  web2Member: boolean;
  web2Access: WebCenterTypeMemberWeb2Access;
  allowNull: boolean; // to be used for example on Boolean types (3-state-checkbox)

  $key: string;
  $fieldType: string;
  $fieldOptions: SelectItem[];

  constructor(item?: Partial<WebCenterTypeMember>) {
    Object.assign(this, item);
  }
}

