import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormFieldWarehouseLocationArticleTypeModule } from './components/form-field-warehouse-location-article-type/form-field-warehouse-location-article-type.module';
import { FormFieldComponent } from './form-field.component';
import { FormFieldTimeSpanHoursModule } from './components/form-field-time-span-hours/form-field-time-span-hours.module';
import { FormFieldBackofficePlatformReportResultMethodModule } from './components/form-field-backoffice-platform-report-result-method/form-field-backoffice-platform-report-result-method.module';
import { FormFieldJsonModule } from './components/form-field-json/form-field-json.module';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
@NgModule({
  declarations: [
    FormFieldComponent,
  ],
  exports: [
    FormFieldComponent,
  ],
  imports: [
    AppSharedV2Module,
    CalendarModule,
    CheckboxModule,
    ColorPickerModule,
    DropdownModule,
    FormFieldBackofficePlatformReportResultMethodModule,
    FormFieldJsonModule,
    FormFieldTimeSpanHoursModule,
    FormFieldWarehouseLocationArticleTypeModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    TooltipModule,
    TriStateCheckboxModule,
  ],
})
export class FormFieldModule { }
