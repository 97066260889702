import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebResponse } from '../../core/models';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlSuffixPlaceholder = 'centerv2/user/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  getSessionUser(guidId: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'getsessionuser'),
      {
        guidId: guidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

}
