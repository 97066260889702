
<p-dialog appendTo="body"
  styleClass="prompt-dialog"
  [header]="title"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <form #form="ngForm"
    (ngSubmit)="submit(form)"
    novalidate>

    <lc-form-field *ngIf="isVisible"
      [autoFocus]="true"
      [editMode]="true"
      [label]="label"
      name="prompt"
      [(ngModel)]="value"
      [required]="required"
      [type]="type || 'text'">
    </lc-form-field>

  </form>

  <p-footer>
    <div class="flex-1"></div>

    <lc-button
      [label]="'Cancel' | translate"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [disabled]="form.invalid"
      [isFormSubmit]="true"
      [label]="'Ok' | translate"
      (click)="form.ngSubmit.emit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
