<p-dialog
  styleClass="confirm-dialog"
  [header]="title"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [(visible)]="isVisible">

  <div [innerHTML]="message"></div>

  <p-footer>
    <div class="flex-1"></div>

    <lc-button
      [label]="noText"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [label]="yesText"
      (click)="confirm()"
      [type]="yesType">
    </lc-button>
  </p-footer>
</p-dialog>
