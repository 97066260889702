export const VIEWTYPE_CORE_GPSDEVICE = 'Core.GpsDevice';
export const VIEWTYPE_CORE_GPSDEVICES = 'Core.GpsDevices';
export const VIEWTYPE_TOPFUEL_TANK = 'TopFuel.Tank';
export const VIEWTYPE_TOPFUEL_TANKS = 'TopFuel.Tanks';
export const VIEWTYPE_TOPFUEL_PREEMORDERS = 'TopFuel.PreemOrders';
export const VIEWTYPE_TOPFUEL_TOPFUELORDERS = 'TopFuel.TopFuelOrders';
export const VIEWTYPE_TOPFUEL_TASKS = 'TopFuel.Tasks';
export const VIEWTYPE_TOPFUEL_FORECASTS = 'TopFuel.Forecasts';

export interface WorkerRequest {
  viewType: typeof VIEWTYPE_CORE_GPSDEVICE |
            typeof VIEWTYPE_CORE_GPSDEVICES |
            typeof VIEWTYPE_TOPFUEL_TANK |
            typeof VIEWTYPE_TOPFUEL_TANKS |
            typeof VIEWTYPE_TOPFUEL_PREEMORDERS |
            typeof VIEWTYPE_TOPFUEL_TOPFUELORDERS |
            typeof VIEWTYPE_TOPFUEL_TASKS |
            typeof VIEWTYPE_TOPFUEL_FORECASTS;
  queryType: string;
  designTreeGuidId?: string;
  designTreeFilterSiteGuidId?: string;
  guidId?: string;
  tick: number;
  traceFromDateTime?: string;
  traceToDateTime?: string;
}

export enum RequestType {
  Data = 0,
  Set = 1,
  SolutionEvents = 2,
}

export const SET_TANKS = 'b4f59522-b931-4d68-b4e9-bf87f1ae685e'; // this probably needs to move somewhere else

export interface WorkerV2Request {
  requestType: RequestType;
  fromTick: number;

  setGuidId?: typeof SET_TANKS;
  siteGuidId?: string;
  withPermissions?: boolean;
  accessPermissionGuidId?: string;
  accessPermissionPath?: string;
  permissionGuidIds?: string[];

  solutionEventsFilterProperties?: {
    solutionEventSystemGuidId: string;
    indesigntreeguidid: string;
    fromTime?: string;
    toTime?: string;
  }
}
