import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponentV2 } from 'app/shared/components/base/base-v2.component';
import { SearchInputComponent } from 'app/shared/components/search-input/search-input.component';
import { SessionService } from 'app/shared/services/app';
import { Subscription } from 'rxjs';
import { SearchTreeService } from '../../../../../center/shared/services';
import { DataResponse, DataSource, DictString, SiteGuidIdAndName, SiteTree, TreeSource, TypeEnum } from '../../../../models';
import { AppService } from '../../../../services';



@Component({
  selector: 'search-tree',
  templateUrl: './search-tree.component.html',
  styleUrls: ['./search-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTreeComponent extends BaseComponentV2 implements OnInit, OnDestroy {

  @Input() designTreeGuidId: string;
  @Input() typeGuidIds: string[];
  @Input() environmentTypeGuidIds: DictString<string[]>;
  @Input() width: number;
  @Output() itemSelected: EventEmitter<SiteGuidIdAndName[]>;

  @ViewChild('input', { static: true }) input: SearchInputComponent;

  query: string;

  result: DataResponse;
  searchTreeSubscription: Subscription;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private searchTreeService: SearchTreeService,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);

    this.itemSelected = new EventEmitter();
  }

  ngOnInit() {
    setTimeout(() => {
      this.input.focusInput();
    }, 50);
  }

  ngOnDestroy() {
    this.searchTreeUnsubscribe();
  }

  private searchTreeUnsubscribe() {
    if (this.searchTreeSubscription) {
      this.searchTreeSubscription.unsubscribe();
      this.searchTreeSubscription = null;
    }
  }

  selectItem(item: DataSource) {
    let treeSource = null;
    if (item.typeGuidId === TypeEnum.Site) { // different "search" if SITE
      if (item.siteOnlySiteTree) {
        treeSource = {
          treeGuidId: item.guidId,
          siteTree: item.siteOnlySiteTree,
        };
      } else {
        treeSource = (this.result.webTreeSources || [])
        .find((ts: TreeSource) => {
          return ts.treeGuidId === item.guidId;
        });
      }
    } else {
      treeSource = (this.result.webTreeSources || [])
      .find((ts: TreeSource) => {
        return ts.treeGuidId === item.defaultTreeGuidId;
      });
    }

    treeSource.siteTree = new SiteTree(treeSource.siteTree);

    this.itemSelected.emit([...treeSource.siteTree.getOrderedSitesArray(), { guidId: item.guidId, name: item.getValue('name') }]);
  }

  searchTree() {
    this.input.blurInput();
    this.searchTreeUnsubscribe();

    if (this.typeGuidIds.indexOf(TypeEnum.Site) < 0) {
      this.typeGuidIds.push(TypeEnum.Site);
    }
    if (this.query) {
      this.searchTreeSubscription = this.searchTreeService.search(
        this.designTreeGuidId,
        this.typeGuidIds,
        this.query,
        this.environmentTypeGuidIds,
      )
      .subscribe((dr: DataResponse) => {
        this.result = dr;
        if (this.result.webDataSources) {
          this.result.webDataSources = (this.result.webDataSources || [])
          .sort((ds1: DataSource, ds2: DataSource) => {
            return ((ds1.values.tankid || '') + (ds1.values.name || ''))
            .localeCompare((ds2.values.tankid || '') + (ds2.values.name || ''));
          });
        }

        this.searchTreeUnsubscribe();
        this.cdr.markForCheck();
      },
      (error: any) => {
        this.searchTreeUnsubscribe();
      });
    } else {
      this.result = null;
    }
    this.cdr.markForCheck();
  }

}
