<div #fieldWrapper [ngSwitch]="type">
  <lc-form-field-backoffice-platform-report-result-method *ngSwitchCase="'backofficeplatformreportresultmethod'"
    appendTo="body"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-backoffice-platform-report-result-method>

  <lc-form-field-backoffice-platform-report-result-method *ngSwitchCase="'backofficerunsystemreportresultmethod'"
    appendTo="body"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-backoffice-platform-report-result-method>

  <lc-form-field-json *ngSwitchCase="'json'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-json>

  <lc-form-field-time-span-hours *ngSwitchCase="'timespanhours'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-time-span-hours>

  <lc-form-field-warehouse-location-article-type *ngSwitchCase="'warehouselocationarticletype'"
    [appendTo]="appendTo"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-warehouse-location-article-type>
  

  <div *ngSwitchDefault
    class="form-field flex-col"
    (click)="onTouched()">

    <div class="flex-row">
      <i *ngIf="type === 'relation'"
        class="relation-label-icon fas fa-chart-network">
      </i>

      <label *ngIf="label && (type !== 'checkbox' || !editMode)"
        class="label"
        [innerHTML]="label">
      </label>

      <i *ngIf="labelIconRight"
        style="margin-left: .5rem;"
        [ngClass]="labelIconRight"
        (click)="labelIconRightClick.emit()"
        [pTooltip]="labelIconRightTooltip">
      </i>
    </div>

    <div *ngIf="!editMode || type === 'link' || type === 'relation'"
      class="value">
      <div class="flex-row middle">
        <i *ngIf="iconLeft"
          style="margin-right: .5rem;"
          [ngClass]="iconLeft">
        </i>

        <div *ngIf="type !== 'link'"
          [innerHTML]="dateValue ? (dateValue | dfnsFormat: (type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm')) : dropdownValue != null ? dropdownValue : value != null ? value : noValueHtml">
        </div>
        <a *ngIf="type === 'link'"
          [innerHTML]="dateValue ? (dateValue | dfnsFormat: (type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm')) : dropdownValue != null ? dropdownValue : value != null ? value : noValueHtml"
          (click)="valueLinkClick.emit()">
        </a>
        
        <i *ngIf="iconRight"
          style="margin-left: .5rem;"
          [ngClass]="iconRight"
          (click)="iconRightClick.emit()">
        </i>

        <i *ngIf="type === 'relation' && relationLinkActive && relationLinkClick?.observers?.length"
          style="margin-left: .5rem;"
          class="link fas fa-eye"
          (click)="relationLinkClick.emit()">
        </i>

        <i *ngIf="type === 'relation' && relationEditActive && relationEditClick?.observers?.length"
          style="margin-left: .5rem;"
          class="link fas fa-pencil-alt"
          (click)="relationEditClick.emit()">
        </i>
      </div>
    </div>

    <div *ngIf="editMode && type !== 'link' && type !== 'relation'">
      <p-checkbox *ngIf="type === 'checkbox'"
        binary="true"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [label]="label">
      </p-checkbox>

      <p-triStateCheckbox *ngIf="type === 'checkbox3'"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [label]="value === true ? ('Yes' | translate) : value === false ? ('No' | translate) : ('Not Set' | translate)">
      </p-triStateCheckbox>

      <p-calendar *ngIf="(type || '').indexOf('date') >= 0"
        [appendTo]="appendTo"
        styleClass="calendar"
        dateFormat="yy-mm-dd"
        [disabled]="disabled"
        hourFormat="24"
        [locale]="locale"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [(ngModel)]="dateValue"
        (onBlur)="updateValueWithDate(dateValue)"
        (onSelect)="updateValueWithDate(dateValue)"
        [showTime]="type === 'datetime'">
      </p-calendar>

      <p-dropdown *ngIf="type === 'dropdown'"
        [appendTo]="appendTo"
        styleClass="dropdown"
        panelStyleClass="dropdown-panel"
        [autoDisplayFirst]="false"
        [disabled]="disabled"
        [filter]="(!dropdownGroup && options?.length > 7) || (dropdownGroup && options?.length > 2)"
        [group]="dropdownGroup"
        [optionLabel]="optionLabel"
        [options]="options"
        [placeholder]="!options ? ('Loading...' | translate) : placeholder"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [required]="required"
        [showClear]="dropdownShowClear">
        <ng-template let-item pTemplate="selectedItem">
          <div class="flex-row middle">
            <div *ngIf="item.icon" [ngClass]="item.icon"></div>

            <div class="dropdown-label">
              {{ optionLabel ? (item.value ? item.value[optionLabel] : item[optionLabel]) : item.label }}
            </div>
          </div>
        </ng-template>

        <ng-template let-item pTemplate="item">
          <div class="flex-row middle"
            style="min-height: 2rem"
            [style.padding-left]="dropdownGroup ? '1rem': '0'">
            <div *ngIf="item.icon" [ngClass]="item.icon"></div>

            <div [class.ellipsis]="item.title || item.description">
              {{ optionLabel ? (item.value ? item.value[optionLabel] : item[optionLabel]) : item.label }}
            </div>
          </div>

          <div class="description"
            [innerHTML]="item.title || item.description || ''">
          </div>
        </ng-template>
      </p-dropdown>

      <p-multiSelect *ngIf="type === 'multi-select'"
        [appendTo]="appendTo"
        [disabled]="disabled"
        [filter]="options?.length > 7"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [options]="options"
        [optionLabel]="optionLabel"
        [panelStyleClass]="options?.length > 7 ? '' : 'ui-multiselect-no-header'"
        [showToggleAll]="options?.length > 7"
        [defaultLabel]="">
      </p-multiSelect>

      <p-multiSelect *ngIf="type === 'multi-select-flags'"
        [appendTo]="appendTo"
        [disabled]="disabled"
        [(ngModel)]="multiSelectFlagsValue"
        (ngModelChange)="setValueFromMultiSelectFlagsValue($event)"
        [options]="options"
        [optionLabel]="optionLabel"
        [panelStyleClass]="options?.length > 7 ? '' : 'ui-multiselect-no-header'"
        [filter]="false"
        [showToggleAll]="options?.length > 7"
        [defaultLabel]="'None' | translate">
      </p-multiSelect>

      <textarea *ngIf="type === 'textarea'"
        #inputElement
        pInputText
        [autofocus]="autoFocus"
        class="input"
        [disabled]="disabled"
        [minlength]="minLength"
        [maxlength]="maxLength"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [rows]="textAreaRows || 4">
      </textarea>

      <input *ngIf="['checkbox', 'checkbox3', 'date', 'datetime', 'dropdown', 'link', 'multi-select', 'multi-select-flags', 'textarea'].indexOf(type) < 0"
        #inputElement
        pInputText
        [autofocus]="autoFocus"
        [disabled]="disabled"
        (blur)="onBlur ? onBlur.emit($event) : null"
        [max]="maxNumber"
        [maxlength]="maxLength"
        [min]="minNumber"
        [minlength]="minLength"
        [ngClass]="type === 'color' ? 'input-color-picker' : 'input'"
        [(ngModel)]="value"
        (ngModelChange)="type === 'number' ? updateValueWithNumber($event) : onChange($event)"
        [pattern]="pattern"
        [placeholder]="placeholder || ''"
        [required]="required"
        [type]="['number', 'password', 'textarea', 'text'].indexOf(type) >= 0 ? type : 'text'"/>

      <p-colorPicker *ngIf="type === 'color'"
        appendTo="body"
        class="color-picker"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)">
      </p-colorPicker>
    </div>
  </div>
</div>
