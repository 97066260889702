import { Injectable } from '@angular/core';
import { RunEngine } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class RunEngineService {

  private urlSuffixPlaceholder = 'centerv2/runengine/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  listDeviceRunEngine(): Observable<RunEngine[]> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'devicerunengine/list'),
      {
      }
    ).pipe(
      map((response: any) => {
        return response ? response.deviceRunEngines : [];
      })
    );
  }

}
