import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const appRoutes: Routes = [
  {
    path: 'auth', redirectTo: 'v2/auth',
  },
  {
    path: 'dt/:deviationTaskShareBaseGuidId', redirectTo: 'v2/center/deviation-task/:deviationTaskShareBaseGuidId',
  },
  {
    path: 'oauth2/redirect', redirectTo: 'v2/auth/oauth2',
  },
  {
    path: 'v2/auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'center',
    loadChildren: () => import('app/center/center.module').then(m => m.CenterModule)
  },
  {
    path: 'v2/center',
    loadChildren: () => import('app/center-v2/center-v2.module').then(m => m.CenterV2Module)
  },
  {
    path: 'demos',
    loadChildren: () => import('app/demos/demos.module').then(m => m.DemosModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('app/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'studio',
    loadChildren: () => import('app/studio/studio.module').then(m => m.StudioModule)
  },

  // custom login modules
  {
    path: 'volymkoll',
    loadChildren: () => import('app/volymkoll/volymkoll.module').then(m => m.VolymkollModule)
  },
  // ---

  {
    path: '**',
    redirectTo: 'landing'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
