import { Injectable } from '@angular/core';
import { BrowserUtils } from 'app/shared/utils';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Workspace } from '../../core/models';



@Injectable({
  providedIn: 'root'
})
export class WorkspaceWidgetService {

  private readonly storageCurrentWorkspaceKey = 'lc_current_workspace';

  private widgetSettingsChangeSubject: Subject<any>;
  private refreshWidgetSubject: Subject<string>;

  constructor(
  ) {
    this.widgetSettingsChangeSubject = new Subject();
    this.refreshWidgetSubject = new Subject();
  }

  getWorkspace(): Workspace {
    const params = BrowserUtils.getQueryParams();
    const json = sessionStorage.getItem(this.storageCurrentWorkspaceKey);
    const jsonObject = JSON.parse(json);
    return jsonObject && jsonObject.guidId === params?.workspaceGuidId ? new Workspace(jsonObject) : null;
  }

  setWorkspace(workspace: Workspace): void {
    if (workspace?.solutionProfile?.systemEvents) {
      delete workspace.solutionProfile.systemEvents;
    }

    try {
      sessionStorage.setItem(this.storageCurrentWorkspaceKey, JSON.stringify(workspace));
    } catch (error) {
      // This is pretty much here to cover the scenario where the storage gets above
      // the maximum size and it would start failing all the time if we don't free up
      // some space
      sessionStorage.clear();
      sessionStorage.setItem(this.storageCurrentWorkspaceKey, JSON.stringify(workspace));
    }
  }

  listenToWidgetSettingsChange(): Observable<any> {
    return this.widgetSettingsChangeSubject.asObservable();
  }

  widgetSettingsChange(settings: any) {
    this.widgetSettingsChangeSubject.next(settings);
  }

  listenToRefreshWidgetMessage(widgetGuidId: string): Observable<void> {
    return this.refreshWidgetSubject.asObservable()
    .pipe(
      filter((guidId: string) => {
        return !guidId || guidId === widgetGuidId;
      }),
      map(() => {
        return;
      })
    );
  }

  refreshWidget(guidId?: string) {
    this.refreshWidgetSubject.next(guidId);
  }


}
