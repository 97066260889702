import { Injectable } from '@angular/core';
import { WebCenterType, Workspace, WorkspacePointer, WorkspaceSolutionType, WorkspaceStructure, WorkspaceType, WorkspaceWidget, WorkspaceWidgetTemplate, WorkspaceWidgetTemplateGroup } from 'app/center-v2/core/models';
import { DictString, SolutionType } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { WorkspaceWidgetService } from './workspace-widget.service';



@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  private urlSuffixPlaceholder = 'centerv2/workspace/{what}';

  private centerTypesMap: DictString<WorkspaceSolutionType[]>; // cache
  private solutionTypesMap: DictString<WorkspaceSolutionType[]>; // cache

  constructor(
    private apiService: ApiCenterV2Service,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    this.centerTypesMap = {};
    this.solutionTypesMap = {};
  }

  clearCenterTypesCache(workspaceGuidId: string): void {
    delete this.centerTypesMap[workspaceGuidId]; // clear cache
  }

  listCenterTypes(workspaceGuidId: string): Observable<WebCenterType[]> {
    const request = this.centerTypesMap && this.centerTypesMap[workspaceGuidId] ?
      of({ web2CenterTypes: this.centerTypesMap[workspaceGuidId] })
      .pipe(
        delay(10)
      ) : 
      this.apiService.post<any>(
        this.urlSuffixPlaceholder.replace('{what}', 'listcentertypes'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    );

    return request.pipe(
      map((response: any) => {
        let result = [];
        for (var item of response?.web2CenterTypes || []) {
          result.push(new WebCenterType(item));
        }

        if (!this.centerTypesMap[workspaceGuidId]) {
          this.centerTypesMap[workspaceGuidId] = result; // set cache
        }
        
        return result;
      })
    );
  }

  clearSolutionTypeCache(workspaceGuidId: string): void {
    delete this.solutionTypesMap[workspaceGuidId]; // clear cache
  }

  /** @deprecated */
  listSolutionTypes(workspaceGuidId: string): Observable<WorkspaceSolutionType[]> {
    const request = this.solutionTypesMap && this.solutionTypesMap[workspaceGuidId] ?
      of({ toolSolutionTypes: this.solutionTypesMap[workspaceGuidId] })
      .pipe(
        delay(10)
      ) : 
      this.apiService.post<any>(
        this.urlSuffixPlaceholder.replace('{what}', 'toolsolutiontype/list'),
        {
          workspaceGuidId: workspaceGuidId,
        }
      );

    return request.pipe(
        map((response: any) => {
          const result = [];
          for (const st of response?.toolSolutionTypes || []) {
            if (st) {
              const wst = new WorkspaceSolutionType(st)
              result.push(wst);
            }
          }

          if (!this.solutionTypesMap[workspaceGuidId]) {
            this.solutionTypesMap[workspaceGuidId] = result; // set cache
          }

          return result;
        })
      );
  }

  /**
   * Used for getting the list of solutionTypes
   * @param workspaceGuidId
   * @param storageGuidId use GuidUtils.emptyGuid() for workspace global solutionTypes or widgetGuidId for widget scoped solutionTypes
   * @param workspaceSolutionTypes fallback to workspace scoped solutionTypes
   * @param solutionType
   */
   listSolutionTypesV2(workspaceGuidId: string, storageGuidId: string, workspaceSolutionTypes: boolean, solutionTypeGuidIds?: string[]): Observable<WorkspaceSolutionType[]> {
    const request = this.solutionTypesMap && this.solutionTypesMap[workspaceGuidId] ?
      of({ toolSolutionTypes: this.solutionTypesMap[workspaceGuidId] })
      .pipe(
        delay(10)
      ) : 
      this.apiService.post<any>(
        this.urlSuffixPlaceholder.replace('{what}', 'toolsolutiontype/getstorage'), // getstoragecompiled
        {
          workspaceGuidId: workspaceGuidId,
          storageGuidId: storageGuidId,
          workspaceSolutionTypes: workspaceSolutionTypes,
          solutionTypeGuidIds: solutionTypeGuidIds,
        }
      );

    return request.pipe(
        map((response: any) => {
          const result = [];
          for (const st of response?.toolSolutionTypes || []) {
            if (st) {
              const wst = new WorkspaceSolutionType(st)
              result.push(wst);
            }
          }

          if (!this.solutionTypesMap[workspaceGuidId]) {
            this.solutionTypesMap[workspaceGuidId] = result; // set cache
          }

          return result;
        })
      );
  }

  /**
   * Used for new / update / delete
   * @param workspaceGuidId
   * @param storageGuidId use GuidUtils.emptyGuid() for workspace global solutionTypes or widgetGuidId for widget scoped solutionTypes
   * @param solutionTypeGuidId
   * @param solutionType
   */
  updateSolutionType(workspaceGuidId: string, storageGuidId: string, solutionTypeGuidId: string, solutionType: SolutionType): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'toolsolutiontype/update'),
      {
        workspaceGuidId: workspaceGuidId,
        storageGuidId: storageGuidId,
        toolSolutionTypeGuidId: solutionTypeGuidId,
        toolSolutionType: solutionType,
      }
    ).pipe(
      map((response: any) => {
        this.clearSolutionTypeCache(workspaceGuidId);
        return response;
      })
    );
  }

  adminNewUserWorkspace(userGuidId: string, parentWorkspaceGuidId: string, name: string): Observable<void> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'admin/newuserworkspace'),
      {
        parentWorkspaceGuidId: parentWorkspaceGuidId,
        userGuidId: userGuidId,
        name: name,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspace : [];
      })
    );
  }

  setUserWorkspace(userGuidId: string, workspaceGuidId: string, sortOrder?: number, favourite?: boolean, favouriteGroup?: string, hide?: boolean, remove?: boolean): Observable<void> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'userworkspace'),
      {
        workspaceGuidId: workspaceGuidId,
        userGuidId: userGuidId,
        sortOrder: sortOrder,
        favourite: favourite,
        favouriteGroup: favouriteGroup,
        hide: hide,
        remove: remove,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspace : [];
      })
    );
  }

  getUserWorkspaces(solutionProfileGuidId?: string): Observable<WorkspacePointer[]> {
    return this.apiService.post<WorkspacePointer[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'getuserworkspaces'),
      {
        solutionProfileGuidId: solutionProfileGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspacePointers : [];
      })
    );
  }

  newWorkspace(
    siteGuidId: string,
    solutionProfileGuidId: string,
    parentWorkspaceGuidId: string,
    name: string,
    demo: boolean,
    shared: boolean,
    color: string,
    workspaceType: WorkspaceType,
    primaryEnvironmentGuidId: string,
  ): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        siteGuidId: siteGuidId,
        solutionProfileGuidId: solutionProfileGuidId,
        parentWorkspaceGuidId: parentWorkspaceGuidId,
        name: name,
        demo: demo,
        shared: shared,
        color: color,
        workspaceType: workspaceType,
        primaryEnvironmentGuidId: primaryEnvironmentGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  updateWorkspace(
    workspaceGuidId: string,
    name: string,
    demo: boolean,
    shared: boolean,
    color: string,
  ): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'update'),
      {
        workspaceGuidId: workspaceGuidId,
        name: name,
        demo: demo,
        shared: shared,
        color: color,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  getStructure(workspaceGuidId?: string): Observable<WorkspaceStructure> {
    return this.apiService.post<WorkspacePointer[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'getstructure'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspaceStructure : null;
      })
    );
  }

  /*getWorkspace(workspaceGuidId: string): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        const workspace = response ? new Workspace(response.workspace) : null;
        this.workspaceWidgetService.setWorkspace(workspace);
        return workspace
      })
    );
  }*/

  getCompiledWorkspace(workspaceGuidId: string): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'getcompiled'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        const workspace = response ? new Workspace(response.compiledWorkspace) : null;
        this.workspaceWidgetService.setWorkspace(workspace);
        return workspace
      })
    );
  }

  addWidget(
    workspaceGuidId: string,
    widgetTemplateGuidId: string,
    workspaceWidgetSettingJson: string,
  ): Observable<WorkspaceWidget> {
    return this.apiService.post<WorkspaceWidget>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/add'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetTemplateGuidId: widgetTemplateGuidId,
        workspaceWidgetSettingJson: workspaceWidgetSettingJson,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  updateWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
    workspaceWidgetSettingJson: string,
  ): Observable<WorkspaceWidget> {
    return this.apiService.post<WorkspaceWidget>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/update'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
        workspaceWidgetSettingJson: workspaceWidgetSettingJson,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  removeWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
  ): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/remove'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  setSettingWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
    settingGuidId: string,
    removeSetting: boolean,
    name: string,
    sysVersion: number,
    jsonValue: any,
  ): Observable<WorkspaceWidget> {
    return this.apiService.post<WorkspaceWidget>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/setsetting'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
        settingGuidId: settingGuidId,
        removeSetting: removeSetting,
        name: name,
        sysVersion: sysVersion,
        jsonValue: jsonValue || 'null',
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  getWidget(widgetGuidId: string): Observable<WorkspaceWidget> {
    return this.apiService.post<WorkspaceWidget>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/get'),
      {
        widgetGuidId: widgetGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      }),
      // share(),
      // take(1),
    );
  }

  getWidgetTemplate(widgetTemplateGuidId: string): Observable<WorkspaceWidgetTemplate> {
    return this.apiService.post<WorkspaceWidget>(
      'centerv2/coreadmin/{what}'.replace('{what}', 'widget/template/get'),
      {
        widgetTemplateGuidId: widgetTemplateGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidgetTemplate(response.widgetTemplate) : null;
      })
    );
  }

  listGroupAccess(workspaceGuidId: string): Observable<WorkspaceWidgetTemplateGroup[]> {
    return this.apiService.post<WorkspaceWidgetTemplateGroup[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/template/listgroupaccess'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.widgetTemplateGroups : [];
      })
    );
  }

  listTemplateAccess(workspaceGuidId: string, widgetTemplateGroupGuidId: string): Observable<WorkspaceWidgetTemplate[]> {
    return this.apiService.post<WorkspaceWidgetTemplate[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'widget/template/listtemplateaccess'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetTemplateGroupGuidId: widgetTemplateGroupGuidId,
      }
    ).pipe(
      map((response: any) => {
        const result = [];
        if (response) {
          for (const wt of response.widgetTemplates || []) {
            result.push(new WorkspaceWidgetTemplate(wt));
          }
        }
        return result;
      })
    );
  }


}
