import { SolutionProfile, SolutionProfileTemplateSolution, SolutionProfileTemplateSolutionWebFeature } from '../../../shared/models/solution';
import { Tag } from '../../../shared/models/tag.model';
import { WebObject } from './web-base/web-object.model';
import { WorkspaceWidget } from './workspace-widget.model';

export enum WorkspaceType {
  User = 0,
  Template = 1,
  SolutionProfile = 2,
}

export class Workspace extends WebObject {

  // TODO: remove these as they'll be replaced by the new web object "members" field
  guidId: string;
  tags: Tag[];
  name: string;
  activeMode: number;
  color: string;
  demo: boolean;
  shared: boolean;
  readOnly: boolean;
  workspaceType: WorkspaceType;
  adminUser: any;
  solutionProfile: SolutionProfile;
  solutionProfileName: string;
  primaryEnvironmentName: string;
  environments: any;
  templateSolutions: SolutionProfileTemplateSolution;
  templateSolutionWebFeatures: SolutionProfileTemplateSolutionWebFeature[];
  widgets: WorkspaceWidget[];
  web2Languages: WebObject[];
  web2Environments: WebObject[];
  web2PrimaryEnvironment: WebObject;

  members: {
    guidId: string;
    tags: Tag[];
    name: string;
    activeMode: number;
    color: string;
    demo: boolean;
    shared: boolean;
    readOnly: boolean;
    workspaceType: WorkspaceType;
    adminUser: any;
    solutionProfile: SolutionProfile;
    solutionProfileName: string;
    environments: any;
    templateSolutions: SolutionProfileTemplateSolution;
    templateSolutionWebFeatures: SolutionProfileTemplateSolutionWebFeature[];
    widgets: WorkspaceWidget[];
    web2Languages: WebObject[];
    web2Environments: WebObject[];
    web2PrimaryEnvironment: WebObject;
  }

  constructor(item?: Partial<Workspace>) {
    super(item);

    this.members = this.members || {} as any;
    this.widgets = (this.widgets || []).map((x: WorkspaceWidget) => {
      return new WorkspaceWidget(x);
    });
    this.members.widgets = (this.members?.widgets || []).map((x: WorkspaceWidget) => {
      return new WorkspaceWidget(x);
    });

    this.web2PrimaryEnvironment = new WebObject(this.web2PrimaryEnvironment);
    
    delete this.web2PrimaryEnvironment.$originalWebObject;
    delete this.$originalWebObject;
  }

  getWidget(widgetGuidId: string) {
    const widgets = this.members?.widgets?.length ? this.members?.widgets : this.widgets;
    return (widgets || []).find((w: WorkspaceWidget) => {
      return w.guidId === widgetGuidId;
    });
  }

}
