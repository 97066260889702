<div class="toolpanel-admin-view-design">
  <div class="header-label ta-center">
    {{ 'Admin - View Design' | translate }}
  </div>

  <div class="flex-row space-evenly">
    <lc-button
      [label]="'Clear State' | translate"
      (click)="clearViewDesignState()"
      type="secondary">
    </lc-button>

    <lc-button
      [label]="'Save State' | translate"
      (click)="saveViewDesignState()"
      type="success">
    </lc-button>
  </div>

  <br><br>

  <p-accordion [multiple]="true">
    <p-accordionTab [header]="'Relative Date Filters' | translate">
      <div *ngFor="let item of relativeDateFilterArray; let i = index;"
        class="section flex-row">
        <lc-form-field class="flex-1"
          [disabled]="true"
          [editMode]="true"
          [label]="'Column' | translate"
          name="relativeDateMemberGuidId + {{ i }}"
          [ngModel]="item.value"
          [options]="relativeDateFilterArray || []"
          type="dropdown">
        </lc-form-field>

        <lc-form-field class="flex-1"
          [dropdownShowClear]="true"
          [editMode]="true"
          [label]="'Relative Filter' | translate"
          name="relativeDateFilterType + {{ i }}"
          [(ngModel)]="item.filterModel.type"
          (ngModelChange)="item.filterModel.type >= 5 ? (item.filterModel.dateFrom = item.filterModel.dateFrom || 1) : (item.filterModel.dateFrom = undefined);"
          [options]="relativeDateFilterTypeItems || []"
          type="dropdown">
        </lc-form-field>

        <lc-form-field *ngIf="item.filterModel.type >= 5"
          [editMode]="true"
          [label]="'X Value' | translate"
          name="relativeDateFilterXValue + {{ i }}"
          [(ngModel)]="item.filterModel.dateFrom"
          type="number">
        </lc-form-field>
      </div>

      <div class="flex-row center">
        <lc-button
          [label]="'Apply to Grid' | translate"
          (click)="applyRelativeDateFilterModel()"
          type="primary">
        </lc-button>
      </div>
    </p-accordionTab>

    <p-accordionTab [header]="'Row Colors' | translate">
      <div class="section flex-col">
        <lc-form-field
          [dropdownShowClear]="true"
          [editMode]="true"
          [label]="'Column' | translate"
          name="gridRowColorMember"
          [(ngModel)]="rowStyleLogic.memberGuidId"
          (ngModelChange)="updateRowStyleMappings()"
          [options]="viewDesignMemberItems || []"
          type="dropdown">
        </lc-form-field>

        <div *ngIf="rowStyleLogic.memberGuidId">
          <label class="label">
            {{ 'Color Mapping' | translate }}
          </label>

          <div *ngFor="let item of rowStyleLogic?.colorMappings; let i = index;"
            class="flex-row">
            <lc-form-field
              style="margin-right: 4px;"
              [editMode]="true"
              [label]="i === 0 ? ('Operator' | translate) : ''"
              name="operator"
              [(ngModel)]="item.operator"
              [options]="operatorItems || []"
              type="dropdown">
            </lc-form-field>

            <lc-form-field class="flex-1"
              style="margin-right: 4px; max-width: 90px;"
              [editMode]="true"
              [label]="i === 0 ? ('Value' | translate) : ''"
              name="memberValue"
              [(ngModel)]="item.value"
              (ngModelChange)="updateRowStyleMappings()"
              type="text">
            </lc-form-field>

            <lc-form-field class="flex-1"
              [editMode]="true"
              [label]="i === 0 ? ('Back Color' | translate) : ''"
              name="rowBackgroundColor"
              [(ngModel)]="item.backgroundColor"
              type="color">
            </lc-form-field>

            <lc-form-field class="flex-1"
              [editMode]="true"
              [label]="i === 0 ? ('Text Color' | translate) : ''"
              name="rowForegroundColor"
              [(ngModel)]="item.color"
              type="color">
            </lc-form-field>
          </div>
        </div>
      </div>

      <div class="flex-row center">
        <lc-button
          [label]="'Apply to Grid' | translate"
          (click)="applyRowStyleLogic()"
          type="primary">
        </lc-button>
      </div>
    </p-accordionTab>

    <p-accordionTab [header]="'Cell Colors' | translate">
      <div *ngFor="let cellStyleItem of cellStyleLogicArray"
        class="section flex-col">
        <lc-form-field
          [dropdownShowClear]="true"
          [editMode]="true"
          [label]="'Column' | translate"
          name="gridRowColorMember"
          [(ngModel)]="cellStyleItem.memberGuidId"
          (ngModelChange)="updateCellStyleMappings()"
          [options]="viewDesignMemberItems || []"
          type="dropdown">
        </lc-form-field>

        <div *ngIf="cellStyleItem.memberGuidId">
          <label class="label">
            {{ 'Color Mapping' | translate }}
          </label>

          <div *ngFor="let item of cellStyleItem?.colorMappings; let i = index;"
            class="flex-row">
            <lc-form-field
              style="margin-right: 4px;"
              [editMode]="true"
              [label]="i === 0 ? ('Operator' | translate) : ''"
              name="operator"
              [(ngModel)]="item.operator"
              [options]="operatorItems || []"
              type="dropdown">
            </lc-form-field>

            <lc-form-field class="flex-1"
              style="margin-right: 4px; max-width: 90px;"
              [editMode]="true"
              [label]="i === 0 ? ('Value' | translate) : ''"
              name="memberValue"
              [(ngModel)]="item.value"
              (ngModelChange)="updateCellStyleMappings()"
              type="text">
            </lc-form-field>

            <lc-form-field class="flex-1"
              [editMode]="true"
              [label]="i === 0 ? ('Back Color' | translate) : ''"
              name="rowBackgroundColor"
              [(ngModel)]="item.backgroundColor"
              type="color">
            </lc-form-field>

            <lc-form-field class="flex-1"
              [editMode]="true"
              [label]="i === 0 ? ('Text Color' | translate) : ''"
              name="rowForegroundColor"
              [(ngModel)]="item.color"
              type="color">
            </lc-form-field>
          </div>
        </div>
      </div>

      <div class="flex-row center">
        <lc-button
          [label]="'Apply to Grid' | translate"
          (click)="applyCellStyleLogic()"
          type="primary">
        </lc-button>
      </div>
    </p-accordionTab>
  </p-accordion>

  <ngx-loading [show]="isBusy"></ngx-loading>
</div>

