export class SolutionObjectValue {

  guidId: string;
  name: string;
  value: any;
}

export class SolutionObject {

  guidId: string;
  values: any;

  constructor(item?: any) {
    Object.assign(this, item);
  }

  getFieldName(key: string) {
    const value = this.values[key] as SolutionObjectValue;
    return value ? value.name.charAt(0).toLowerCase() + value.name.slice(1) : undefined;
  }

  getName(key: string) {
    const value = this.values[key] as SolutionObjectValue;
    return value ? value.name : undefined;
  }

  getValue(key: string, defaultValue?: any) {
    const value = this.values[key] as SolutionObjectValue;
    return value && value.value != null ? value.value : defaultValue;
  }

}
