import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse, DataSource } from '../../../../shared/models';
import { ApiCenterService } from '../../../../shared/services';
import { ENVIRONMENT_GUIDID, RelationDataRequest, RFM_TREECHILD } from './relation-data-request.model';



@Injectable({
  providedIn: 'root'
})
export class RelationDataService {
  private readonly URL_SUFFIX = 'api/v0/relationdata';

  constructor(private apiService: ApiCenterService) { }

  /**
   * Method to get Relational DataResponse stuff.
   * @param {string} guidId Object of interest guidId
   * @param {string} siteGuidId Parent site (of object of interest) guidId
   * @param {string} typeTreeRelationGuidId GuidId that we want to return available values for
   */
  get(guidId: string, siteGuidId: string, typeTreeRelationGuidId: string): Observable<DataResponse> {
    let request = <RelationDataRequest>{
      guidId: guidId,
      siteGuidId: siteGuidId,
      typeTreeRelationGuidId: typeTreeRelationGuidId,
    };
    return this.makeRequest(request);
  }

  /**
   * Method to get Relational DataResponse stuff.
   * @param {string} guidId Object of interest guidId
   * @param {string} typeTreeRelationGuidId GuidId that we want to return available values for
   */
  getTreeChilds(guidId: string, typeTreeRelationGuidId: string): Observable<DataResponse> {
    let request = <RelationDataRequest>{
      guidId: guidId,
      relationFilterMode: RFM_TREECHILD,
      typeTreeRelationGuidId: typeTreeRelationGuidId,
      environmentGuidId: ENVIRONMENT_GUIDID,
    };
    return this.makeRequest(request);
  }

  private makeRequest(request: RelationDataRequest): Observable<DataResponse> {
    return this.apiService.post<DataResponse>(this.URL_SUFFIX, request)
    .pipe(
      map((dr: DataResponse) => {
        dr.webDataSources = plainToClass(DataSource, dr.webDataSources);

        return dr;
      })
    );
  }

}
