<div class="flex-row middle"
  [ngClass]="params?.cellClass"
  [pTooltip]="params?.tooltip">
  <span *ngIf="params?.group" class="ag-group-expanded" ref="eExpanded">
    <span class="ag-icon ag-icon-tree-open" unselectable="on">
    </span>
  </span>
  <span *ngIf="params?.group" class="ag-group-contracted" ref="eContracted">
    <span class="ag-icon ag-icon-tree-closed" unselectable="on">
    </span>
  </span>

  <span class="width-100 flex-row middle"
    (click)="onClick($event)"
    (dblclick)="onDoubleClick($event)">
    <i *ngIf="params?.iconLeft"
      style="margin-right: .5rem;"
      [ngClass]="params.iconLeft"
      [ngStyle]="params?.iconLeftStyle">
    </i>

    <span *ngIf="!params?.isDate && !params?.isLink"
      [innerHTML]="value | highlight:params?.filter ? params.filter() : noValueHtml">
    </span>

    <span *ngIf="params?.isDate && !params?.withTime && value"
      [innerHTML]="value | dfnsFormat: 'yyyy-MM-dd' | highlight:(params?.filter ? params.filter() : '')">
    </span>

    <span *ngIf="params?.isDate && params?.withTime && value"
      [innerHTML]="value | dfnsFormat: 'yyyy-MM-dd HH:mm' | highlight:(params?.filter ? params.filter() : '')">
    </span>

    <a *ngIf="!params?.isDate && params?.isLink"
      draggable="false"
      href="javascript: void(0);"
      [innerHTML]="value || noValueHtml">
    </a>

    <div *ngIf="params?.iconRight && !params?.iconRightNotFarRight" class="flex-1"></div>

    <i *ngIf="params?.iconRight"
      style="margin-left: .5rem;"
      [ngClass]="params.iconRight"
      [ngStyle]="params?.iconRightStyle"
      [pTooltip]="params?.iconRightTooltip">
    </i>
  </span>
</div>

<i *ngIf="params?.data && isEditable"
  class="cell-hover-icon {{ params.disabled ? 'fas fa-lock' : 'fas fa-pencil-alt' }}">
</i>
