export enum PreemOrderStatus {
  New = 0, // order created
  Requested = 1, // order created & sms sent
  Confirmed = 2, // user has confirmed the order
  Planned = 3, // planned by preem
  Delivered = 4, // the tank has gotten +5%
  Completed = 5,
  OrderRequest = 6,
  Cancelled = 7,
}
