import { Injectable } from '@angular/core';
import { WorkspaceWidgetService } from 'app/center-v2/shared/services';
import { CenterType, DeviceSolutionSet, DeviceSolutionType, Solution, SolutionEnvironment, SolutionFeature, SolutionProfile, SolutionProfileOverview, SolutionType } from 'app/shared/models';
import { StorageUtils } from 'app/shared/utils';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterService } from '../api/api-center.service';




@Injectable({
  providedIn: 'root'
})
export class SolutionProfileService {

  private readonly localSelectedSolutionProfileKey = 'lc_selected_solution_profile';

  private urlSuffixPlaceholder = 'api/v0/solutionprofile/{what}';
  private urlSuffixV2Placeholder = 'solutionprofile/{what}';
  private urlSuffixCenterV2Placeholder = 'centerv2/solutionprofile/{what}';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) { }

  getSelectedGuidId(): string {
    return StorageUtils.getItem('local', this.localSelectedSolutionProfileKey);
  }

  setSelectedGuidId(solutionProfileGuidId: string) {
    if (solutionProfileGuidId) {
      StorageUtils.setItem('local', this.localSelectedSolutionProfileKey, solutionProfileGuidId);
    } else {
      StorageUtils.removeItem('local', this.localSelectedSolutionProfileKey);
    }
  }



  landing(siteGuidId?: string): Observable<SolutionProfile[]> {
    return this.apiService.post<SolutionProfile[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'landing'),
      {
        siteGuidId: siteGuidId
      }
    )
    .pipe(
      map((response: any) => {
        if (response && response.solutionProfiles) {
          // sort the profiles by name
          response.solutionProfiles = response.solutionProfiles
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          for (const solProfile of response.solutionProfiles) {
            // sort the environments by environment number
            solProfile.solutionEnvironments = (solProfile.solutionEnvironments || [])
            .sort((a, b) => {
              return a.environment - b.environment;
            });
          }
          return plainToClass(SolutionProfile, response.solutionProfiles as SolutionProfile[]);
        }
        return null;
      })
    );
  }

  new(name: string, siteGuidId: string, testEnvironment: boolean, demo: boolean): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
    {
      name: name,
      siteGuidId: siteGuidId,
      testEnvironment: testEnvironment,
      demo: demo,
    })
  }

  get(solutionProfileGuidId: string): Observable<SolutionProfileOverview> {
    return this.apiService.post<SolutionProfileOverview>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    })
    .pipe(
      map((response: any) => {
        if (response && response.overviewSolutionProfile) {
          const solProfile = response.overviewSolutionProfile.solutionProfile;
          // sort the environments by environment number
          solProfile.solutionEnvironments = (solProfile.solutionEnvironments || [])
          .sort((a, b) => {
            return a.environment - b.environment;
          });
          return plainToClass(SolutionProfileOverview, response.overviewSolutionProfile as SolutionProfileOverview);
        } else {
          return null;
        }
      })
    );
  }


  listEnvironments(solutionProfileGuidId: string): Observable<SolutionEnvironment[]> {
    return this.apiV2Service.post<SolutionEnvironment[]>(
      this.urlSuffixV2Placeholder.replace('{what}', 'solutionenvironment/list'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    })
    .pipe(
      map((response: any) => {
        return response && response.solutionEnvironments ? response.solutionEnvironments : null;
      })
    );
  }

  listSolutions(solutionProfileGuidId: string): Observable<Solution[]> {
    return this.apiService.post<Solution[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/list'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    })
    .pipe(
      map((response: any) => {
        return response && response.solutions ? response.solutions : null;
      })
    );
  }



  listSolutionTypes(solutionProfileGuidId: string): Observable<SolutionType[]> {
    return this.apiService.post<SolutionType[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'solutiontype/list'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    })
    .pipe(
      map((response: any) => {
        if (response && response.solutionTypes) {
          const result = [];
          for (const st of response.solutionTypes) {
            result.push(new DeviceSolutionType(st));
          }
          return result;
        } else {
          return [];
        }
      })
    );
  }

  listCenterTypes(solutionProfileGuidId: string): Observable<CenterType[]> {
    return this.apiService.post<CenterType[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'solutiontype/listcentertype'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    })
    .pipe(
      map((response: any) => {
        if (response) {
          let result = [];
          for (var item of response.centerTypes || []) {
            result.push(new CenterType(item));
          }
          return result;
        } else {
          return [];
        }
      })
    );
  }

  /**
   * Used for new / update / delete
   * @param solutionProfileGuidId
   * @param solutionTypeGuidId
   * @param solutionType
   */
  updateSolutionType(solutionProfileGuidId: string, solutionTypeGuidId: string, solutionType: SolutionType): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'solutiontype/update'),
      {
        solutionProfileGuidId,
        solutionTypeGuidId: solutionTypeGuidId,
        solutionType: solutionType,
      }
    );
  }

  listRunEngines(solutionProfileGuidId: string): Observable<any[]> {
    return this.apiV2Service.post<any[]>(
      this.urlSuffixV2Placeholder.replace('{what}', 'runengine/get'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    });
  }

  newSolutionSet(
    name: string,
    solutionProfileGuidId: string,
    solutionEnvironmentGuidId: string,
    runEngineGuidId: string,
    deviceSolutionGuidId: string,
    createEnrollmentProfile: boolean,
    enrollmentProfileSiteGuidId: string,
    enrollmentProfileKey: string,
    enrollmentProfilePassword: string,
  ): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'devicesolutionset/new'),
    {
      name: name,
      version: undefined,
      solutionProfileGuidId: solutionProfileGuidId,
      solutionEnvironmentGuidId: solutionEnvironmentGuidId,
      runEngineGuidId: runEngineGuidId,
      deviceSolutionGuidId: deviceSolutionGuidId,
      createEnrollmentProfile: createEnrollmentProfile,
      enrollmentProfileSiteGuidId: enrollmentProfileSiteGuidId,
      enrollmentProfileKey: enrollmentProfileKey,
      enrollmentProfilePassword: enrollmentProfilePassword,
    });
  }

  updateSolutionSet(
    deviceSolutionSetGuidId: string,
  ): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'devicesolutionset/update'),
    {
      deviceSolutionSetGuidId: deviceSolutionSetGuidId,
    });
  }

  productionSolutionSet(
    deviceSolutionSetGuidId: string,
  ): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'devicesolutionset/production'),
    {
      deviceSolutionSetGuidId: deviceSolutionSetGuidId,
    });
  }

  listDeviceSolutionSets(): Observable<DeviceSolutionSet[]> {
    return this.apiV2Service.post<any>(
      this.urlSuffixCenterV2Placeholder.replace('{what}', 'devicesolutionset/list'),
      { }
    ).pipe(
      map((response: any) => {
        const result = [];
        if (response && response.deviceSolutionSets) {
          for (const item of response.deviceSolutionSets) {
            result.push(new DeviceSolutionSet(item));
          }
        }
        return result;
      })
    );
  }

  getDeviceSolutionSet(deviceSolutionSetGuidId: string): Observable<DeviceSolutionSet> {
    return this.apiV2Service.post<any>(
      this.urlSuffixCenterV2Placeholder.replace('{what}', 'devicesolutionset/get'),
      {
        deviceSolutionSetGuidId: deviceSolutionSetGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response && response.deviceSolutionSet ? new DeviceSolutionSet(response.deviceSolutionSet) : null;
      })
    );
  }

  delete(solutionProfileGuidId: string): Observable<boolean> {
    return this.apiService.post<boolean>(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    });
  }

  requestKeys(solutionProfileGuidId: string): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'enrollment/requestkeys'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    });
  }

  listSolutionFeatures(solutionProfileGuidId: string): Observable<SolutionFeature[]> {
    return this.apiV2Service.post<SolutionFeature[]>(
      this.urlSuffixV2Placeholder.replace('{what}', 'solutionfeature/list'),
    {
      solutionProfileGuidId: solutionProfileGuidId,
    });
  }

  /**
   * Used for new / update / delete
   * @param solutionProfileGuidId
   * @param solutionFeatureGuidId
   * @param solutionFeature
   */
  updateSolutionFeature(solutionProfileGuidId: string, solutionFeatureGuidId: string, solutionFeature: SolutionFeature): Observable<SolutionFeature> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'solutionfeature/update'),
      {
        solutionProfileGuidId,
        solutionFeatureGuidId: solutionFeatureGuidId,
        solutionFeature: solutionFeature,
      }
    );
  }

  addCenterType(solutionProfileGuidId: string, centerTypeGuidId: string): Observable<any> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'centertype/add'),
      {
        solutionProfileGuidId: solutionProfileGuidId,
        centerTypeGuidId: centerTypeGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  installTemplateSolution(solutionProfileGuidId: string, templateSolutionGuidId: string): Observable<any> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'solutiontemplate/install'),
      {
        solutionProfileGuidId: solutionProfileGuidId,
        templateSolutionGuidId: templateSolutionGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
