export enum TypeTreeRelationEnum {
  // center
  SiteSite =  '2fd83c56-0254-467b-9e59-416763f4deb8',
  SiteGroup =  'db46748a-c08d-4b30-b5ee-f99ba6594ba0',
  SiteUser =  'd1e884dc-27ce-4313-80c1-6a87e3cd9309',
  SiteAdminUser =  '37015973-1e8a-494c-b06b-3edbb2518da0',
  SiteDevice =  '8b077525-a887-40cc-8a17-743cd677141b',
  SiteTank =  '1ae22f0b-6e0d-4602-bd86-53dfc0bbed74',
  SiteAddress =  'b41b5ec0-719b-47e8-97ba-764554f29c6b',
  SiteResourceFolder =  '5b73f2b5-7c09-4de8-b691-89603e186f67',

  UserAddress =  'bd279c8f-388a-4b2f-89f1-ca27430cbcb4',
  UserPermission =  '37dafd4f-1556-4ac4-b040-a6f07ce452d6',
  UserGroup =  'f3db173a-3d47-44aa-a50d-a8de6c4f6232', // ?

  TankOrderUser =  '2a833721-b29b-4c11-ac9a-5a13d26708e4',
  TankAlarmUser =  'fa456e9e-a4cd-40cc-a40b-bd3100c0ff99',
  TankAddress =  '7e0ac2e4-4df3-4876-a73d-59befd2142ef',
  TankProduct =  'c627596f-760f-481c-9e98-bec45c9151f6',
  TankOrder =  '025ad724-3b79-48b6-b402-0d2834249f06',
  TankTopFuelService =  'a43641d5-bed0-4a82-a22f-0a5976d5cacd',
  TankTankModel =  '10ac03a9-3930-48a4-b3f8-d5bbb5c1446d',

  TopFuelForecastTank = '446faf9f-601d-49ae-baba-22ad81406526',
  TopFuelTankForecastTopFuelForecast = '3f9f775e-9c4d-4104-b50b-1260d6310b5b',

  GroupPermission =  '1044293b-c6c5-4e4d-a592-a38acd2ddb18',

  SitePermission =  'e3b85e0c-33d8-4866-ab4b-d1808c498555',

  RootUser =  '634132fd-861b-4480-b6bc-ba85184e9a7d',
  RootGroup =  '094fbaec-f5b8-4828-b9bf-7780e15022f8',
  RootPermission =  'e3b85e0c-33d8-4866-ab4b-d1808c498555',
  RootGpsDevice =  '0a8e724b-c5d8-4f51-a00b-e7e4844a0c6c',
  RootProductGroup =  'cb9ede58-e519-45c8-894a-dc483580f4f0',
  RootTopFuelForecast = '88060d8a-dc04-4b1c-80a8-089fe11940e0',

  ProductGroupProduct =  '619d1f08-b33e-49e7-b38b-0f59af0f3858',

  TaskSite =  'ba43ae1b-3dac-4d6c-8ba3-8e1ca74baa3c',
  TaskTank =  '8e9e792a-d04f-4c31-a7e7-728c6106af40',
  TaskUser =  '9d30583b-3ce9-437b-96b7-7c398596df7f',

  // studio
  RootSolutionProfile =  'a314294d-1597-43ef-980b-0b5db4fc87d6',

  SolutionProfileSolutionSet =  'bf6a4e9a-dcb4-44a2-a193-3ccef8241eae',
  SolutionSetRunEngine = '29f949e9-b62a-4d13-ae65-222ee08681ae',
}
