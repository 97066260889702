import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { WebLoadMap, WebObject, WebResponse } from 'app/center-v2/core/models';
import { GenericService } from 'app/center-v2/shared/services';
import { BaseDialog, ConfirmDialog, InfoDialog } from 'app/shared/dialogs';
import { SessionService } from 'app/shared/services/app';
import { BrowserUtils, GuidUtils } from 'app/shared/utils';
import { MenuItem, TreeNode } from 'primeng/api';
import { Observable, of, zip } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { CenterType, DataResponse, DataSource, DesignTreeNode, DesignTreeResponse, DictString, SiteGuidIdAndName, TreeRelationSource, TreeSource, TypeEnum, TypeTreeRelationEnum } from '../../models';
import { AppService, DataService, DesignTreeService, LookupService, TreeContextMenuService } from '../../services';
import { StorageUtils, TreeUtils, TypeUtils } from '../../utils';
import { BaseComponentV2 } from '../base/base-v2.component';


@Component({
  selector: 'lc-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent extends BaseComponentV2 implements OnInit {

  readonly EMPTY_GUID = GuidUtils.emptyGuid();
  readonly TYPE_USER_GUID = TypeEnum.User;
  private readonly LOCAL_SELECTEDTREENODE_KEY = 'lc_selected_tree_node';

  @ViewChild(ConfirmDialog) confirmDialog: BaseDialog;
  @ViewChild(InfoDialog) infoDialog: BaseDialog;

  @Input() adminMode: boolean;
  @Input() set rootObjectGuidId(value: string) { // use one or the other
    this._rootObjectGuidId = value;
    this.treeNodes = [];
    this.searchMode = false;

    if (this._rootObjectGuidId) {
      setTimeout(() => {
        if (this._rootObjectGuidId === this.session.userDesignTreeGuidId) {
          this.showTypesInTree = false;
          this.fetchDataAndOrDesignTree(this.session.siteGuidId);
        } else {
          this.showTypesInTree = true;
          this.fetchDataAndOrDesignTree(this._rootObjectGuidId);
        }
      });
    }
  }
  get rootObjectGuidId(): string { return this._rootObjectGuidId; }
  private _rootObjectGuidId: string;

  @Input() set typeGuidIds(value: string[]) { // use one or the other
    this._typeGuidIds = value;
    this.treeNodes = [];

    if (this._typeGuidIds) {
      setTimeout(() => {
        this.showTypesInTree = false;
        this.fetchDataForSpecificTypeGuidId(this._typeGuidIds);
      });
    }
  }
  get typeGuidIds(): string[] { return this._typeGuidIds; }
  private _typeGuidIds: string[];

  @Input() set displayInTreeTypeGuidIds(value: string[]) {
    this._displayInTreeTypeGuidIds = value;
    if (this._displayInTreeTypeGuidIds) {
      this.fetchDisplayInTreesForExpandedSites();
    }
    this.refreshTree();
  }
  get displayInTreeTypeGuidIds(): string[] { return this._displayInTreeTypeGuidIds; }
  private _displayInTreeTypeGuidIds: string[];

  @Input() set selectedTreeNode(value: TreeNode) {
    if (
      (!this._selectedTreeNode) ||
      (this._selectedTreeNode && !value) ||
      (this._selectedTreeNode.data.guidId !== value.data.guidId)
    ) {
      this._selectedTreeNode = value;
      if (this._selectedTreeNode && !this.isNavigatingToNode) {
        this.loadStoredNodePathAndNavigateToItIfExists();
      }
    }
  }
  get selectedTreeNode(): TreeNode {
    return this._selectedTreeNode;
  }
  private _selectedTreeNode: TreeNode;

  @Input() updateBreadcrumb: boolean;
  @Input() selectSiteDialog: BaseDialog;
  @Input() addToWorkspaceDialog: BaseDialog;

  @Output() selectedTreeNodeChange: EventEmitter<TreeNode>;
  @Output() deleteTank: EventEmitter<string>;
  @Output() selectedTreeNodePathChange: EventEmitter<SiteGuidIdAndName[]>;

  treeNodes: TreeNode[];
  treeWidth: number;

  longLivedBusy: boolean;

  contextMenuItems: MenuItem[];
  searchMode: boolean;
  isNavigatingToNode: boolean;
  environmentTypeGuidIds: DictString<string[]>;

  private alreadyFetchedTypeObjectsForTheseSitesMap: { [key: string]: string[] };

  showTypesInTree: boolean;

  cancelAnyActiveNavigation: boolean;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private dataService: DataService,
    private designTreeService: DesignTreeService,
    private elementRef: ElementRef,
    private genericService: GenericService,
    private lookupService: LookupService,
    sessionService: SessionService,
    private treeContextMenuService: TreeContextMenuService,
  ) {
    super(cdr, sessionService);

    this.selectedTreeNodeChange = new EventEmitter();
    this.deleteTank = new EventEmitter();
    this.selectedTreeNodePathChange = new EventEmitter();

    this.rootObjectGuidId = undefined;
    this.treeNodes = [];
  }

  ngOnInit() {
    if (this.adminMode) {
      this.subscriptions.push(
        this.treeContextMenuService.fullRefresh$
        .subscribe((treeNode: TreeNode) => {
          this.nodeSelect(treeNode, true);

          setTimeout(() => {
            this.rootObjectGuidId = this.rootObjectGuidId;
            this.cdr.markForCheck();
          }, 100);
        }),
        this.treeContextMenuService.nodeSelect$
        .subscribe((treeNode: TreeNode) => {
          this.nodeSelect(treeNode, true);
        }),
        this.treeContextMenuService.refresh$
        .subscribe((treeNode: TreeNode) => {
          this.refreshTree(treeNode);
        }),
        this.treeContextMenuService.deleteTank$
        .subscribe((tankGuidId: string) => {
          this.deleteTank.emit(tankGuidId);
        })
      );
    }
  }

  nodeExpand(treeNode: TreeNode) {
    treeNode.expanded = true;
    this.nodeSelect(
      treeNode,
      !this.isNavigatingToNode || !treeNode.children || !treeNode.children.length
    );

    if (!this.adminMode && !treeNode.children) {
      treeNode.leaf = true;
    }

    this.updateTreeWidth();
  }

  nodeSelect(treeNode: TreeNode, fetchData: boolean) {
    if (treeNode && (this.isNavigatingToNode || treeNode !== this.selectedTreeNode)) {
      this._selectedTreeNode = treeNode;
      this.cdr.markForCheck();

      if (fetchData && treeNode.data.guidId) {
        Sentry.configureScope((scope) => {
          scope.setExtra('treeNodeGuidId', treeNode.data.guidId);
          scope.setExtra('treeNodeTypeGuidId', treeNode.data.typeGuidId);
        });
        console.log(this.selectedTreeNode.data);

        if (this.updateBreadcrumb) {
          this.buildStoreAndEmitNodePath();
        }
        if (this.adminMode || this.displayInTreeTypeGuidIds) {
          this.fetchDataAndOrDesignTree(treeNode.data.guidId, treeNode);
        } else {
          this.selectedTreeNodeChange.emit(treeNode);
        }
      } else {
        console.log('Navigating by: ' + this.selectedTreeNode.label);
        if (!this.isNavigatingToNode) {
          this.selectedTreeNodeChange.emit(treeNode);
        }
      }

      if (this.adminMode) {
        this.contextMenuItems = this.treeContextMenuService.buildMenuItems(
          treeNode,
          this.treeNodes,
          this.rootObjectGuidId,
          this.permissions,
          this.confirmDialog,
          this.infoDialog,
          this.selectSiteDialog,
          this.addToWorkspaceDialog,
        );
      }
    }
  }

  private buildStoreAndEmitNodePath() {
    let path: SiteGuidIdAndName[] = [];
    let currentNode = this.selectedTreeNode;
    while (currentNode) {
      if (currentNode.data.typeGuidId === TypeEnum.Site) {
        path.splice(0, 0, {
          guidId: currentNode.data.guidId,
          name: currentNode.label
        });
      }

      currentNode = currentNode.parent;
      if (currentNode) {
        currentNode.expanded = true;
      }
    }

    StorageUtils.setItem('local', this.LOCAL_SELECTEDTREENODE_KEY, path);

    this.selectedTreeNodePathChange.emit(path);
  }

  private loadStoredNodePathAndNavigateToItIfExists() {
    const path = JSON.parse(StorageUtils.getItem('local', this.LOCAL_SELECTEDTREENODE_KEY) || '[]');
    if (
      path.length > 1 &&
      this.treeNodes && this.treeNodes.length &&
      path[0].guidId === this.treeNodes[0].data.guidId
    ) {
      this.navigateToPath(path);
    } else {
      setTimeout(() => {
        this.buildStoreAndEmitNodePath();
        this.selectedTreeNodeChange.emit(this.treeNodes[0]);
      });
    }
  }

  private fetchDataAndOrDesignTree(objectGuidId: string, treeNode?: TreeNode) {
    if (objectGuidId) {
      this.emitBusy(true);

      const typeGuidIds = [];
      this.environmentTypeGuidIds = {};
      if (this.displayInTreeTypeGuidIds) {
        const structuralPermission = this.getPermission('Structural');
        if (structuralPermission) {
          for (const permission of structuralPermission.permissions || []) {
            if (
              permission.valueGuidId !== TypeEnum.Site &&
              this.hasReadAccess('Structural|' + permission.valueGuidId)
            ) {
              this.checkAndAddTypeGuidIdIfRequired(permission.valueGuidId, true, typeGuidIds, this.environmentTypeGuidIds);
            }
          }
        }
      }

      let requests: Observable<any>[] = [];
      if (!this.showTypesInTree && (!treeNode || treeNode.data.typeGuidId === TypeEnum.Site)) {
        // mixing old designTree with newer v2 generic list call...
        // since this is a big change and bypasses permissions and environmentType checks, I'm doing this just for tanks, to be used on the planner
        let environmentTypeGuidIds = JSON.parse(JSON.stringify(this.environmentTypeGuidIds)) as DictString<string[]>;
        let tankTypeGuidIdIndex = -1;
        if (BrowserUtils.getQueryParams().workspaceGuidId && BrowserUtils.getQueryParams().widgetGuidId) {
          tankTypeGuidIdIndex = typeGuidIds.findIndex((typeGuidId: string) => {
            return typeGuidId === TypeEnum.Tank;
          });
          if (tankTypeGuidIdIndex >= 0) {
            typeGuidIds.splice(tankTypeGuidIdIndex, 1);
          }

          for (const envTypeGuidIds of Object.values(environmentTypeGuidIds)) {
            tankTypeGuidIdIndex = envTypeGuidIds.findIndex((typeGuidId: string) => {
              return typeGuidId === TypeEnum.Tank;
            });
            if (tankTypeGuidIdIndex >= 0) {
              envTypeGuidIds.splice(tankTypeGuidIdIndex, 1);
            }
          }
        }

        requests.push(
          this.designTreeService.get(
            this.session.userDesignTreeGuidId,
            objectGuidId !== this.session.siteGuidId ? objectGuidId : undefined,
            this.adminMode || this.displayInTreeTypeGuidIds ? 2 : 0,
            this.adminMode || this.displayInTreeTypeGuidIds ? typeGuidIds : [],
            this.adminMode || this.displayInTreeTypeGuidIds ? environmentTypeGuidIds : null,
          ).pipe(
            mergeMap((dtr: DesignTreeResponse) => {
              dtr.nodes = dtr.nodes || [];
              if (tankTypeGuidIdIndex >= 0) {
                return this.genericService.list(TypeEnum.Tank, null, null, false, false, objectGuidId, 2)
                .pipe(
                  map((response: WebResponse) => {
                    for (const webObject of response.getObject() as WebObject[] || []) {
                      dtr.nodes.push({
                        parentSiteGuidId: webObject.siteGuidId,
                        guidId: webObject.guidId,
                        typeGuidId: webObject.typeGuidId,
                        iconUri: 'fas fa-battery-half',
                        name: webObject.members.name || webObject.members.tankId,
                      });
                    }
                    return dtr;
                  })
                );
              } else {
                return of(dtr);
              }
            })
          )
        );
      } else {
        requests.push(of(null).pipe(delay(10)));
      }

      if (this.adminMode) {
        requests.push(
          this.dataService.getByGuids(
            [
              objectGuidId
            ],
            true,
            true,
            true,
            false,
            true,
            true,
            false,
            !this.showTypesInTree,
            this.showTypesInTree
          )
        );
      } else {
        requests.push(of(null));
      }

      zip(...requests)
      .subscribe((response: [DesignTreeResponse, DataResponse]) => {
        if (response[0] && response[0].sites) {
          this.treeNodes = this.updateTreeWithDesignTreeResponse(this.treeNodes, response[0]);
        }
        // populate the selected node data
        if (response[1] && response[1].webDataSources && response[1].webTreeSources) {
          this.treeNodes = this.updateTreeWithDataResponse(this.treeNodes, response[1], objectGuidId, this.showTypesInTree);
        }

        if (!this.isNavigatingToNode) {
          if (treeNode) {
            this.selectedTreeNodeChange.emit(treeNode);
          } else {
            this.loadStoredNodePathAndNavigateToItIfExists();
          }
        }

        this.refreshTree();
        this.emitBusy(false);
      }, (error: any) => {

        this.emitBusy(false);
      });
    }
  }

  private updateTreeWithDesignTreeResponse(treeNodes: TreeNode[], dtr: DesignTreeResponse): TreeNode[] {
    treeNodes = treeNodes || [];

    let nodes = [...(dtr.sites || []), ...(dtr.nodes || [])];
    if (nodes.length) {
      // start by finding the root node
      const rootNodeIndex = nodes.findIndex((node: DesignTreeNode) => {
        return node.parentSiteGuidId === GuidUtils.emptyGuid();
      });
      const rootNodeGuidId = nodes[rootNodeIndex].guidId;
      if (rootNodeIndex >= 0 && treeNodes.length === 0) {
        // if no treeNodes yet, add it to the root
        let tempTreeNode = this.makeTreeNodeFromDesignTreeNode(nodes[rootNodeIndex], rootNodeGuidId);
        treeNodes.push(tempTreeNode);
      }
      nodes.splice(rootNodeIndex, 1);

      let iterationNumber = 0;
      let initialNodesLength = nodes.length;
      while (nodes.length && iterationNumber < 1000) {
        let parentNodeFound = false;
        for (let i = 0; i < nodes.length; i++) {
          TreeUtils.traverseTree(this, treeNodes, null, (parentNode: TreeNode, currentNode: TreeNode) => {
            if (currentNode.data.guidId === nodes[i].parentSiteGuidId) {
              let existingNode = (currentNode.children || []).find((node: TreeNode) => {
                return node.data.guidId === nodes[i].guidId;
              });
              if (!existingNode) {
                let tempTreeNode = this.makeTreeNodeFromDesignTreeNode(nodes[i], rootNodeGuidId);
                currentNode.children.push(tempTreeNode);
              }
              parentNodeFound = true;
              TreeUtils.breakTraverse = true;
            }
          });

          if (parentNodeFound) {
            nodes.splice(i, 1);
            break;
          }
        }
        iterationNumber++;
      }

      if (iterationNumber >= 1000) {
        console.error('this shouldn\'t have happened - start nodes: ' +
          initialNodesLength + ', end nodes: ' + nodes.length);
      }
    }

    return treeNodes;
  }

  private makeTreeNodeFromDesignTreeNode(dtn: DesignTreeNode, rootNodeGuidId: string): TreeNode {
    // designtreenode of type SITE does not have typeGuidId set
    const defaultTreeGuidId = dtn.typeGuidId ? dtn.guidId : rootNodeGuidId;
    dtn.typeGuidId = dtn.typeGuidId || TypeEnum.Site;

    const lt = this.lookupService.getLogicType(dtn.typeGuidId) || {} as CenterType;
    const typeDetails = lt.name ? `(${lt.name})` : undefined;

    const node: TreeNode = {
      data: {
        guidId: dtn.guidId,
        typeGuidId: dtn.typeGuidId,
        defaultTreeGuidId: defaultTreeGuidId,
        typeDetails: typeDetails,
        adminUserGuidId: dtn.adminUserGuidId,
      },
      label: dtn.name || dtn.guidId,
      icon: dtn.iconUri,
      leaf: false,
      children: []
    };

    return node;
  }

  private updateTreeWithDataResponse(treeNodes: TreeNode[], dr: DataResponse, objectGuidId: string, useMainTreeSourceOnly?: boolean): TreeNode[] {
    let mainDataSource: DataSource = undefined;
    if (objectGuidId === GuidUtils.emptyGuid()) {
      mainDataSource = new DataSource(GuidUtils.emptyGuid(), GuidUtils.emptyGuid());
    } else {
      mainDataSource = (dr.webDataSources || [])
      .find((ds: DataSource) => {
        return ds.guidId === objectGuidId;
      });
    }

    if (mainDataSource) {
      const mainTreeSource = (dr.webTreeSources || [])
      .find((ts: TreeSource) => {
        if (mainDataSource.typeGuidId === TypeEnum.Site) {
          return ts.treeGuidId === this.session.siteGuidId;
        } else {
          return ts.treeGuidId === mainDataSource.defaultTreeGuidId;
        }
      });

      if (useMainTreeSourceOnly) {
        if (this.showTypesInTree) {
          // as to not ALWAYS iterate over the whole tree (treeNodes), here I'm passing the selectedTreeNode array...
          // this can potentially also work for the non typed view, but need further testing...
          this.generateTreeNodesWithTypesFromTreeSource(this.selectedTreeNode && this.selectedTreeNode.parent ? this.selectedTreeNode.parent.children : treeNodes, mainTreeSource);
        } else {
          this.generateTreeNodesFromTreeSource(treeNodes, mainTreeSource);
        }
      } else {
        for (const ts of dr.webTreeSources || []) {
          // add temp treeNodes using the treeRelationSources from all the treeSources
          if (ts.active) {
            if (this.showTypesInTree) {
              this.generateTreeNodesWithTypesFromTreeSource(this.selectedTreeNode && this.selectedTreeNode.parent ? this.selectedTreeNode.parent.children : treeNodes, ts);
            } else {
              this.generateTreeNodesFromTreeSource(treeNodes, ts);
            }
          }
        }
      }

      // collections
      if (mainDataSource.collections && this.selectedTreeNode) {
        for (const typeGuidId of Object.keys(mainDataSource.collections)) {
          for (const collectionTypeGuidId of Object.keys(mainDataSource.collections[typeGuidId])) {
            const alreadyExists = this.selectedTreeNode.children.some((tn: TreeNode) => {
              return tn.data.typeGuidId === collectionTypeGuidId;
            });
            if (!alreadyExists) {
              const collectionType = this.lookupService.getLogicType(typeGuidId).collections
              .find((c: any) => {
                return c.typeGuidId === collectionTypeGuidId;
              });

              const collectionTypeNode: TreeNode = {
                data: {
                  guidId: collectionType.typeCollectionGuidId,
                  typeGuidId: collectionType.typeGuidId,
                  defaultTreeGuidId: undefined,
                  typeDetails: '(Collection)',
                },
                label: collectionType.collectionName,
                icon: '',
                leaf: false,
                children: []
              };
              this.selectedTreeNode.children.push(collectionTypeNode);

              for (const objectGuidId of Object.keys(mainDataSource.collections[typeGuidId][collectionTypeGuidId])) {
                const collectionItemNode: TreeNode = {
                  data: {
                    guidId: objectGuidId,
                    typeGuidId: collectionType.typeGuidId,
                    defaultTreeGuidId: undefined,
                    typeDetails: undefined,
                  },
                  label: objectGuidId,
                  icon: '',
                  leaf: true
                };
                collectionTypeNode.children.push(collectionItemNode);

                // create "collection datasource"
                const itemDS = new DataSource(objectGuidId, collectionType.typeGuidId);
                itemDS.values[collectionType.typeGuidId] = mainDataSource.collections[typeGuidId][collectionTypeGuidId][objectGuidId];
                this.lookupService.setDataSource(itemDS);
              }
            }
          }
        }
      }
    }

    return treeNodes;
  }

  private generateTreeNodesWithTypesFromTreeSource(treeNodes: TreeNode[], treeSource: TreeSource): TreeNode[] {
    treeNodes = treeNodes || [];

    if (treeSource) {
      const missingTreeRelations: TreeRelationSource[] = [];
      for (const trs of treeSource.treeRelationSources || []) {
        let nodeFound = false;
        const treeNode = this.makeTreeNodeFromTreeRelationSource(trs, true);

        if (treeNode) {
          if (GuidUtils.isNullOrEmpty(trs.parentGuidId) || trs.parentGuidId === this.session.siteGuidId) {
            const existingTN = treeNodes.find((tn: TreeNode) => {
              return tn.data.guidId === treeNode.data.guidId
            });
            if (existingTN) {
              existingTN.data = treeNode.data;
              existingTN.label = treeNode.label;
              existingTN.children = treeNode.children;
            } else if (treeNodes.length === 0) {
              treeNodes.push(treeNode);
            }
          } else {
            TreeUtils.traverseTree(this, treeNodes, null, (parentNode: TreeNode, currentNode: TreeNode) => {
              // If parent node found
              if (currentNode.data.guidId === trs.parentGuidId) {
                // then find the correct subnode type to add the new node to
                const relationNode = (currentNode.children || []).find((tn: TreeNode) => {
                  return tn.data.typeTreeRelationGuidId === trs.typeTreeRelationGuidId;
                });
                if (relationNode) {
                  const existingNode = (relationNode.children || []).find((tn: TreeNode) => {
                    return tn.data.guidId === treeNode.data.guidId;
                  });
                  if (!existingNode) {
                    relationNode.children.push(treeNode);
                  } else {
                    existingNode.data = treeNode.data;
                    existingNode.label = treeNode.label;
                  }
                }/* else {
                  const existingNode = (currentNode.children || []).find((tn: TreeNode) => {
                    return tn.data.guidId === treeNode.data.guidId;
                  });
                  if (!existingNode) {
                    currentNode.children.push(treeNode);
                  } else {
                    existingNode.data = treeNode.data;
                    existingNode.label = treeNode.label;
                  }
                }*/

                nodeFound = true;
                TreeUtils.breakTraverse = true;
              }
            });

            if (!nodeFound) {
              missingTreeRelations.push(trs);
            }
          }
        }
      }

      // try again for treeRelations that didn't make the cut initially (were out of order)
      let index = 0;
      while (missingTreeRelations.length > 0 && missingTreeRelations.length > index) {
        const trs = missingTreeRelations[index];
        let nodeFound = false;
        const treeNode = this.makeTreeNodeFromTreeRelationSource(trs);

        if (treeNode) {
          TreeUtils.traverseTree(this, treeNodes, null, (parentNode: TreeNode, currentNode: TreeNode) => {
            if (currentNode.data.guidId === trs.parentGuidId) {
              // then find the correct subnode type to add the new node to
              const relationNode = (currentNode.children || []).find((tn: TreeNode) => {
                return tn.data.typeTreeRelationGuidId === trs.typeTreeRelationGuidId;
              });
              if (relationNode) {
                const existingNode = (relationNode.children || []).find((tn: TreeNode) => {
                  return tn.data.guidId === treeNode.data.guidId;
                });
                if (!existingNode) {
                  relationNode.children.push(treeNode);
                } else {
                  existingNode.data = treeNode.data;
                  existingNode.label = treeNode.label;
                }
              }

              missingTreeRelations.splice(index, 1);
              index = index > 0 ? index - 1 : 0;
              nodeFound = true;
              TreeUtils.breakTraverse = true;
            }
          });
        }

        if (!nodeFound) {
          index++;
        }
      }
    }

    return treeNodes;
  }

  private generateTreeNodesFromTreeSource(treeNodes: TreeNode[], treeSource: TreeSource): TreeNode[] {
    treeNodes = treeNodes || [];

    if (treeSource) {
      const missingTreeRelations: TreeRelationSource[] = [];
      for (const trs of treeSource.treeRelationSources || []) {
        let nodeFound = false;
        if (trs.active) {
          const treeNode = this.makeTreeNodeFromTreeRelationSource(trs);
          if (treeNode) {
            if (GuidUtils.isNullOrEmpty(trs.parentGuidId) && trs.typeTreeRelationGuidId !== TypeTreeRelationEnum.RootProductGroup) {
              const existingTN = treeNodes.find((tn: TreeNode) => {
                return tn.data.guidId === treeNode.data.guidId
              });
              if (existingTN) {
                existingTN.data = treeNode.data;
                existingTN.label = treeNode.label;
              } else if (treeNodes.length === 0) {
                treeNodes.push(treeNode);
              }
            } else {
              TreeUtils.traverseTree(this, treeNodes, null, (parentNode: TreeNode, currentNode: TreeNode) => {
                if (currentNode.data.guidId === trs.parentGuidId) {
                  const existingNode = (currentNode.children || []).find((tn: TreeNode) => {
                    return tn.data.guidId === trs.childGuidId;
                  });
                  if (!existingNode) {
                    currentNode.children.push(treeNode);
                  }
                  nodeFound = true;
                  TreeUtils.breakTraverse = true;
                }
              });

              if (!nodeFound) {
                missingTreeRelations.push(trs);
              }
            }
          }
        }
      }

      // try again for treeRelations that didn't make the cut initially (were out of order)
      let index = 0;
      while (missingTreeRelations.length > 0 && missingTreeRelations.length > index) {
        const trs = missingTreeRelations[index];
        let nodeFound = false;
        const treeNode = this.makeTreeNodeFromTreeRelationSource(trs);

        if (treeNode) {
          TreeUtils.traverseTree(this, treeNodes, null, (parentNode: TreeNode, currentNode: TreeNode) => {
            if (currentNode.data.guidId === trs.parentGuidId) {
              const existingNode = (currentNode.children || []).find((tn: TreeNode) => {
                return tn.data.guidId === trs.childGuidId;
              });
              if (!existingNode) {
                currentNode.children.push(treeNode);
              }
              missingTreeRelations.splice(index, 1);
              index = index > 0 ? index - 1 : 0;
              nodeFound = true;
              TreeUtils.breakTraverse = true;
            }
          });
        }

        if (!nodeFound) {
          index++;
        }
      }
    }

    return treeNodes;
  }

  private makeTreeNodeFromTreeRelationSource(trs: TreeRelationSource, includeTypeNodes?: boolean): TreeNode {
    const ds = this.lookupService.getDataSource(trs.childGuidId);
    let label = trs.childGuidId;

    if (ds) {
      const typeGuidId = ds.typeGuidId;
      let lt = this.lookupService.getLogicType(typeGuidId) || {} as CenterType;
      const typeDetails = lt.name ? `(${lt.name})` : undefined;
      const defaultTreeGuidId = ds.defaultTreeGuidId === GuidUtils.emptyGuid() ? ds.guidId : ds.defaultTreeGuidId;
      const subCastTypeGuidIds = ds.subCastTypeGuidIds || [];

      const flatValues = ds.getValuesFlattened();
      if (flatValues?.tankid) {
        label = flatValues.tankid;
      } else if (flatValues?.name) {
        label = flatValues.name;
      } else {
        label = ds.guidId;
      }

      const node: TreeNode = {
        data: {
          guidId: trs.childGuidId,
          typeGuidId: typeGuidId,
          subCastTypeGuidIds: subCastTypeGuidIds,
          defaultTreeGuidId: defaultTreeGuidId,
          typeDetails: typeDetails,
        },
        label: label,
        icon: TypeUtils.getIcon(typeGuidId),
        leaf: false,
        children: []
      };

      if (includeTypeNodes) {
        this.addRelationTypeNodes(node);
      }

      return node;
    } else {
      return null;
    }
  }

  private addRelationTypeNodes(node: TreeNode) {
    const typeGuidIds = [node.data.typeGuidId];
    typeGuidIds.push(...node.data.subCastTypeGuidIds);
    const logicTypes = [];
    for (const typeGuidId of typeGuidIds) {
      const lt = this.lookupService.getLogicType(typeGuidId);
      if (lt) {
        logicTypes.push(lt);
      }
    }

    for (const lt of logicTypes) {
      for (const relation of lt.relations || []) {
        if (!relation.isRootRelation && relation.parentTypeGuidId === lt.typeGuidId) {
          let typeDetails = relation.collectionRelation ? '(Relation) (List)' : '(Relation)';

          node.children.push({
            data: {
              typeGuidId: relation.typeGuidId,
              typeTreeRelationGuidId: relation.typeTreeRelationGuidId,
              typeDetails: typeDetails,
            },
            label: relation.name,
            leaf: false,
            children: []
          });
        }
      }
    }
  }

  private fetchDisplayInTreesForExpandedSites() {
    this.alreadyFetchedTypeObjectsForTheseSitesMap = this.alreadyFetchedTypeObjectsForTheseSitesMap || {};
    const structuralPermission = this.getPermission('Structural');
    if (structuralPermission) {
      for (const permission of structuralPermission.permissions || []) {
        if (
          permission.valueGuidId !== TypeEnum.Site &&
          this.hasReadAccess('Structural|' + permission.valueGuidId)
        ) {
          this.alreadyFetchedTypeObjectsForTheseSitesMap[permission.valueGuidId] = this.alreadyFetchedTypeObjectsForTheseSitesMap[permission.valueGuidId] || [];
        }
      }
    }

    let expandedSiteNotFetchedGuidIds = (TreeUtils.getExpandedSiteGuidIds(this.treeNodes) || [])
    .filter((siteGuidId: string) => {
      let result = false;
      if (structuralPermission) {
        for (const permission of structuralPermission.permissions || []) {
          if (
            permission.valueGuidId !== TypeEnum.Site &&
            this.hasReadAccess('Structural|' + permission.valueGuidId)
          ) {
            result = result || (this.displayInTreeTypeGuidIds.indexOf(permission.valueGuidId) >= 0 && this.alreadyFetchedTypeObjectsForTheseSitesMap[permission.valueGuidId].indexOf(siteGuidId) < 0);
          }
        }
      }
      return result;
    });

    if (expandedSiteNotFetchedGuidIds.length) {
      this.emitBusy(true);

      for (const siteGuidId of expandedSiteNotFetchedGuidIds) {
        const typeGuidIds = [];
        this.environmentTypeGuidIds = {};
        const structuralPermission = this.getPermission('Structural');
        if (structuralPermission) {
          for (const permission of structuralPermission.permissions || []) {
            if (
              permission.valueGuidId !== TypeEnum.Site &&
              this.hasReadAccess('Structural|' + permission.valueGuidId)
            ) {
              this.checkAndAddTypeGuidIdIfRequired(permission.valueGuidId, this.alreadyFetchedTypeObjectsForTheseSitesMap[permission.valueGuidId].indexOf(siteGuidId) < 0, typeGuidIds, this.environmentTypeGuidIds);
            }
          }
        }

        this.designTreeService.get(
          this.session.userDesignTreeGuidId,
          siteGuidId !== this.session.siteGuidId ? siteGuidId : undefined,
          2,
          typeGuidIds,
          this.environmentTypeGuidIds,
        ).subscribe((dtr: DesignTreeResponse) => {
          if (structuralPermission) {
            for (const permission of structuralPermission.permissions || []) {
              if (
                permission.valueGuidId !== TypeEnum.Site &&
                this.hasReadAccess('Structural|' + permission.valueGuidId)
              ) {
                if (this.displayInTreeTypeGuidIds.indexOf(TypeEnum.GpsDevice) >= 0) {
                  this.alreadyFetchedTypeObjectsForTheseSitesMap[permission.valueGuidId].push(siteGuidId);
                }
              }
            }
          }

          if (dtr) {
            this.treeNodes = this.updateTreeWithDesignTreeResponse(this.treeNodes, dtr);
            this.refreshTree();
          }
          this.emitBusy(false);
        },
        (error: any) => {

          this.emitBusy(false);
        });
      };
    } else {
      this.emitBusy(false);
    }
  }

  private checkAndAddTypeGuidIdIfRequired(typeGuidId: string, notYetFetchedForSite: boolean, typeGuidIds: string[], environmentTypeGuidIds: DictString<string[]>) {
    if (this.displayInTreeTypeGuidIds.indexOf(typeGuidId) >= 0 && notYetFetchedForSite) {
      const permissionEnvironmentGuidId = this.getPermissionEnvironmentGuidId('Structural|' + typeGuidId);
      if (permissionEnvironmentGuidId) {
        environmentTypeGuidIds[permissionEnvironmentGuidId] = environmentTypeGuidIds[permissionEnvironmentGuidId] || [];
        environmentTypeGuidIds[permissionEnvironmentGuidId].push(typeGuidId);
      } else {
        typeGuidIds.push(typeGuidId);
      }
    }
  }

  private emitBusy(busy: boolean, longLivedBusy?: boolean) {
    if (longLivedBusy !== undefined) {
      this.longLivedBusy = longLivedBusy;
    }
    this.appService.setBusy(this.longLivedBusy || busy);
  }

  toggleSearch() {
    this.searchMode = !this.searchMode;

    this.cdr.markForCheck();
  }

  navigateToPath(path: SiteGuidIdAndName[]) {
    if (path && path.length) {
      if (this.treeNodes && this.treeNodes.length) {
        console.log('Starting navigation to:', path);
        this.cancelAnyActiveNavigation = this.isNavigatingToNode;
        this.searchMode = false;
        this.isNavigatingToNode = true;
        this.cdr.markForCheck();

        this.emitBusy(true, true);
        this.nodeSelect(this.treeNodes[0], false);
        this.treeNodes[0].expanded = true;

        this.recursiveNavigateUsingSiteArray(path, path[path.length - 1].guidId, 0);
      } else { // if tree is not ready yet, try again in a bit...
        setTimeout(() => {
          this.navigateToPath(path);
        }, 100);
      }
    }
  }

  private recursiveNavigateUsingSiteArray(sites: SiteGuidIdAndName[], treeGuidId: string, retryAttempt: number) {
    setTimeout(() => {
      if (this.cancelAnyActiveNavigation) {
        this.cancelAnyActiveNavigation = false;
        return;
      } else if (
        (!this.selectedTreeNode.children || !this.selectedTreeNode.children.length) &&
        retryAttempt < 50
      ) {
        this.recursiveNavigateUsingSiteArray(sites, treeGuidId, ++retryAttempt);
      } else if (sites.length > 1) {
        retryAttempt = 0;
        const site: SiteGuidIdAndName = sites.splice(0, 1)[0];
        const existingTreeNodeInLevel = (this.selectedTreeNode.children || []).find((treeNode: TreeNode) => {
          return treeNode.data.guidId === site.guidId;
        });
        if (existingTreeNodeInLevel) {
          this.nodeExpand(existingTreeNodeInLevel);

          this.recursiveNavigateUsingSiteArray(sites, treeGuidId, 0);
        } else {
          this.recursiveNavigateUsingSiteArray(sites, treeGuidId, 0);
        }
      } else {
        let existingTreeNodeInLevel = undefined;
        if (this.selectedTreeNode.data.guidId === treeGuidId) {
          existingTreeNodeInLevel = this.selectedTreeNode;
        } else {
          existingTreeNodeInLevel = (this.selectedTreeNode.children || []).find((treeNode: TreeNode) => {
            return treeNode.data.guidId === treeGuidId;
          });
        }

        if (existingTreeNodeInLevel) {
          this.nodeSelect(existingTreeNodeInLevel, true);
        }

        this.isNavigatingToNode = false;
        this.emitBusy(false, false);
      }
    }, 200);
  }

  refreshTree(selectNode?: TreeNode) {
    if (this.treeNodes) {
      TreeUtils.traverseTree(this, this.treeNodes, { sort: true }, (parentNode: TreeNode, currentNode: TreeNode) => {
        // filter tree to only show desired subNode types
        if (this.adminMode || this.displayInTreeTypeGuidIds) {
          currentNode.styleClass =
            currentNode.data.typeGuidId === TypeEnum.Site ||
            (!this.displayInTreeTypeGuidIds ||
              (this.displayInTreeTypeGuidIds.indexOf(currentNode.data.typeGuidId) >= 0 && parentNode.data.typeGuidId === TypeEnum.Site))
            ? undefined : 'hidden';
        } else {
          currentNode.styleClass =
            currentNode.data.typeGuidId === TypeEnum.Site
            ? undefined : 'hidden';
        }

        if (!parentNode) { // expand root node
          currentNode.expanded = true;
        }
      });

      if (!selectNode) {
        this.nodeSelect(this.selectedTreeNode || this.treeNodes[0], false);
      } else {
        this.nodeSelect(selectNode, true);
      }
    }

    this.updateTreeWidth();

    this.cdr.markForCheck();
  }

  private updateTreeWidth() {
    if (this.elementRef.nativeElement.parentElement) {
      this.treeWidth = this.elementRef.nativeElement.parentElement.clientWidth;
    }
  }

  private fetchDataForSpecificTypeGuidId(typeGuidIds: string[]) {
    this.emitBusy(true);
    // TODO: What's the difference between this call and the one in the studio landing page for all solutionProfiles?
    this.dataService.getTypesInProfile(
      typeGuidIds,
      this.session.siteGuidId,
      0,
      true,
      true,
      true,
      false,
      true,
      false,
    ).subscribe(
      (dr: DataResponse) => {
        this.treeNodes = [];

        // Start by adding a root node
        const rootNode: TreeNode = {
          type: GuidUtils.emptyGuid(),
          label: 'Root',
          data: {
            guidId: GuidUtils.emptyGuid(),
            typeGuidId: GuidUtils.emptyGuid(),
          },
          leaf: false,
          expanded: true,
          children: []
        };
        this.treeNodes.push(rootNode);

        if (dr && dr.webDataSources && dr.webTreeSources) {
          // mark every treeSource as a child of the above root node
          for (const trs of dr.webTreeSources || []) {
            trs.treeRelationSources[0].parentGuidId = rootNode.data.guidId;
          }

          // build the UI tree
          this.treeNodes = this.updateTreeWithDataResponse(this.treeNodes, dr, rootNode.data.guidId);

          this.refreshTree();
          this.emitBusy(false);
        }
      },
      (error: any) => {
        this.emitBusy(false);

      }
    );
  }

  resetTree() {
    this.treeNodes = [];
    this.fetchDataAndOrDesignTree(this.session.siteGuidId);
  }

}
