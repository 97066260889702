<div class="tree">
  <p-tree *ngIf="!searchMode"
    selectionMode="single"
    [value]="treeNodes"
    [contextMenu]="contextMenu"
    [selection]="selectedTreeNode"
    (onNodeExpand)="nodeExpand($event.node)"
    (onNodeSelect)="nodeSelect($event.node, true)"
    (onNodeContextMenuSelect)="nodeSelect($event.node, true)">
    <ng-template let-node pTemplate="default">
      <span [ngClass]="showTypesInTree && node.data.guidId ? 'bold' : ''">
        {{ node.label }}
      </span>

      <span *ngIf="showTypesInTree && node.data.typeDetails"
        [ngClass]="node.data.guidId ? '' : 'gray'">
        {{ node.data.typeDetails }}
      </span>

      <span *ngIf="node.data.typeGuidId === TYPE_USER_GUID && node.data.guidId && node.parent?.data.adminUserGuidId === node.data.guidId">
        *
      </span>
    </ng-template>
  </p-tree>

  <search-tree *ngIf="searchMode"
    [designTreeGuidId]="rootObjectGuidId"
    [environmentTypeGuidIds]="environmentTypeGuidIds"
    [typeGuidIds]="displayInTreeTypeGuidIds"
    [width]="treeWidth"
    (itemSelected)="navigateToPath($event)">
  </search-tree>

  <p-contextMenu #contextMenu
    [model]="contextMenuItems"
    [hidden]="!contextMenuItems"
    class="contextMenu">
  </p-contextMenu>
</div>

<div *ngIf="adminMode">
  <lc-confirm-dialog></lc-confirm-dialog>
  <lc-info-dialog></lc-info-dialog>
</div>
