export const environment = {
  name: 'zoey',
  production: true,

  apiStatusUrl: 'https://wapi.logiccenter.com:1810/', // StatusWebAPI Production
  apiCenterUrl: 'https://webapiv2.logiccenter.com/', // CenterWebAPI Production
  apiCenterV2Url: 'https://centerapiv2.logiccenter.com/', // CenterWebAPI V2 Production
  apiReportUrl: 'https://wapi.logiccenter.com:1814/', // ReportWebAPI Production
  apiStudioUrl: 'https://wapi.logiccenter.com:1812/', // StudioWebAPI Production

  agGridLicenseKey: 'CompanyName=MobileLogic Sweden AB,LicensedApplication=LogicCenter,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=3,AssetReference=AG-007512,ExpiryDate=25_March_2021_[v2]_MTYxNjYzMDQwMDAwMA==d99194cc596e8fc70c946b3a473ca68f',
  goJsLicenseKey: '73f143e4b70537c702d90776423d6af919a57a63c8841ea35d0414f6e9083c1d729dee7105d6dec287f848fb1d7fc1cbcdc36c7ac24c0c68b565d7de47e2d3a9b73276bb130c1388f4032fc09ff87ef2f87c75e0d2b322f2dc78dea2eabad39f5debf2cb4ad50eba',
  sentryDNS: 'https://40ac81a42da643bfa57c14cad126f682@sentry.io/1323950',
};
