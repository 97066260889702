import { TreeNode } from 'primeng/api';
import { TypeEnum } from '../models/type.enum';


export class TreeUtils {
  static breakTraverse = false;

  static traverseTree(self: any, obj: any, options: any, func: Function, parent?: any) {
    options = options || {};

    if (Array.isArray(obj)) {
      if (options.sort) obj.sort(this.compareFn);

      obj.forEach((child) => {
        TreeUtils.traverseTree(self, child, options, func);
      });
    } else if (TreeUtils.isObject(obj) && !Array.isArray(obj) && obj.data) { // if this is a treeNode
      // obj.children = obj.children || [];
      func.apply(self, [parent, obj]);
      if (TreeUtils.breakTraverse) {
        TreeUtils.breakTraverse = false;
        return;
      }

      if (options.sort && obj.children) obj.children.sort(TreeUtils.compareFn);

      if (obj.children) {
        for (let i = obj.children.length - 1; i >= 0; i--) {
          if (!options.expandedOnly || obj.expanded) {
            TreeUtils.traverseTree(self, obj.children[i], options, func, obj);
          }
        }
      }

      obj.leaf = (!obj.children || obj.children.length === 0) ||
                 (obj.children && !obj.children.some((child: TreeNode) => {
                   return child.styleClass !== 'hidden';
                 }));
    }
  }

  static getExpandedSiteGuidIds(treeItems: TreeNode[]): string[] {
    let result: string[] = [];
    TreeUtils.traverseTree(this, treeItems, { expandedOnly: true }, (parentNode: TreeNode, currentNode: TreeNode) => {
      if (currentNode.data.typeGuidId === TypeEnum.Site && currentNode.expanded) {
        result.push(currentNode.data.guidId);
      }
    });
    return result;
  }

  static getAddressTreeNode(treeNode: TreeNode): TreeNode {
    return (treeNode?.children || []).find((node: TreeNode) => {
      return node.data.typeGuidId === TypeEnum.Address;
    });
  }

  static hasAdminUser(treeNode: TreeNode): boolean {
    const siteAddress = TreeUtils.getAddressTreeNode(treeNode);
    if (siteAddress) {
      for (const node of treeNode.children) {
        if (node.data.typeGuidId === TypeEnum.User) {
          const userAddress = TreeUtils.getAddressTreeNode(node);
          if (userAddress &&
            userAddress.data.guidId === siteAddress.data.guidId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static findTreeNode(treeNodes: TreeNode[], guidId: string) {
    let result: TreeNode;

    TreeUtils.traverseTree(this, treeNodes, { sort: true }, (parentNode: TreeNode, currentNode: TreeNode) => {
      if (currentNode.data.guidId === guidId) {
        result = currentNode;
        TreeUtils.breakTraverse = true;
      }
    });

    return result;
  }

  static findOrAddToTree(treeNodes: TreeNode[], node: TreeNode) {
    const nodeInSourceTree = this.findTreeNode(treeNodes, node.data.guidId);
    if (nodeInSourceTree) {
      node = nodeInSourceTree;
    } else {
      // if node doesn't exist in tree, find a parent node that does
      let currentNode = node;
      let parentNode = currentNode.parent;
      while (parentNode) {
        let parentNodeInSourceTree = this.findTreeNode(treeNodes, parentNode.data.guidId);
        if (parentNodeInSourceTree) {
          if (!parentNodeInSourceTree.parent && parentNode.parent) {
            // this shouldn't be needed but in some cases the parent is null
            parentNodeInSourceTree.parent = this.findTreeNode(treeNodes, parentNode.parent.data.guidId);
          }
          parentNodeInSourceTree.children.push(currentNode);
          currentNode.parent = parentNodeInSourceTree;
          break;
        } else {
          currentNode = parentNode;
          parentNode = currentNode.parent;
        }
      }
    }

    return node;
  }

  static expandParentSites(treeNode: TreeNode) {
    let currentNode = treeNode;
    while (currentNode) {
      currentNode.expanded = true;
      currentNode = currentNode.parent;
    }
  }

  static cloneTree(treeNodes: TreeNode[]): TreeNode[] {
    const resultTree = [];
    TreeUtils.traverseTree(this, treeNodes, null, (pn1: TreeNode, cn1: TreeNode) => {
      if (!pn1) { // root element
        resultTree.push(TreeUtils.cloneTreeNode(cn1, false));
      } else {
        TreeUtils.traverseTree(this, resultTree, null, (pn2: TreeNode, cn2: TreeNode) => {
          if (cn2.data.guidId === pn1.data.guidId) {
            cn2.children = cn2.children || [];
            cn2.children.push(TreeUtils.cloneTreeNode(cn1, false));
            TreeUtils.breakTraverse = true;
          }
        });
      }
    });
    return resultTree;
  }

  static cloneTreeNode(treeNode: TreeNode, cloneChildren = true): TreeNode {
    let clonedChildren = <TreeNode[]>[];
    if (cloneChildren) {
      (treeNode.children || []).forEach((child: TreeNode) => {
        clonedChildren.push(this.cloneTreeNode(child, false));
      });
    }
    return {
      label: treeNode.label,
      data: Object.assign({}, treeNode.data),
      icon: treeNode.icon,
      children: clonedChildren,
      leaf: treeNode.leaf,
      expanded: treeNode.expanded,
      type: treeNode.type,
      parent: undefined, // don't clone parent (circular dependency)
      partialSelected: treeNode.partialSelected,
      styleClass: treeNode.styleClass,
      draggable: treeNode.draggable,
      droppable: treeNode.droppable,
      selectable: treeNode.selectable,
    };
  }


  private static compareFn(tn1: TreeNode, tn2: TreeNode) {
    // order by the Types first: (Site, Group, Users, SensorDevices, GpsDevices, Tanks)
    let t1 = tn1.data.typeGuidId === TypeEnum.Site ? 'a' :
      tn1.data.typeGuidId === TypeEnum.Group ? 'b' :
        tn1.data.typeGuidId === TypeEnum.User ? 'c' :
          tn1.data.typeGuidId === TypeEnum.Tank ? 'd' :
            tn1.data.typeGuidId === TypeEnum.GpsDevice ? 'e' : 'z';
    let t2 = tn2.data.typeGuidId === TypeEnum.Site ? 'a' :
      tn2.data.typeGuidId === TypeEnum.Group ? 'b' :
        tn2.data.typeGuidId === TypeEnum.User ? 'c' :
          tn2.data.typeGuidId === TypeEnum.Tank ? 'd' :
            tn2.data.typeGuidId === TypeEnum.GpsDevice ? 'e' : 'z';

    return (t1 + tn1.label + tn1.data.guidId).localeCompare(t2 + tn2.label + tn2.data.guidId);
  }

  private static isObject(value: any) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
  }
}
