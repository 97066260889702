import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCenterService } from '../../../../shared/services';
import { TypePermission } from '../../models';
import { TypePermissionRequest } from './type-permission-request.model';


@Injectable({
  providedIn: 'root'
})
export class TypePermissionService {
  private urlSuffix = 'api/v0/typepermission';

  constructor(private apiService: ApiCenterService) { }

  get(guidId: string, typeGuidId: string): Observable<TypePermission> {
    const request: TypePermissionRequest = {
      guidId: guidId,
      typeGuidId: typeGuidId
    };

    return this.apiService.post<TypePermission>(
      this.urlSuffix,
      request
    );
  }
}
