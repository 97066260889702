import { Observable, Subject } from "rxjs";

export class BlobUtils {

  static b64ToBlob(b64: string, fileType: string): Blob {
    const bytesString: string = BlobUtils.b64ToUtf8(b64);
    return BlobUtils.stringToBlob(bytesString, fileType);
  }

  static utf8ToB64(str: string) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }
 
  static b64ToUtf8(str: string) {
    return decodeURIComponent(escape(window.atob( str )));
  }

  /*static decodeURIComponentSafe(s) {
    if (!s) return s;
    return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  }*/

  static stringToBlob(text: string, fileType: string, sliceSize?: number): Blob {
    /*sliceSize = sliceSize || 512;

    var byteArrays = [];
    for (var offset = 0; offset < text.length; offset += sliceSize) {
      var slice = text.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length * 2);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint16Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob: Blob = new Blob(byteArrays, { type: fileType }); */

    // Note to self, opening weird swedish chars in a csv in excel breaks the chars again, even after being correctly encoded / decoded. Opening the same file in VSC works fine
    const blob: Blob = new Blob([text], { type: fileType });
    return blob;
  }

  static blobToB64(file: File | Blob): Observable<string> {
    const subject = new Subject<string>();

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      subject.next(reader.result as string);
      subject.complete();
    };
    reader.onerror = function (error) {
      subject.error(error);
    };

    return subject;
 }

 static blobToText(file: File | Blob): Observable<string> {
    const subject = new Subject<string>();

    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      subject.next(reader.result as string);
      subject.complete();
    };
    reader.onerror = function (error) {
      subject.error(error);
    };

    return subject;
  }

  // Alternative, supposedely MDN recommended (more recent but worse support for older browsers)
  /*static utf8ToB64(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }));
  }
 
  static b64ToUtf8(str: string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
  }*/


}
