import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchInputComponent),
  multi: true
};

@Component({
  selector: 'lc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SearchInputComponent implements ControlValueAccessor {

  @ViewChild('input', { static: true, read: ViewContainerRef }) inputEl: any;

  @Input() label: string;

  onChange: (x: any) => {};
  onTouched: () => {};
  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  disableKeysPropagation() {
    this.inputEl.element.nativeElement.addEventListener('keydown', (event) => {
      const keyCode = event.keyCode;

      const KEY_ENTER = 13;
      const KEY_LEFT = 37;
      const KEY_UP = 38;
      const KEY_RIGHT = 39;
      const KEY_DOWN = 40;
      const KEY_PAGE_UP = 33;
      const KEY_PAGE_DOWN = 34;
      const KEY_PAGE_HOME = 36;
      const KEY_PAGE_END = 35;
      const preventKeyCodes = [KEY_ENTER, KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN, KEY_PAGE_UP, KEY_PAGE_DOWN, KEY_PAGE_HOME, KEY_PAGE_END];

      if (preventKeyCodes.indexOf(keyCode) >= 0) {
          // this stops the grid from receiving the event and executing keyboard navigation
          event.stopPropagation();
      }
    });
  }

  blurInput(): void {
    this.inputEl.element.nativeElement.blur();
  }

  focusInput(): void {
    this.inputEl.element.nativeElement.focus();
  }

  reset() {
    this.value = undefined;
    this.onChange(this.value);

    this.cdr.markForCheck();
    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

}
