import { WebCenterType, WebCenterTypeMember, WebCenterTypeMemberSpecialValueType, WebObject, WorkspaceSolutionType } from 'app/center-v2/core/models';
import { WorkspaceSolutionTypeMember } from 'app/center-v2/core/models/workspace-solution-type-member.model';
import { WebAgGridViewMemberFilterType } from 'app/center-v2/core/view-system/models/web-view-member-filter-type.enum';
import { WebViewMemberSort } from 'app/center-v2/core/view-system/models/web-view-member-sort.enum';
import { WebViewMemberValueType } from 'app/center-v2/core/view-system/models/web-view-member-value-type.enum';
import { SelectItem } from 'primeng/api';
import { GridCellRendererText } from '../components/grid';
import { GridCellEditorFormField } from '../components/grid/grid-celleditor-form-field/grid-celleditor-form-field.component';
import { ObjectOrValue, SolutionTypeSubMemberType } from '../models';
import { CaseUtils } from './case.utils';
import { JsonUtils } from './json.utils';

export class GridUtils {

  static buildColumnFromWorkspaceSolutionTypeMember(
    solutionTypes: WorkspaceSolutionType[],
    member: WorkspaceSolutionTypeMember,
    webCenterTypes: WebCenterType[],
    parentComponent: any,
    userLanguageGuidId: string,
    useRelationPointersAsRows?: boolean,
    cellRendererParamsExtras?: any,
  ): any {
    let fieldOrValueGetter = undefined;
    const fieldPath = (useRelationPointersAsRows ? 'web2Object.' : '') + member.getWeb2ObjectPath();
    
    if (
      member.objectOrValue === ObjectOrValue.Object &&
      member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Relation
    ) {
      const relationMemberSolutionType = (solutionTypes || []).find((st: WorkspaceSolutionType) => {
        return st.baseSolutionTypeGuidId === member.subMembers[member.subLevels].toTypeGuidId;
      });
      fieldOrValueGetter = (params: any) => {
        let relationWebObject = JsonUtils.deepFind(params.data, member.getWeb2ObjectPath());
        if (!relationWebObject && member.fallbackRelationTypeName) { // fallback
          relationWebObject = JsonUtils.deepFind(params.data, `relations.${CaseUtils.camelize(member.fallbackRelationTypeName)}.web2Object`);
        }
         
        return relationMemberSolutionType?.getMainMemberLabel(relationWebObject) || relationWebObject?.members?.name || relationWebObject?.guidId;
      };
    } else {
      fieldOrValueGetter = (params: any) => {
        const membersPathSubString = fieldPath.substring((fieldPath || '').indexOf('members.'));
        const key = membersPathSubString.substring('members.'.length);
        const newFieldPath = fieldPath.replace(membersPathSubString, `languageMembers.${key}.${userLanguageGuidId}`);
        if (params?.data) {
          const newFieldPathValue = JsonUtils.deepFind(params.data, newFieldPath);
          if (userLanguageGuidId && newFieldPathValue) {
            return newFieldPathValue;
          } else {
            const value = JsonUtils.deepFind(params.data, fieldPath);

            const wct = (webCenterTypes || []).find((ct: WebCenterType) => {
              return ct.typeGuidId === member.subMembers[member.subLevels].fromTypeGuidId;
            });
            const wctm = (wct?.members || []).find((ctm: WebCenterTypeMember) => {
              return ctm.guidId === member.guidId;
            });
            if (wctm?.$fieldOptions?.length) {
              if (wctm?.specialValuesType === WebCenterTypeMemberSpecialValueType.IsFlags) {
                const multiSelectFlagsValue = [];
                for (const option of wctm.$fieldOptions || []) {
                  if ((value & option.value) === option.value) {
                    multiSelectFlagsValue.push(option.label);
                  }
                }
                return multiSelectFlagsValue.join(', ');
              } else {
                const enumValue = wctm?.$fieldOptions.find((item: SelectItem) => {
                  return item.value == value;
                })?.label || value;

                return enumValue;
              }
            } else {
              return value;
            }
          }
        } 
      }
    }
    
    return GridUtils.buildColumn(
      member.displayName,
      fieldOrValueGetter,
      member.fieldType,
      parentComponent,
      false,
      member.sort,
      useRelationPointersAsRows,
      cellRendererParamsExtras,
      false,
      undefined,
      fieldPath,
    );
  }

  static buildColumnFromRelationWebObject(
    headerName: string,
    solutionTypes: WorkspaceSolutionType[],
    getRelationWebObject: (params: any) => WebObject,
    parentComponent: any,
    sort?: 'asc' | 'desc' | 'not-sortable',
    cellRendererParamsExtras?: any,
  ): any {
    let fieldOrValueGetter = (params: any) => {
      const relationWebObject = getRelationWebObject(params);
      const relationMemberSolutionType = (solutionTypes || []).find((st: WorkspaceSolutionType) => {
        return st.baseSolutionTypeGuidId === relationWebObject?.typeGuidId;
      });
      return relationMemberSolutionType?.getMainMemberLabel(relationWebObject) || relationWebObject?.members?.name || relationWebObject?.guidId;
    };

    return GridUtils.buildColumn(
      headerName,
      fieldOrValueGetter,
      'text',
      parentComponent,
      false,
      sort,
      false,
      cellRendererParamsExtras,
    );
  }

  static buildColumnFromViewMemberWebObject(
    viewMemberWO: WebObject,
    viewDesignFilterMemberValues: any,
    cellRendererParamsExtras: any,
    cellStyle: (params: any) => any,
    parentComponent: any,
  ): any {
    const fieldType: string = viewMemberWO.members.valueType === WebViewMemberValueType.Date ? 'date' :
    viewMemberWO.members.valueType === WebViewMemberValueType.DateTime ? 'datetime' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'number' :
      'text';
    const chartDataType = (viewMemberWO.members.valueType === WebViewMemberValueType.Date || viewMemberWO.members.valueType === WebViewMemberValueType.DateTime) ? 'time' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'series' : 
      viewMemberWO.members.valueType === WebViewMemberValueType.Unknown ? 'excluded' :
      'category';
    const filter = (viewMemberWO.getSubType('WebAgGridViewDesignMember')?.members?.filterType === WebAgGridViewMemberFilterType.List && viewDesignFilterMemberValues[viewMemberWO.guidId]) ? 'agSetColumnFilter' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Date || viewMemberWO.members.valueType === WebViewMemberValueType.DateTime) ? 'agDateColumnFilter' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'agNumberColumnFilter' :
      'agTextColumnFilter';
    const filterValuesMap = !viewDesignFilterMemberValues[viewMemberWO.guidId] ? {} :
      viewDesignFilterMemberValues[viewMemberWO.guidId]
      .reduce((previousValue, currentValue) => {
        previousValue[currentValue.objectGuidId] = currentValue.value;
        return previousValue;
      }, {});

    const filterParams = ['agDateColumnFilter', 'agSetColumnFilter'].indexOf(filter) >= 0 ? 
      {
        comparator: (filterLocalDate, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return 0;

          var cellDate = new Date(dateAsString);

          return cellDate < filterLocalDate ? -1 : cellDate > filterLocalDate ? 1 : 0;
        },
        values: filter === 'agSetColumnFilter' ?
          viewDesignFilterMemberValues[viewMemberWO.guidId].map((item: any) => {
            return item.objectGuidId || item.value;
          }) :
          undefined,
        valueFormatter: (params: any) => {
          return filterValuesMap[params.value] || params.value;
        },
      } : undefined;

    const cellRendererParams = Object.assign({
      filter: () => { return parentComponent.gridQuickFilter; },
      isDate: fieldType === 'date' || fieldType === 'datetime',
      withTime: fieldType === 'datetime',
    }, cellRendererParamsExtras || {});

    return {
      headerName: viewMemberWO.members?.name + (viewMemberWO.members.valueType === WebViewMemberValueType.Percentage ? ' (%)' : ''),
      field: 'values.' + viewMemberWO.guidId,
      colId: viewMemberWO.guidId,
      cellRendererFramework: GridCellRendererText,
      cellRendererParams: cellRendererParams,
      cellStyle: cellStyle,
      chartDataType: chartDataType,
      editable: false,
      filter: filter,
      filterParams: filterParams,
      floatingFilter: true,
      hide: viewMemberWO.members?.hide || viewMemberWO.members.preFilter,
      minWidth: 120,
      rowGroup: viewMemberWO.members?.rowGroup,
      sort: viewMemberWO.members?.sort === WebViewMemberSort.Asc ? 'asc' : viewMemberWO.members?.sort === WebViewMemberSort.Desc ? 'desc' : undefined,
      sortable: true,
      width: 120,
    };
  }

  static buildColumn(
    headerName: string,
    fieldOrValueGetter: string | any,
    fieldType: string,
    parentComponent: any,
    editable = false,
    sort?: 'asc' | 'desc' | 'not-sortable',
    useRelationPointersAsRows?: boolean,
    cellRendererParamsExtras?: any,
    floatingFilter?: boolean,
    chartDataType?: 'category' | 'series' | 'time' | 'excluded' | undefined,
    colId?: string,
    valueSetter?: any,
  ): any {
    const cellRendererParams = Object.assign({
      filter: () => { return parentComponent.gridQuickFilter; },
      isDate: fieldType === 'date' || fieldType === 'datetime',
      withTime: fieldType === 'datetime',
    }, cellRendererParamsExtras || {});

    return {
      headerName: headerName,
      field: typeof fieldOrValueGetter === 'string' ? (useRelationPointersAsRows ? 'web2Object.' : '') + fieldOrValueGetter : undefined,
      colId: colId || (typeof fieldOrValueGetter === 'string' ? fieldOrValueGetter : undefined),
      valueGetter: typeof fieldOrValueGetter === 'function' ? fieldOrValueGetter : undefined,
      valueSetter: valueSetter,
      cellEditorFramework: GridCellEditorFormField,
      cellEditorParams: {
        // cellValueChanged: (params: any) => {  },
        type: fieldType,
      },
      cellRendererFramework: GridCellRendererText,
      cellRendererParams: cellRendererParams,
      chartDataType: chartDataType,
      editable: editable,
      filter: (fieldType || '').indexOf('date') >= 0 ? 'agDateColumnFilter' : fieldType === 'number' ? 'agNumberColumnFilter' : 'agTextColumnFilter',
      filterParams: fieldType === 'date' || fieldType === 'datetime' ? {
        comparator: (filterLocalDate, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return 0;

          var cellDate = new Date(dateAsString);

          return cellDate < filterLocalDate ? -1 : cellDate > filterLocalDate ? 1 : 0;
        }
      } : undefined,
      floatingFilter: floatingFilter,
      minWidth: 120,
      sort: sort !== 'not-sortable' ? sort : undefined,
      sortable: sort !== 'not-sortable' ? true : false,
      width: 120,
    };
  }

  static getParamValue(params: any, paramField: any): any {
    return paramField && typeof paramField === 'function' ? paramField(params) : paramField;
  }

  static getParamValueSuccessCallback(params: any, paramField: any, successCallback: (data: any | any[]) => void): void {
    return paramField && typeof paramField === 'function' ? paramField(params, successCallback) : paramField;
  }

  static filterParamsLocalDateAtMidnightComparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) return 0;

    let cellDate = new Date(dateAsString);
    cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate())

    return cellDate < filterLocalDateAtMidnight ? -1 : cellDate > filterLocalDateAtMidnight ? 1 : 0; 
  }

}