import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AppService } from './shared/services';



@Injectable()
export class AppErrorHandler implements ErrorHandler {

  private appService: AppService;
  private isShowingError: boolean;

  constructor(
    private injector: Injector,
  ) {
    this.appService = this.injector.get(AppService);
  }

  handleError(error) {
    if (
      !this.isShowingError &&
      error &&
      (error.message || '').indexOf('Loading chunk') < 0 && // ignore loading chunk errors as they're handled in app.component NavigationError event
      (error.message || error || '').indexOf('Duplicate requests prevented:') < 0 // ignore what the error message says...
    ) {
      this.isShowingError = true;

      // this.appService.showDefaultErrorToast(error);
      console.error(error);

      const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({ eventId });

      setTimeout(() => {
        this.isShowingError = false;
      }, 30 * 1000);
    } else if (error) {
      console.warn(error);
    }
  }
}
