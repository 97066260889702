<div class="ui-float-label input-search">
  <input #input
    pInputText
    type="text"
    name="filter"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)" />
    <a *ngIf="!!value"
      class="small-reset fas fa-times-circle"
      [title]="'Reset search filter' | translate"
      (click)="reset()">
    </a>

  <label for="filter">
    {{ label || 'Search...' | translate }}
  </label>
</div>
