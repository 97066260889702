import { ChangeDetectorRef, HostListener, ViewChild, Directive, Component } from '@angular/core';
import { BaseComponentV2 } from 'app/shared/components/base/base-v2.component';
import { SessionService } from 'app/shared/services/app';
import { Dialog } from 'primeng/dialog';



@Component({
  template: '<div></div>'
})
export abstract class BaseDialog extends BaseComponentV2 {

  readonly SIZE_MD = 960;

  @ViewChild(Dialog, { static: true }) dialog: Dialog;

  protected data: any;
  isVisible: boolean;
  onClose: (data: any) => void;

  windowWidth: number;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sessionService?: SessionService,
  ) {
    super(cdr, sessionService);

    setTimeout(() => {
      this.resize();
    });
  }

  @HostListener('window:resize', ['$event'])
  protected resize(event?: Event) {
    this.windowWidth = window.innerWidth;

    this.cdr.markForCheck();
  }

  show(data?: any) {
    this.isVisible = true;
    this.data = data;
    this.init(this.data);

    this.cdr.markForCheck();
  }

  protected abstract init(data: any);

  dismiss() {
    this.close(undefined);
  }

  protected close(data: any) {
    if (this.onClose) {
      this.onClose(data);
    }
    this.isVisible = false;

    this.cdr.markForCheck();
  }

  positionOverlay() {
    setTimeout(() => {
      if (this.isVisible) {
        // this.dialog.positionOverlay();
        this.cdr.markForCheck();
      }
    });
  }

}
