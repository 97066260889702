<p-autoComplete class="cell-field flex-1"
  (completeMethod)="params.search(params, $event.query, searchCallback)"
  dataKey="guidId"
  [disabled]="params.disabled"
  field="members.name"
  [forceSelection]="true"
  [minLength]="3"
  [(ngModel)]="params.value"
  (ngModelChange)="cellValueChanged.next()"
  [suggestions]="searchResults">
  <ng-template let-item pTemplate="item">
    <div class="list-item">
      <div *ngIf="!item.guidId"
        class="name">
        <i class="fas fa-plus"></i>
        {{ 'New Ingredient' | translate }}
      </div>

      <div *ngIf="!item.guidId"
        class="description">
        {{ 'Click to add a new ingredient...' | translate }}
      </div>

      <div *ngIf="item.guidId">
        <div class="name">
          {{ item.members?.name }} {{ item.sysId ? '(' + item.sysId + ')' : '' }}
        </div>

        <div class="description">
          {{ 'IngredientType' | translate }}: {{ item.members?.ingredientType === 0 ? ('Main' | translate) : ('Content' | translate) }}
        </div>

        <div class="description">
          {{ 'Version' | translate }}: {{ item.members?.version || 'N/A' }}
        </div>

        <div class="description">
          {{ 'Is Allergen' | translate }}: {{ (item.members?.isAllergen ? 'Yes' : 'No') | translate }}
        </div>
      </div>
      
    </div>
  </ng-template>
</p-autoComplete>