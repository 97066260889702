<div *ngIf="params?.data"
  class="cell flex-row"
  [class.center]="!params?.group"
  [class.middle]="!params?.group">
  <span *ngIf="params?.group" class="ag-group-expanded" ref="eExpanded">
    <span class="ag-icon ag-icon-tree-open" unselectable="on">
    </span>
  </span>
  <span *ngIf="params?.group" class="ag-group-contracted" ref="eContracted">
    <span class="ag-icon ag-icon-tree-closed" unselectable="on">
    </span>
  </span>

  <span (click)="onClick($event)"
    style="width: 100%;">
    <i [class.disabled]="params?.disabled"
      [class.link]="params?.click"
      [ngClass]="params?.icon"
      [pTooltip]="params?.tooltip"
      [style.color]="params?.color">
    </i>
  </span>
</div>
