import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { GdprModule } from 'app/shared/dialogs/gdpr/gdpr.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonComponentModule } from '../button/button.component.module';
import { TreeComponentModule } from '../tree/tree.component.module';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { TopBarComponent } from './top-bar.component';


@NgModule({
  declarations: [
    SideMenuComponent,
    TopBarComponent,
  ],
  exports: [
    TopBarComponent,
  ],
  imports: [
    AppSharedV2Module,
    BreadcrumbModule,
    ButtonComponentModule,
    ClickOutsideModule,
    DialogModule,
    GdprModule,
    OverlayPanelModule,
    PanelMenuModule,
    SidebarModule,
    TreeComponentModule,
  ],
  providers: [],
})
export class TopBarModule { }

