import { plainToClass } from 'class-transformer';
import { CenterType, TreeRelationSource } from '.';
import { CommentSource } from './comment-source.model';
import { DataSource } from './data-source.model';
import { Permission } from './permission.model';
import { TreeSource } from './tree-source.model';

export class DataResponse {
  webDataSources: DataSource[];
  webTreeSources: TreeSource[];
  webCenterTypes?: CenterType[];
  webTraceSources?: any[];
  webCommentSources?: CommentSource[];
  webSessionPermissions?: Permission[];

  constructor(item?: any) {
    Object.assign(this, item);

    this.webDataSources = plainToClass(DataSource, this.webDataSources);

    if (this.webTreeSources) {
      this.webTreeSources = plainToClass(TreeSource, this.webTreeSources);
      for (const ts of this.webTreeSources) {
        ts.treeRelationSources = plainToClass(TreeRelationSource, ts.treeRelationSources);
      }
    }
  }

}
