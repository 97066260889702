import { Injectable } from '@angular/core';
import { FilterStringFilter } from 'app/center-v2/core/models/filter-string/filter-string-filter.model';
import { DictString } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { GuidUtils } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebCenterType, WebObject, WebPointerStatus, WebRelationPointer, WebResponse } from '../../core/models';
import { WebLoadMap } from '../../core/models/web-base/web-load-map.model';
import { AdvancedSearchResponse } from '../models/advanced-search-response.model';


@Injectable({
  providedIn: 'root'
})
export class GenericService {

  private urlSuffixPlaceholder = 'centerv2/generic/{what}';
  private urlSuffixPlaceholderV2 = 'centerv2/generic2/{what}';

  private webCenterTypeCache: DictString<WebCenterType> = {};

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  /** @deprecated */
  get(guidId: string, fullObject?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        workspaceGuidId: workspaceGuidId,
        web2LoadTemplate: fullObject ? 'Complete' : 'Base',
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getAllCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  /** @deprecated */
  getMap(guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'map/get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        web2LoadTemplate: true ? 'Complete' : 'Base',
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getAllCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getV2(guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        web2LoadTemplate: true ? 'Complete' : 'Base',
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getAllCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getBatch(guidIds: string[], webLoadMap: WebLoadMap, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'get'),
      {
        guidId: guidIds,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        web2LoadTemplate: true ? 'Complete' : 'Base',
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getAllCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getTypes(typeGuidIds: string[]): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'types/get'),
      {
        typeGuidIds: typeGuidIds,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getAllCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  list(typeGuidId: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, fullObjects?: boolean, includeCenterTypes?: boolean, siteGuidId?: string, subSiteLevels?: number, solutionEnvironmentGuidId?: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'list'),
      {
        includeCenterTypes: includeCenterTypes || false,
        siteGuidId: siteGuidId,
        solutionEnvironmentGuidId: solutionEnvironmentGuidId,
        subSiteLevels: subSiteLevels || undefined,
        typeGuidId: typeGuidId,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationMap(parentGuidId: string, typeGuidId: string, relationName: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'map/relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relation(parentGuidId: string, typeGuidId: string, relationName: string, fullObjects?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationV2(parentGuidId: string, typeGuidId: string, relationName: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationsCount(parentGuidId: string, typeGuidId: string, relationNames: string[], fullObjects?: boolean, includeCenterTypes?: boolean): Observable<DictString<number>> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'relationscount'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationNames: relationNames,
        includeCenterTypes: includeCenterTypes,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.relationsCount : null;
      })
    );
  }

  search(typeGuidId: string, member: string, value: any, wildcardSearch?: boolean, incaseSensitive?: boolean, fullObjects?: boolean, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholder.replace('{what}', 'search'),
      {
        typeGuidId: typeGuidId,
        member: member,
        value: value,
        wildcardSearch: wildcardSearch,
        incaseSensetive: incaseSensitive,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  advancedSearch(web2FilterStrings: DictString<{ filter: FilterStringFilter, web2LoadMap: WebLoadMap }>, fromDateTime?: string, toDateTime?: string, fullObjects?: boolean, includeCenterTypes?: boolean): Observable<AdvancedSearchResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'advancesearch'),
      {
        web2FilterStrings: web2FilterStrings,
        fromDateTime: fromDateTime,
        toDateTime: toDateTime,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new AdvancedSearchResponse(response) : null;
      })
    );
  }

  newDraftObject(typeGuidId: string, parentGuidId?: string, includeCenterTypes?: boolean): Observable<WebResponse> {
    if (includeCenterTypes && !!this.webCenterTypeCache[typeGuidId]) {
      includeCenterTypes = false;
    }

    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'new'),
      {
        typeGuidId: typeGuidId,
        parentGuidId: parentGuidId,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response = new WebResponse(result);
        if (!includeCenterTypes && !!this.webCenterTypeCache[typeGuidId]) {
          response.addCenterType(this.webCenterTypeCache[typeGuidId]);
        } else if (includeCenterTypes) {
          this.webCenterTypeCache[typeGuidId] = response.getCenterType(typeGuidId);
        }

        return response;
      })
    );
  }

  newDraftRelation(parentTypeGuidId: string, relationName: string, childObject: WebObject, workspaceGuidId?: string): Observable<WebRelationPointer> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newrelation'),
      {
        parentTypeGuidId: parentTypeGuidId,
        relationName: relationName,
        childGuidId: childObject?.guidId || GuidUtils.emptyGuid(),
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (response?.web2RelationPointer) {
          const wrp = new WebRelationPointer(response.web2RelationPointer);
          wrp.web2Object = childObject;
          return wrp;
        } else {
          return null;
        }
      })
    );
  }

  newDraftSubType(guidId: string, typeGuidId: string, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newsubtype'),
      {
        guidId: guidId,
        typeGuidId: typeGuidId,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  update(webObjects: WebObject[], workspaceGuidId?: string): Observable<WebResponse> {
    const webObjectsToUpdate = this.cleanWebObjectsForUpdate(webObjects);

    return this.apiService.post<void>(
      this.urlSuffixPlaceholderV2.replace('{what}', 'update'),
      {
        web2Objects: webObjectsToUpdate,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (response) {
          const webResponse = new WebResponse(response);
          const updatedWebObjects = webResponse.getObject() as WebObject[];
          for (const uwo of updatedWebObjects || []) {
            const existingWO = webObjects.find(wo => wo.guidId === uwo.guidId);
            if (existingWO) {
              Object.assign(existingWO, uwo);
              existingWO.updateOriginalWebObject();
            }
          }
          return webResponse;
        } else {
          return null;
        }
      })
    );
  }

  private cleanWebObjectsForUpdate(webObjects: WebObject[]) {
    const webObjectsToUpdate = [];
    for (const wo of JSON.parse(JSON.stringify(webObjects))) {
      webObjectsToUpdate.push(wo);

      // We don't need to send back the LazyRelations property
      delete wo.lazyRelations;

      if (wo.web2Status !== WebPointerStatus.New) {
        // Clean LanguageMembers
        const updatedLanguageMembers = [];
        for (const key of Object.keys(wo.languageMembers || {})) {
          for (const languageGuidId of Object.keys(wo.languageMembers[key] || {})) {
            if (wo.languageMembers[key][languageGuidId] === wo.$originalWebObject.languageMembers[key][languageGuidId]) {
              delete wo.languageMembers[key][languageGuidId];
            } else {
              updatedLanguageMembers.push(key);
            }
          }
          if (!Object.keys(wo.languageMembers[key] || {}).length) {
            delete wo.languageMembers[key];
          }
        }

        // Clean Members
        for (const key of Object.keys(wo.members || {})) {
          if (
            updatedLanguageMembers.indexOf(key) < 0 &&
            wo.members[key] === wo.$originalWebObject.members[key]
          ) {
            delete wo.members[key];
          }
        }
      }

      // Clean Relations
      for (const key of Object.keys(wo.relations || {})) {
        const originalRelationPointers = wo.$originalWebObject.relations[key] || [];
        if (wo.relations[key] && JSON.stringify(wo.relations[key]).localeCompare(JSON.stringify(originalRelationPointers)) !== 0) {
          if (Array.isArray(wo.relations[key])) {
            let originalIndex = 0;
            let removedRelationCount = 0;
            for (let i = 0; i < (wo.relations[key] as WebRelationPointer[]).length; i++) {
              originalIndex = i + removedRelationCount;
              const rp: WebRelationPointer = wo.relations[key][i];
              if (
                originalIndex >= originalRelationPointers?.length ||
                JSON.stringify(rp).localeCompare(JSON.stringify(originalRelationPointers ? originalRelationPointers[originalIndex] : null)) !== 0
              ) {
                for (const key2 of Object.keys(rp.members || {})) {
                  if (rp.members[key2] === originalRelationPointers[originalIndex]?.members[key2]) {
                    delete rp.members[key];
                  } else if (rp.web2Status === WebPointerStatus.Active) {
                    rp.web2Status = WebPointerStatus.Update;
                  }
                }
                if (rp.web2Object) {
                  rp.web2Object = this.cleanWebObjectsForUpdate([rp.web2Object])[0];
                }
              } else {
                (wo.relations[key] as WebRelationPointer[]).splice(i, 1);
                removedRelationCount++;
                i--;
              }
            }
          } else {
            const rp = wo.relations[key] as WebRelationPointer;
            for (const key2 of Object.keys(rp.members || {})) {
              if (rp.members[key2] === (wo.$originalWebObject.relations[key] as WebRelationPointer).members[key2]) {
                delete rp.members[key];
              } else if (rp.web2Status === WebPointerStatus.Active) {
                rp.web2Status = WebPointerStatus.Update;
              }
            }

            if (rp.web2Object) {
              rp.web2Object = this.cleanWebObjectsForUpdate([rp.web2Object])[0];
            }
          }
        } else {
          delete wo.relations[key];
        }
      }

      // Clean SubTypes
      for (const key of Object.keys(wo.subTypes || {})) {
        if (
          wo.subTypes[key] &&
          (wo.subTypes[key].web2Status !== WebPointerStatus.Active ||
          JSON.stringify(wo.subTypes[key]) !== JSON.stringify(wo.$originalWebObject.subTypes[key]))
        ) {
          wo.subTypes[key] = this.cleanWebObjectsForUpdate([wo.subTypes[key]])[0];
        } else {
          delete wo.subTypes[key];
        }
      }

      // Set Web2Status flag accordingly
      if (
        wo.web2Status === WebPointerStatus.Active &&
        (
          Object.keys(wo.languageMembers || {}).length ||
          Object.keys(wo.members || {}).length ||
          Object.keys(wo.relations || {}).length ||
          Object.keys(wo.subTypes || {}).length
        )
      ) {
        wo.web2Status = WebPointerStatus.Update;
      }
    }

    return webObjectsToUpdate;
  }

}
