import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { TreeNode } from 'primeng/api';
import { AppService } from '../../services';



@Component({
  selector: 'lc-select-site-dialog',
  templateUrl: './select-site.dialog.html',
  styleUrls: ['./select-site.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSiteDialog extends BaseDialog {

  @ViewChild('tree') tree: any; // TreeComponent; circular dependency

  title: string;

  rootObjectGuidId: string;
  selectedTreeNode: TreeNode;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.title = undefined;
    this.rootObjectGuidId = undefined;

    setTimeout(() => {
      this.title = data.title;
      this.rootObjectGuidId = data.rootObjectGuidId;

      this.cdr.markForCheck();
    });
  }

  submit() {
    this.close(this.selectedTreeNode);
  }

  searchTree() {
    this.tree.toggleSearch();
  }

}
