import { Injectable } from '@angular/core';
import * as Comlink from 'comlink';
import { from, Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storage: any;
  private isReadySubject: Subject<void>;

  constructor() {
    this.isReadySubject = new Subject();

    const Storage = Comlink.wrap<typeof import('app/workers/storage.worker').Storage>(
      new Worker('app/workers/storage.worker', { type: 'module' } ),
    );

    (new Storage()).then((storage: any) => {
      this.storage = storage;

      this.isReadySubject.next();
      this.isReadySubject.complete();
    });
  }

  private waitUntilReady(): Observable<void> {
    if (this.storage) {
      return of(null);
    } else {
      return this.isReadySubject.asObservable();
    }
  }

  get(key: string): Observable<any> {
    return this.waitUntilReady()
    .pipe(
      mergeMap(() => {
        return from(this.storage.get(key));
      }),
    );
  }

  remove(key: string): Observable<any> {
    return from(this.storage.remove(key));
  }

  set(key: string, value: any): Observable<any> {
    return from(this.storage.set(key, value));
  }

}
