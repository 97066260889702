const pako = require('pako');

export const enum ActionCode {
  Ok = 0,
  NoSessionAccess = 1,
  SetPassword = 2,
  Retry = 3,

  StudioInvalidReservation = 1001,
}

export class ApiResponse<T> {

  actionCode: ActionCode;
  success: boolean;
  exceptionString: string;
  failedReason: string;

  private _compressedValue?: string;
  private _value?: T;

  constructor(item: any) {
    Object.assign(this, item);
  }

  set compressedValue(val: string) {
    this._compressedValue = val;
  }

  set value(val: T) {
    this._value = val;
  }

  get value(): T {
    if (!this._value && this._compressedValue) {
      // let previousNow = performance.now();
      const valueUint8Array = pako.ungzip(window.atob(this._compressedValue));
      const decoder = new (window as any).TextDecoder('utf8');
      this._value = JSON.parse(decoder.decode(valueUint8Array));
      // let now = performance.now();
      // console.log(`Time it took to inflate & decode compressedValue: ${now - previousNow}ms`);
    }

    return this._value;
  }

}
