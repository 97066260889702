import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { UserSession } from '../../models';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterService } from '../api/api-center.service';
import { SessionService } from '../app';




@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly urlSession = 'api/v0/session';
  private readonly urlV2ValidateSession = 'centerv2/user/validatesession';
  private readonly urlUserSuffixPlaceholder = 'api/v0/{what}';
  private readonly urlV2UserSuffixPlaceholder = 'user/{what}';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
    private sessionService: SessionService,
  ) { }

  loginWithEmail(email: string, password: string): Observable<UserSession> {
    return this.apiService.post<UserSession>(
      this.urlSession,
      {
        email: email,
        password: password,
        baseUri: this.getBaseUrl(),
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session, email);
      })
    );
  }

  loginWithGuid(userGuidId: string, password: string): Observable<UserSession> {
    return this.apiService.post<UserSession>(
      this.urlSession,
      {
        baseId: userGuidId,
        password: password,
        baseUri: this.getBaseUrl(),
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session);
      })
    );
  }

  loginAsUser(userGuidId: string): Observable<UserSession> {
    return this.apiV2Service.post<UserSession>(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'logon/logonasuser'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session);
      })
    );
  }

  logout(): Observable<UserSession> {
    return this.apiService.post<UserSession>(
      this.urlUserSuffixPlaceholder.replace('{what}', 'sessionlogoff'),
      {}
    ).pipe(
      mergeMap((logoffSession: any) => {
        sessionStorage.clear();

        let session: UserSession = null;
        if (logoffSession.parentUserSession) {
          session = logoffSession.parentUserSession;
        } else {
          session = this.sessionService.instant();
          session.guidId = undefined;
        }

        session.hasParentSession = false;
        return this.sessionService.set(session);
      })
    );
  }

  fullLogout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  validateSession(): Observable<UserSession> {
    return this.apiV2Service.post<any>(
      this.urlV2ValidateSession,
      {}
    ).pipe(
      mergeMap((response: any) => {
        return this.sessionService.get()
        .pipe(
          mergeMap((session: UserSession) => {
            if (session) {
              session.hasParentSession = response.hasParentSession;
              session.inConfuscateMode = response.inConfuscateMode;
              return this.sessionService.set(session);
            } else {
              return of(session);
            }
          })
        );
      })
    );
  }

  setPassword(password: string): Observable<boolean> {
    return this.apiService.post<boolean>(
      this.urlUserSuffixPlaceholder.replace('{what}', 'user/setpassword'),
      {
        password: password,
        baseUri: this.getBaseUrl(),
      }
    ).pipe(
      map((result: boolean) => {
        this.sessionService.get()
        .subscribe((session: UserSession) => {
          session.tempPassword = false;
          this.sessionService.set(session).subscribe();
        });

        return result;
      })
    );
  }

  generatePassword(userGuidId: string, emailUser: boolean): Observable<boolean> {
    return this.apiService.post<boolean>(
      this.urlUserSuffixPlaceholder.replace('{what}', 'user/generatenewpassword'),
      {
        userGuidId: userGuidId,
        emailUser: emailUser,
        baseUri: this.getBaseUrl(),
      }
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.apiService.post<boolean>(
      this.urlUserSuffixPlaceholder.replace('{what}', 'user/forgotpassword'),
      {
        email: email,
        baseUri: this.getBaseUrl(),
      }
    );
  }

  private getBaseUrl(): string {
    return window.location.origin;
  }

  private handleLoginResponse(session: UserSession, email?: string): Observable<UserSession> {
    if (session.authenticated) {
      if (email) {
        session.email = email;
      }

      return this.sessionService.set(session);
    } else {
      throw Error('Invalid Credentials');
    }
  }
}
