import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GridUtils } from 'app/shared/utils';


@Component({
  selector: 'lc-grid-cellrenderer-icon',
  templateUrl: 'grid-cellrenderer-icon.component.html',
  styleUrls: ['grid-cellrenderer-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererIcon implements AgRendererComponent {

  params: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }

  agInit(params): void {
    this.params = params;

    this.params.color = GridUtils.getParamValue(this.params, this.params.color);
    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.icon = GridUtils.getParamValue(this.params, this.params.icon);
    this.params.tooltip = GridUtils.getParamValue(this.params, this.params.tooltip);
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  onClick(ev: Event) {
    if (this.params?.click) {
      ev.stopPropagation();

      this.params?.click && !this.params?.disabled ? this.params.click(this.params, ev) : null;
    }
  }

}
