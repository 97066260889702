import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../utils';

@Pipe({ name: 'localisostring' })
export class LocalISOStringPipe implements PipeTransform {

  transform(text: string, displaySeconds?: string): string {
    return DateUtils.getLocalISOString(text, !!displaySeconds);
  }
}
