
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { SolutionTypeMember } from './solution-type-member.model';
import { SolutionTypeSpecialValue } from './solution-type-special-value.model';



export class SolutionType {

  guidId: string;
  solutionProfileGuidId?: string;
  reportTemplateGuidId?: string;
  name: string;

  baseSolutionTypeGuidId: string;
  objectTypeGuidIds: string[];

  members: SolutionTypeMember[];

  specialValues: SolutionTypeSpecialValue[];

  compiled: boolean;
  valueTypeString: string;


  constructor(item?: any) {
    if (item) {
      Object.assign(this, item);

      this.guidId = this.guidId || GuidUtils.new();
      this.objectTypeGuidIds = this.objectTypeGuidIds || [];

      this.members = (this.members || []).map((i: SolutionTypeMember) => {
        return new SolutionTypeMember(i);
      });
    } else {
      this.guidId = GuidUtils.new();
      this.objectTypeGuidIds = [];

      this.members = [];
    }
  }

}
