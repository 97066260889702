import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { MessageService, TreeDragDropService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';




@NgModule({
  imports: [
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ColorPickerModule,
    ContextMenuModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MessagesModule,
    MenuModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelMenuModule,
    SelectButtonModule,
    SidebarModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TriStateCheckboxModule,
    TreeModule,
  ],
  exports: [
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ColorPickerModule,
    ContextMenuModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MessagesModule,
    MenuModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelMenuModule,
    SelectButtonModule,
    SidebarModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TriStateCheckboxModule,
    TreeModule,
  ],
})
export class PrimeNgModule {
  static forRoot(): ModuleWithProviders<PrimeNgModule> {
    return {
        ngModule: PrimeNgModule,
        providers: [
            MessageService,
            TreeDragDropService,
        ]
    };
}
}
