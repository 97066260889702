import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, Subject, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CenterType, DataResponse, DataSource, TreeSource } from '../../models';
import { StorageUtils } from '../../utils';
import { ApiCenterService } from '../api/api-center.service';
import { StateRequest } from './state-request.model';
import { StateResponse } from './state-response.model';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  private readonly URL_SUFFIX = 'api/v0/state';

  constructor(
    private apiService: ApiCenterService,
  ) { }

  getDataState(guidId: string, dataRequest$: Observable<DataResponse>): Observable<DataResponse> {
    const cachedDR = JSON.parse(StorageUtils.getItem('session', 'data_' + guidId) || '{}');
    if (cachedDR && cachedDR.webDataSources && cachedDR.webDataSources.length) {
      let request: StateRequest = {
        guidId: guidId,
        tick: cachedDR.webDataSources[0].tick,
      };
      return this.checkStateAndMakeDataRequestIfRequired(request, cachedDR, dataRequest$);
    } else {
      return dataRequest$;
    }
  }

  getTreeState(treeGuidId: string, dataRequest$: Observable<DataResponse>): Observable<DataResponse> {
    const cachedDR = JSON.parse(StorageUtils.getItem('session', 'tree_' + treeGuidId) || '{}');
    if (cachedDR && cachedDR.webTreeSources && cachedDR.webTreeSources.length) {
      let request: StateRequest = {
        treeGuidId: treeGuidId,
        tick: cachedDR.webTreeSources[0].tick,
      };
      return this.checkStateAndMakeDataRequestIfRequired(request, cachedDR, dataRequest$);
    } else {
      return dataRequest$;
    }
  }

  getDataAndTreeState(guidId: string, dataRequest$: Observable<DataResponse>): Observable<DataResponse> {
    const cachedDR = JSON.parse(StorageUtils.getItem('session', 'data&tree_' + guidId) || '{}');
    if (cachedDR && cachedDR.webDataSources && cachedDR.webDataSources.length &&
        cachedDR.webTreeSources && cachedDR.webTreeSources.length) {
      let requestData: StateRequest = {
        guidId: guidId,
        tick: cachedDR.webDataSources[0].tick,
      };
      let requestTree: StateRequest = {
        treeGuidId: guidId,
        tick: cachedDR.webTreeSources[0].tick,
      };

      const subject = new Subject<DataResponse>();

      zip(
        this.makeRequest(requestData),
        this.makeRequest(requestTree)
      )
      .pipe(
        map((srs: StateResponse[]) => {
          if (srs[0] && srs[0].stateValid &&
              srs[1] && srs[1].stateValid) {
            this.plainToClassMainProperties(cachedDR);
            subject.next(cachedDR);
            subject.complete();
          } else {
            dataRequest$.subscribe((dr: DataResponse) => {
              subject.next(dr);
              subject.complete();
            });
          }
        })
      ).subscribe();

      return subject.asObservable();
    } else {
      return dataRequest$;
    }
  }

  private makeRequest(request: StateRequest): Observable<StateResponse> {
    return this.apiService.post<StateResponse>(this.URL_SUFFIX, request);
  }

  private checkStateAndMakeDataRequestIfRequired(request: StateRequest, cachedDR: DataResponse, dataRequest$: Observable<DataResponse>): Observable<DataResponse> {
    const subject = new Subject<DataResponse>();

    this.makeRequest(request)
    .pipe(
      map((sr: StateResponse) => {
        if (sr && sr.stateValid) {
          this.plainToClassMainProperties(cachedDR);
          subject.next(cachedDR);
          subject.complete();
        } else {
          dataRequest$.subscribe((dr: DataResponse) => {
            subject.next(dr);
            subject.complete();
          });
        }
      })
    ).subscribe();

    return subject.asObservable();
  }

  private plainToClassMainProperties(cachedDR: DataResponse) {
    if (cachedDR.webDataSources) {
      cachedDR.webDataSources = plainToClass(DataSource, cachedDR.webDataSources);
    }
    if (cachedDR.webTreeSources) {
      cachedDR.webTreeSources = plainToClass(TreeSource, cachedDR.webTreeSources);
    }
    if (cachedDR.webCenterTypes) {
      cachedDR.webCenterTypes = plainToClass(CenterType, cachedDR.webCenterTypes);
    }
  }

}
