
import { GuidUtils } from 'app/shared/utils';
import { SelectItem, TreeNode } from 'primeng/api';
import { DictNumber } from '../dict.model';
import { CenterTypeMember } from '../center-type-member.model';
import { TypeEnum } from '../type.enum';
import { SolutionTypeSubMember, SolutionTypeSubMemberType } from './solution-type-sub-member.model';
import { SolutionTypeEnum, SystemComplexSolutionTypes, SystemSolutionTypes } from './solution-type.enum';

export enum ObjectOrValue {
  None = 0,
  Unknown = 1,
  Object = 2,
  Value = 3,
}

export class SolutionTypeMember {

  guidId: string;
  baseSolutionTypeGuidId: string;
  specialValueGuidId: string;
  name: string;
  displayName: string;
  path: string;

  searchMember: boolean;
  objectOrValue: ObjectOrValue;
  isValue: boolean;
  inLayout: boolean;
  isList: boolean;
  isRelationPointer: boolean;
  isStrictValues: boolean;
  isStructuralResolveValue: boolean;
  strictValues: number[];

  subLevels: number;
  subMembers: DictNumber<SolutionTypeSubMember>;

  forceLayout: boolean;

  readOnly: boolean;
  systemReadOnly: boolean;

  $memberId: number;
  fieldType: string;
  fieldOptions: SelectItem[];

  addSubTypeIfMissing: boolean;
  customControlType: string;

  constructor(item?: Partial<SolutionTypeMember>) {
    if (item) {
      Object.assign(this, item);

      this.subMembers = this.subMembers || {};
    } else {
      this.subMembers = {};
    }
  }

  getPrettyPath() {
    return '(root)' + (this.path ? ' > ' : '') + (this.path || '').replace(/\./g, ' > ');
  }

  static fromTreeNode(treeNode: TreeNode) {
    const newItem = new SolutionTypeMember();

    newItem.guidId = treeNode.data.guidId;

    if (treeNode.data.member) {
      newItem.baseSolutionTypeGuidId = this.getSolutionTypeEnumFromMember(treeNode.data.member)
      newItem.objectOrValue = ObjectOrValue.Value;
    } else if (treeNode.data.base) {
      newItem.objectOrValue = ObjectOrValue.Value;
    } else {
      newItem.baseSolutionTypeGuidId = treeNode.data.typeGuidId;
      newItem.objectOrValue = ObjectOrValue.Object;
    }
    newItem.path = this.getPath(treeNode);
    newItem.name = (newItem.path.replace(/\./g, ' ') + ' ' + treeNode.label).trim();
    newItem.displayName = treeNode.label;

    newItem.subMembers = {};

    newItem.objectOrValue = SystemSolutionTypes.indexOf(newItem.baseSolutionTypeGuidId) >= 0 && SystemComplexSolutionTypes.indexOf(newItem.baseSolutionTypeGuidId) < 0?
      ObjectOrValue.Value :
      ObjectOrValue.Object;
    newItem.isList = !!treeNode.data.isList || !!treeNode.data.parentIsList;
    newItem.isRelationPointer = treeNode.data.isRelationPointer;
    newItem.isValue = !newItem.isList || !!treeNode.data.member;

    newItem.isStructuralResolveValue = this.getIsStructuralResolveValue(treeNode);

    newItem.$memberId = treeNode.data.member ? treeNode.data.member.memberId : 0;
    newItem.fieldType = treeNode.data.member?.$formControlType || treeNode.data.member?.$fieldType || treeNode.data.base?.$fieldType;
    newItem.fieldOptions = treeNode.data.member?.$fieldOptions;

    newItem.systemReadOnly = treeNode.data.member ? treeNode.data.member.systemReadOnly : true;
    newItem.readOnly = newItem.systemReadOnly;

    console.log(treeNode);
    console.log(newItem);
    return newItem;
  }

  static getPath(treeNode: TreeNode) {
    let path = ''; // treeNode.label;
    while (treeNode.parent && treeNode.parent.parent && treeNode.parent.parent.parent) {
      treeNode = treeNode.parent.parent;
      path = treeNode.label + (path ? '.' + path : '');
    }
    return path;
  }

  static getSolutionTypeEnumFromMember(member: CenterTypeMember) {
    switch (member.nativeType) {
      case 'System.Boolean':
        return SolutionTypeEnum.Bool;
      case 'System.Decimal':
        return SolutionTypeEnum.Float;
      case 'System.Double':
        return SolutionTypeEnum.Double;
      case 'System.Int32':
        return SolutionTypeEnum.Int;
      case 'System.Int64':
        return member.specialMemberType === 'DateTime' ?
          SolutionTypeEnum.DateTime :
          SolutionTypeEnum.Int;
      case 'System.String':
        return member.specialMemberType === 'GuidId' ?
          SolutionTypeEnum.Guid :
          SolutionTypeEnum.String;
      default:
        console.error('Need to map this nativeType to SolutionTypeEnum: ' + member.nativeType);
        return GuidUtils.emptyGuid();
    }
  }

  static getIsStructuralResolveValue(treeNode: TreeNode) {
    let result = false;
    while (treeNode.parent && treeNode.parent.parent && treeNode.parent.parent.parent) {
      treeNode = treeNode.parent.parent;
      result = treeNode.data.typeGuidId === TypeEnum.Site;
      if (result) break;
    }
    return result;
  }

  buildSubMemberNode(treeNode: TreeNode) {
    if (treeNode.data.relation) {
      return {
        fromTypeGuidId: treeNode.parent.parent.data.typeGuidId,
        toTypeGuidId: treeNode.data.typeGuidId,

        dataMemberId: 0,
        dataMemberGuidId: GuidUtils.emptyGuid(),

        subMemberType: SolutionTypeSubMemberType.Relation,

        relationCollection: treeNode.data.relation && treeNode.data.relation.collectionRelation ? true : false,
        relationGuidId: treeNode.data.relation.typeTreeRelationGuidId,
      } as SolutionTypeSubMember;
    } else if (treeNode.data.collection) {
      return {
        fromTypeGuidId: treeNode.data.typeGuidId,
        toTypeGuidId: GuidUtils.emptyGuid(),

        dataMemberId: this.$memberId || 0,
        dataMemberGuidId: this.guidId,

        subMemberType: SolutionTypeSubMemberType.Collection,

        relationCollection: false,
        relationGuidId: GuidUtils.emptyGuid(),
      } as SolutionTypeSubMember;
    } else if (treeNode.data.subType) {
      return {
        fromTypeGuidId: treeNode.parent.parent.data.typeGuidId,
        toTypeGuidId: treeNode.data.typeGuidId,

        dataMemberId: 0,
        dataMemberGuidId: GuidUtils.emptyGuid(),

        subMemberType: SolutionTypeSubMemberType.Subcast,

        relationCollection: false,
        relationGuidId: GuidUtils.emptyGuid(),
      } as SolutionTypeSubMember;
    } else if (treeNode.data.site) {
      return {
        fromTypeGuidId: treeNode.data.typeGuidId,
        toTypeGuidId: GuidUtils.emptyGuid(),

        dataMemberId: this.$memberId || 0,
        dataMemberGuidId: this.guidId,

        subMemberType: SolutionTypeSubMemberType.Site,

        relationCollection: false,
        relationGuidId: GuidUtils.emptyGuid(),
      } as SolutionTypeSubMember;
    } else if (treeNode.data.tick) {
      return {
        fromTypeGuidId: treeNode.data.typeGuidId,
        toTypeGuidId: GuidUtils.emptyGuid(),

        dataMemberId: this.$memberId || 0,
        dataMemberGuidId: this.guidId,

        subMemberType: SolutionTypeSubMemberType.Tick,

        relationCollection: false,
        relationGuidId: GuidUtils.emptyGuid(),
      } as SolutionTypeSubMember;
    } else {
      return {
        fromTypeGuidId: treeNode.data.typeGuidId,
        toTypeGuidId: GuidUtils.emptyGuid(),

        dataMemberId: this.$memberId || 0,
        dataMemberGuidId: this.guidId,

        subMemberType: SolutionTypeSubMemberType.Data,

        relationCollection: false,
        relationGuidId: GuidUtils.emptyGuid(),
      } as SolutionTypeSubMember;
    }
  }
}
