import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridUtils } from 'app/shared/utils';


@Component({
  selector: 'lc-grid-cellrenderer-text',
  templateUrl: 'grid-cellrenderer-text.component.html',
  styleUrls: ['grid-cellrenderer-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererText implements AgRendererComponent {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  get isEditable(): boolean {
    return GridUtils.getParamValue(this.params, this.params.colDef?.editable);
  };
  params: any;

  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {

  }

  agInit(params): void {
    this.params = params;

    this.params.cellClass = GridUtils.getParamValue(this.params, this.params.cellClass);
    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.iconLeft = GridUtils.getParamValue(this.params, this.params.iconLeft);
    this.params.iconLeftStyle = GridUtils.getParamValue(this.params, this.params.iconLeftStyle);
    this.params.iconRight = GridUtils.getParamValue(this.params, this.params.iconRight);
    this.params.iconRightStyle = GridUtils.getParamValue(this.params, this.params.iconRightStyle);
    this.params.isLink = GridUtils.getParamValue(this.params, this.params.isLink);
    this.params.tooltip = GridUtils.getParamValue(this.params, this.params.tooltip);
    this.params.valueFormatted = GridUtils.getParamValue(this.params, this.params.valueFormatted);

    if (this.params.isDate) {
      this.value = this.params.value ? new Date(this.params.value) : undefined;
    } else if (this.params.valueFormatted) {
      this.value = this.params.valueFormatted;
    } else if (this.params.value && this.params.translate) {
      this.value = this.translateService.instant(this.params.value);
    } else {
      this.value = this.params.value != null ? this.params.value : '';
    }
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  onClick(ev: Event) {
    if (this.params?.click) {
      ev.stopPropagation();

      this.params?.click && !this.params?.disabled ? this.params.click(this.params, ev) : null;
    }
  }
  
  onDoubleClick(ev: Event) {
    if (this.params?.doubleClick) {
      ev.stopPropagation();

      this.params?.doubleClick && !this.params?.disabled ? this.params.doubleClick(this.params, ev) : null;
    }
  }

}
