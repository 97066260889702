import { WebObject } from './web-base/web-object.model';

export enum DeviceSolutionSetStatus {
  Unknown = 0,
  Test = 1,
  Pilot = 2,
  Production = 3,
  Decomissioned = 4,
  Archived = 5,
  Internal = 99,
}

export class DeviceSolutionSetV2 extends WebObject {

  members: {
    name: string;
    standard: boolean;
    status: DeviceSolutionSetStatus;
    comments: string;
    runCompiled: boolean;
    sysVersion: number;
    version: string;
    versionType: string;
    versionDateTime: string;
    testDateTime: string;
    pilotDateTime: string;
    productionDateTime: string;
    deviceSolutionTick: number;
    deviceSolutionRefreshObserveMode: any;
    liveDeviceMode: any;
    invalid: any;
    clientOverrideShow: boolean;
    clientColor: string;
  }

  constructor(item?: Partial<DeviceSolutionSetV2>) {
    super(item);
  }

}
