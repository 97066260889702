import { Tag } from '../../../shared/models/tag.model';
import { WorkspaceWidgetSetting } from './workspace-widget-setting.model';
import { WebObject } from './web-base/web-object.model';

export class WorkspaceWidget extends WebObject {

  static readonly WidgetTemplateMap = {
    '55e75b12-869f-477b-99ef-f78b18659831': 'active-solution',
    '90656b88-6404-4151-bf71-cd203c17b010': 'advanced-search',
    'f2e036e1-faec-4f17-a883-5b1d76b018bd': 'article-system-admin',
    '5de5bf23-2b70-4674-95f5-6407c1921730': 'barcode-generator',
    '9ab1ee98-2144-4116-8a17-a8a0bb6f3e5b': 'base-system-article-import',
    '47d2756f-c5d9-4279-a12d-f2b3bbeceb8f': 'base-system-gs1-setup',
    '939de4b9-06dd-4ed2-b1c1-c6f0f84f307f': 'day-segments',
    '4eb3e69b-648b-42f0-b367-3601b2f6183b': 'device-solution-set',
    '0f741226-f2e5-4291-8204-dd507cc5d212': 'device-solution-set-admin',
    'effed7c7-cc58-41de-bd2a-83da419d37f7': 'forecast',
    'dfbdbe47-50f8-4357-8355-fa07f7dd105d': 'gps-devices',
    '11fb011c-6b0a-4e74-970d-b0bcf80409a5': 'inventory-order-review-tool',
    '726297ba-430b-4f56-b44d-8607ef644d2b': 'label-templates-admin',
    'f1a3b935-97a6-4178-a1e6-c59d72848ef0': 'new-devices',
    '37b34f91-fe4d-4c9f-8be9-b14dade1ef23': 'object-list',
    'bbd43501-4870-4cff-b689-e00391cfd514': 'object-view',
    '4cab5cff-2c03-4636-99d9-bf3718391976': 'order-system-admin',
    '646163a4-ea2f-4adb-8ac1-a4d7d083e3d2': 'mission-studio',
    '775d54cb-c922-4a25-828c-610d367b71e2': 'pick-system',
    '3eb0a8f1-3a8f-40c7-ae40-a2145fccda4d': 'pick-system-setup',
    '485eae17-5cce-4f86-8b2a-7ad9a136b300': 'pick-system-picklist-types',
    'eb01d201-6781-4ae2-a2c3-f470cae4b7fb': 'planner',
    '0f204071-5002-4f40-a327-62e0c072c71b': 'platform-report',
    '06029202-e26f-427f-b051-0be3673fa858': 'preem-orders',
    '85eff122-ba53-4871-a186-2d95843930c3': 'production-planner',
    '6b244266-b0d7-4281-959f-fe28ea5336f7': 'production-template-planner',
    'a0f139bc-538c-4b2f-83dd-539c2b81a511': 'products',
    '34153e35-dd65-429d-9dcf-24100c086111': 'queue-state',
    'dd3b0cd4-0dcf-4297-978a-f29f58fe4708': 'resources-folder',
    '71eb84c3-8150-47a7-80a0-baf533dcda3a': 'smart-image-admin',
    '870512bf-7336-4fb4-8d5b-bb5ad82b6d40': 'solution-event-system',
    '3f546bfb-4cfd-4e62-860c-3f32513bc66d': 'solution-profile-admin',
    'a0fb3fea-4ae8-4eb0-a080-02ef142a4fff': 'solution-profile-v1',
    '90bb5ba5-33ca-444b-9a60-8b07aaa2991a': 'structural-admin',
    'fb275ddc-f936-4fc2-9db2-c1cc50ffaef0': 'tanks',
    'b72ebb9f-8b71-441e-8b3d-1c877a7da597': 'tank-gauge',
    'dd4ebb28-aa4d-455d-b630-2d46ff7b2e2c': 'task-system-admin',
    '481f92e0-6495-4dc7-aefd-5f73a37088f4': 'task-manager',
    '174f7b0d-e570-44c0-84d1-9ca3cfa53296': 'tasks',
    'f0f1596c-be5a-439a-9416-e7bb50207b51': 'topfuel-orders',

    '218651dc-7e10-4427-9602-8c4b2571571e': 'user-text',
    'd13d4e44-02e3-4547-b12c-36b80d39bf88': 'view-system',
    'f44fc5a8-e815-48c7-9d53-7469b3bfabe9': 'visma-net-integration',

    '77b89367-7eb8-4229-a81f-33d44a1ad84f': 'warehouse-admin',
    '0c6315ba-25b7-4d5d-b7fa-aae5884287ef': 'warehouse-setup',
    '8f336ba0-bda9-453a-a64c-2a522ab6dbb7': 'web-device',
    '22275a3b-17fb-4a5b-911b-bea3dfbd6dce': 'web-device-group',
    '99136717-305a-4576-9fd1-eb15f6a92b63': 'weekly-index',
    
    'fd3d6b79-dfe6-4439-aa2f-65988602e457': 'workspace-navigator',

    '4db97955-eed7-46b8-b183-eaa3aed597ce': 'work-system-admin',
    'a9ef7039-7f87-40c2-8892-dffe775583a8': 'work-items',
    'b398c70d-b6c0-4ec1-a86b-9e9df5f5d507': 'work-item-new',
  };

  guidId: string;
  // TODO: remove these as they'll be replaced by the new web object "members" field
  tags: Tag[];
  settings: WorkspaceWidgetSetting[];
  name: string;
  widgetTemplateGuidId: string;
  sysWidgetName: string;
  sysWidgetVersion: number;
  workspaceWidgetSettingJson: any;
  readOnly: boolean;
  parentLevel: number;

  members: {
    tags: Tag[];
    settings: WorkspaceWidgetSetting[];
    name: string;
    widgetTemplateGuidId: string;
    sysWidgetName: string;
    sysWidgetVersion: number;
    workspaceWidgetSettingJson: any;
    readOnly: boolean;
    parentLevel: number;
  }

  // gridster fields
  x: number;
  y: number;
  rows: number;
  cols: number;

  // widget bar
  favoriteBarSortOrder: number;
  widgetBarSortOrder: number;

  $dirty: boolean;
  $ephemereal: boolean;
  $widgetTemplateModuleName: string;

  constructor(item?: Partial<WorkspaceWidget>) {
    super(item);

    this.members = this.members || {} as any;

    this.settings = (this.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });
    this.members.settings = (this.members.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });

    const widgetSettingsJson = JSON.parse(this.workspaceWidgetSettingJson || this.members.workspaceWidgetSettingJson || '{}');
    
    if (!this.cols && !this.rows) {
      this.cols = widgetSettingsJson.cols;
      this.rows = widgetSettingsJson.rows;
      this.x = widgetSettingsJson.x;
      this.y = widgetSettingsJson.y;
    }

    this.favoriteBarSortOrder = widgetSettingsJson.favoriteBarSortOrder;
    this.widgetBarSortOrder = widgetSettingsJson.widgetBarSortOrder;

    this.$widgetTemplateModuleName = WorkspaceWidget.WidgetTemplateMap[this.widgetTemplateGuidId || this.members.widgetTemplateGuidId] || '';
  }

  getSettingValueByName(name: string) {
    const setting = this.getSettingByName(name) || {} as WorkspaceWidgetSetting;
    return setting.$value;
  }

  getSettingByName(name: string) {
    const setting = (this.settings || []).find((s: WorkspaceWidgetSetting) => {
      return s.name === name;
    });
    return setting;
  }

  getSettingsAsKeyValueObject() {
    const settings = {};
    for (const setting of this.settings || []) {
      settings[setting.name] = setting.$value;
    }
    return settings;
  }

  updateWorkspaceWidgetSettingJson() {
    const widgetSettings = {
      cols: this.cols,
      rows: this.rows,
      x: this.x,
      y: this.y,
      widgetBarSortOrder: this.widgetBarSortOrder,
      favoriteBarSortOrder: this.favoriteBarSortOrder,
    };

    this.workspaceWidgetSettingJson = JSON.stringify(widgetSettings);
  }

}
