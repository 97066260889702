import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DesignTreeResponse, DictString } from '../../models';
import { StorageUtils } from '../../utils';
import { ApiCenterService } from '../api/api-center.service';
import { DesignTreeRequest } from './design-tree-request.model';


/**
 * this is different to /designtreedata in the way it returns data. This one return designTrees
 */
@Injectable({
  providedIn: 'root'
})
export class DesignTreeService {
  private readonly URL_SUFFIX = 'api/v0/designtree';

  constructor(
    private apiService: ApiCenterService,
  ) { }

  get(
    designTreeGuidId: string,
    filterSiteGuidId: string,
    filterLevels: number,
    typeGuidIds: string[],
    environmentTypeGuidIds: DictString<string[]>,
  ): Observable<DesignTreeResponse> {
    typeGuidIds = typeGuidIds || [];

    let request: DesignTreeRequest = {
      designTreeGuidId: designTreeGuidId,
      filterSiteGuidId: filterSiteGuidId,
      filterLevels: filterLevels,
      designTreeTick: 0,
      typeGuidIds: typeGuidIds,
      environmentTypeGuidIds: environmentTypeGuidIds,
    };

    const cacheKey = 'designtree_' + filterLevels + '_' + typeGuidIds.length + '_' + designTreeGuidId;
    const cachedDTR = JSON.parse(StorageUtils.getItem('session', cacheKey) || '{}');
    if (cachedDTR && cachedDTR.webTreeSources && cachedDTR.webTreeSources.length) {
      request.designTreeTick = cachedDTR.webTreeSources[0].tick;
    }

    return this.makeRequest(request, cacheKey, cachedDTR);
  }

  private makeRequest(request: DesignTreeRequest, cacheKey: string, cachedDTR: DesignTreeResponse): Observable<DesignTreeResponse> {
    return this.apiService.post<DesignTreeResponse>(this.URL_SUFFIX, request)
    .pipe(
      map((dtr: DesignTreeResponse) => {
        if (dtr) {
          StorageUtils.setItem('session', cacheKey, dtr);

          return dtr;
        } else {
          return cachedDTR;
        }
      })
    );
  }

}
