import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { TreeComponentModule } from 'app/shared/components/tree/tree.component.module';
import { DialogModule } from 'primeng/dialog';
import { SelectSiteDialog } from './select-site.dialog';



@NgModule({
  declarations: [
    SelectSiteDialog,
  ],
  exports: [
    SelectSiteDialog,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    DialogModule,
    TreeComponentModule,
  ],
  providers: [],
})
export class SelectSiteDialogModule { }

