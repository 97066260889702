
import { Injectable } from '@angular/core';
import { WebFailedReasonResponse, WebLoadMap, WebResponse } from 'app/center-v2/core/models';
import { ApiCenterV2Service } from 'app/shared/services/api/api-center-v2.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PickSystemService {

  private urlSuffixPlaceholder = 'centerv2/templatesolution/picksystem/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  resortFree(picklistTypeGuidId: string, picklistOrderGuidId: string, userSortOrder: number, webLoadMap: WebLoadMap): Observable<WebResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'resortfree'),
    {
      picklistTypeGuidId: picklistTypeGuidId,
      picklistOrderGuidId: picklistOrderGuidId,
      userSortOrder: userSortOrder,
      web2LoadMap: webLoadMap,
    })
    .pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  releasePicklist(picklistGuidId: string, force?: boolean): Observable<WebFailedReasonResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'picklist/releasepicklist'),
    {
      picklistGuidId: picklistGuidId,
      force: force,
    })
    .pipe(
      map((response: any) => {
        return response ? new WebFailedReasonResponse(response) : null;
      })
    );
  }

  forceCompletePicklistOrder(picklistOrderGuidId: string, addBox?: boolean): Observable<any> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'picklist/forcecompletepicklistorder'),
    {
      picklistOrderGuidId: picklistOrderGuidId,
      addBox: addBox,
    });
  }

  removePicklistOrder(picklistOrderGuidId: string, force: boolean): Observable<WebFailedReasonResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'picklist/removepicklistorder'),
    {
      picklistOrderGuidId: picklistOrderGuidId,
      force: force,
    })
    .pipe(
      map((response: any) => {
        return response ? new WebFailedReasonResponse(response) : null;
      })
    );
  }

  removeCustomerOrder(customerOrderGuidId: string, force: boolean): Observable<WebFailedReasonResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'order/removecustomerorder'),
    {
      customerOrderGuidId: customerOrderGuidId,
      force: force,
    })
    .pipe(
      map((response: any) => {
        return response ? new WebFailedReasonResponse(response) : null;
      })
    );
  }

  unblockPicklistOrder(picklistTypeGuidId: string, picklistOrderGuidId: string): Observable<WebFailedReasonResponse> {
    return this.apiService.post<any>(
      this.urlSuffixPlaceholder.replace('{what}', 'picklisttype/unblockpicklistorder'),
    {
      picklistTypeGuidId: picklistTypeGuidId,
      picklistOrderGuidId: picklistOrderGuidId,
    })
    .pipe(
      map((response: any) => {
        return response ? new WebFailedReasonResponse(response) : null;
      })
    );
  }

}
