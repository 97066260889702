import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse, DataSource, DictString, TreeSource } from '../../../../shared/models';
import { ApiCenterService } from '../../../../shared/services';


@Injectable({
  providedIn: 'root'
})
export class SearchTreeService {
  private urlSuffix = 'api/v0/searchtree';

  constructor(private apiService: ApiCenterService) { }

  search(
    designTreeGuidId: string,
    typeGuidIds: string[],
    query: string,
    environmentTypeGuidIds: DictString<string[]>,
  ): Observable<DataResponse> {
    return this.apiService.post<DataResponse>(
      this.urlSuffix,
    {
      designTreeGuidId: designTreeGuidId,
      typeGuidIds: typeGuidIds,
      searchString: query,
      environmentTypeGuidIds: environmentTypeGuidIds,
    })
    .pipe(
      map((dr: DataResponse) => {
        if (dr.webDataSources && dr.webDataSources.length) {
          dr.webDataSources = plainToClass(DataSource, dr.webDataSources);
          dr.webDataSources.forEach((ds: DataSource) => {
            ds.values = ds.getValuesFlattened();
          });
        }

        if (dr.webTreeSources && dr.webTreeSources.length) {
          dr.webTreeSources = plainToClass(TreeSource, dr.webTreeSources);
        }

        return dr;
      })
    );

  }
}
