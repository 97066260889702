<p-dialog *ngIf="title"
  appendTo="body"
  [styleClass]="'select-site-dialog ' + (isVisible ? 'flex' : '')"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">
  <p-header>
    <span>
      {{ title }}
    </span>

    <lc-button *ngIf="rootObjectGuidId"
      [label]="'Search' | translate"
      (click)="searchTree($event)"
      type="clear">
    </lc-button>
  </p-header>

  <lc-tree #tree
    adminMode="false"
    [rootObjectGuidId]="rootObjectGuidId"
    [displayInTreeTypeGuidIds]="[]"
    [(selectedTreeNode)]="selectedTreeNode">
  </lc-tree>

  <p-footer>
    <div class="flex-1"></div>

    <lc-button
      [label]="'Cancel' | translate"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [label]="'Ok' | translate"
      (click)="submit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
