<div *ngIf="params"
  class="cell-wrapper flex-row middle">

  <lc-form-field *ngIf="!params?.daySegments"
    class="cell-field flex-1"
    [class.changed]="!params.disabled && !params.data[colDate].isTemplatePlanDayArticle && (!params.data[colDate].hasTemplatePlanDayArticle || params.data[colDate].templatePlanDayArticle?.members?.quantity !== params.value.quantity)"
    [disabled]="params.disabled"
    [editMode]="true"
    (keydown.tab)="$event.stopPropagation()"
    [maxNumber]="params.maxNumber"
    [minNumber]="params.minNumber"
    [(ngModel)]="params.value.quantity"
    (ngModelChange)="quantityValueChanged.next()"
    [options]="params.options"
    type="number">
  </lc-form-field>

  <lc-form-field *ngFor="let ds of params?.daySegments || []; let i = index"
    class="cell-field flex-1"
    [class.changed]="params.value.daySegments[ds.guidId] ? params.value.daySegments[ds.guidId].members?.changed : false"
    [disabled]="params.disabled"
    [editMode]="true"
    (keydown.tab)="$event.stopPropagation()"
    [maxNumber]="100"
    [minNumber]="0"
    [ngModel]="params.value.daySegments[ds.guidId] ? (params?.isPercentage ? params.value.daySegments[ds.guidId].members?.templateSegmentPercentage : params.value.daySegments[ds.guidId].members?.segmentQuantity) : undefined"
    (ngModelChange)="daySegmentValueChanged.next({ guidId: ds.guidId, oldValue: params.value.daySegments[ds.guidId], newValue: $event, index: i })"
    [placeholder]="params?.isPercentage ? 100 / params.daySegments.length : ''"
    [pTooltip]="ds.members?.name"
    [type]="'number'">
  </lc-form-field>

  <div *ngIf="params.disabled"
    class="icon-none">
  </div>

  <i *ngIf="!params.disabled && params?.data"
    class="icon-info far fa-info-circle"
    (click)="showPlannedSourceDayArticlesInfo($event)">
  </i>
</div>

<p-overlayPanel
  appendTo="body"
  [showCloseIcon]="true"
  (onHide)="overlayPanelHide()"
  (onShow)="overlayPanelShow()">
  <ng-template pTemplate>
    <div class="flex-col"
      style="width: 320px;">
      <div class="flex-row">
        <lc-form-field class="flex-1"
          [disabled]="true"
          [editMode]="false"
          [label]="'Planned Qty' | translate"
          [ngModel]="params.data[colDate].plannedQuantity | number:'1.0-2'"
          type="number">
        </lc-form-field>

        <lc-form-field *ngIf="params.data[colDate].hasTemplatePlanDayArticle"
          class="flex-1"
          [disabled]="true"
          [editMode]="false"
          [label]="'Template Qty' | translate"
          [labelIconRight]="params.data[colDate].templatePlanDayArticle?.members?.quantity !== params.data[colDate].quantity ? 'link fad fa-sync-alt' : ''"
          [labelIconRightTooltip]="'Make template quantity same as current quantity' | translate"
          (labelIconRightClick)="syncTemplatePlanDayArticleQuantity()"
          [ngModel]="params.data[colDate].templatePlanDayArticle?.members?.quantity | number:'1.0-2'"
          type="number">
        </lc-form-field>

        <lc-form-field *ngIf="!params.data[colDate].hasTemplatePlanDayArticle"
          class="flex-1"
          [disabled]="true"
          [editMode]="false"
          [label]="'Template' | translate"
          [labelIconRight]="'link fad fa-sync-alt'"
          [labelIconRightTooltip]="'Create article planner template' | translate"
          (labelIconRightClick)="setPlanDayArticleToTemplate()"
          [ngModel]="'Article not in template'"
          type="text">
        </lc-form-field>
      </div>

      <label class="label">
        {{ 'Production' | translate}}:
      </label>

      <div class="flex-row">
        <lc-form-field class="flex-1"
          style="margin-right: 1rem;"
          [disabled]="true"
          [editMode]="false"
          [label]="'Total' | translate"
          [ngModel]="params.data[colDate].plannedTotalProducedQuantity | number:'1.0-2'"
          type="number">
        </lc-form-field>

        <lc-form-field class="flex-1"
          [disabled]="true"
          [editMode]="false"
          [label]="'Avg Day' | translate"
          [ngModel]="params.data[colDate].plannedAvgProducedQuantity | number:'1.0-2'"
          type="number">
        </lc-form-field>
      </div>

      <label class="label">
        {{ 'Wasted' | translate}}:
      </label>

      <div class="flex-row">
        <lc-form-field class="flex-1"
          style="margin-right: 1rem;"
          [disabled]="true"
          [editMode]="false"
          [label]="'Total' | translate"
          [ngModel]="params.data[colDate].plannedTotalProducedWasted | number:'1.0-2'"
          type="number">
        </lc-form-field>

        <lc-form-field class="flex-1"
          [disabled]="true"
          [editMode]="false"
          [label]="'Avg Day' | translate"
          [ngModel]="params.data[colDate].plannedAvgProducedWasted | number:'1.0-2'"
          type="number">
        </lc-form-field>
      </div>

      <lc-form-field class="flex-1"
        [editMode]="true"
        [label]="'Comment' | translate"
        [(ngModel)]="params.data[colDate].comment"
        [textAreaRows]="2"
        type="textarea">
      </lc-form-field>

      <label *ngIf="params.data[colDate].plannedSourceDayArticles?.length"
        class="label">
        {{ 'History' | translate}}:
      </label>

      <div *ngFor="let psda of params.data[colDate].plannedSourceDayArticles || []; let i = index;"
        class="flex-row">
        <lc-form-field class="flex-1"
          style="margin-right: 1rem;"
          [editMode]="false"
          [label]="i === 0 ? ('Date' | translate) : ''"
          [ngModel]="psda.$dayDate"
          type="date">
        </lc-form-field>

        <lc-form-field class="flex-1"
          style="margin-right: 1rem;"
          [editMode]="false"
          [label]="i === 0 ? ('Produced' | translate) : ''"
          [ngModel]="psda.resultProducedQuantity || 0"
          type="number">
        </lc-form-field>

        <lc-form-field class="flex-1"
          [editMode]="false"
          [label]="i === 0 ? ('Wasted' | translate) : ''"
          [ngModel]="psda.resultWastedQuantity || 0"
          type="number">
        </lc-form-field>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>