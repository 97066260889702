import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { FormFieldComponent } from '../../form-field/form-field.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'lc-grid-celleditor-form-field',
  templateUrl: 'grid-celleditor-form-field.component.html',
  styleUrls: ['grid-celleditor-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorFormField implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild(FormFieldComponent) formFieldComponent: FormFieldComponent;

  params: any;

  cellValueChanged: Subject<void>;
  originalValue: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.cellValueChanged = new Subject<any>();
    this.cellValueChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe(() => { this.onCellValueChanged(); });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formFieldComponent.disableKeysPropagation();
      
      if (this.params.colDef.cellEditorFramework === GridCellEditorFormField) {
        this.formFieldComponent.tryToFocusInputElement();
      }
      
      if (this.params.type === 'dropdown') {
        this.formFieldComponent.showPicker();
        this.cdr.markForCheck();
      } else if (this.params.type === 'date') {
        this.formFieldComponent.showPicker();
        this.cdr.markForCheck();
      }
    });
  }

  agInit(params): void {
    this.params = params;

    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.options = GridUtils.getParamValue(this.params, this.params.options);
    this.params.type = GridUtils.getParamValue(this.params, this.params.type);

    this.originalValue = this.params.value;

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  onCellValueChanged() {
    if (this.params.value !== this.originalValue) {
      this.params.newValue = this.params.value;
      this.params.oldValue = this.originalValue;

      if (this.params.colDef?.valueSetter) {
        this.params.colDef.valueSetter(this.params);
      } else {
        let fieldSplit = this.params.colDef.field.split('.');
        const fieldKey = fieldSplit[fieldSplit.length - 1];
        const fieldPath = fieldSplit.slice(0, fieldSplit.length - 1).join('.');
        const fieldParent = fieldPath ? JsonUtils.deepFind(this.params.data, fieldPath) : this.params.data;
        fieldParent[fieldKey] = this.params.value;
      }

      if (this.params?.cellValueChanged) {
        this.params.cellValueChanged(this.params);
      }
    }
    this.originalValue = this.params.value;
  }

}
