import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  private readonly storageNavigationHistoryKey = 'lc_navigation_history';

  private navigationHistory: string[];

  constructor(
    private router: Router,
  ) {

    this.navigationHistory = JSON.parse(sessionStorage.getItem(this.storageNavigationHistoryKey) || '[]');
  }

  goBack() {
    if (this.navigationHistory.length > 1) {
      const lastRoutes = this.navigationHistory.splice(this.navigationHistory.length - 2, 2);

      this.router.navigateByUrl(lastRoutes[0]);

      sessionStorage.setItem(this.storageNavigationHistoryKey, JSON.stringify(this.navigationHistory));
    }
  }

  hasBack() {
    return this.navigationHistory && this.navigationHistory.length > 1;
  }

  routeChanged(url: string): void {
    // this.navigationHistory = this.navigationHistory || [];
    if (
      url.indexOf('/login') >= 0 ||
      url.indexOf('/landing/center') >= 0 ||
      (url.indexOf('/v2/center/workspaces') >= 0 && url.indexOf('/v2/center/workspaces/') < 0)
    ) {
      this.navigationHistory.length = 0;
    }

    const lastRoute = this.navigationHistory.length ? this.navigationHistory[this.navigationHistory.length - 1] : '';
    if (!lastRoute || url.indexOf(lastRoute) < 0) { // using indexOf and not !== to contemplate cases where the query strings are appended but we don't want those appends in the nav stack
      this.navigationHistory.push(url);

      sessionStorage.setItem(this.storageNavigationHistoryKey, JSON.stringify(this.navigationHistory));
    }
  }

}
