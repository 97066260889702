import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { SessionService } from 'app/shared/services/app';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Permission, UserSession } from '../../models';
import { PermissionUtils } from '../../utils';


@Component({
  template: '<div></div>'
})
export abstract class BaseComponentV2 implements OnDestroy {

  private refreshSubject: Subject<void>;

  subscriptions: Subscription[];

  session: UserSession;
  permissions: Permission[];

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sessionService?: SessionService,
  ) {
    this.subscriptions = [];

    this.refreshSubject = new Subject();

    this.addBaseSubscriptions();

    this.updateThisSession(false);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  updateThisSession(andRefresh?: boolean) {
    this.sessionService.get()
    .subscribe((session: UserSession) => {
      this.session = session;

      if (this.session && this.session.permissionProfile) {
        this.permissions = this.session.permissionProfile.permissions;
      } else {
        this.permissions = [];
      }

      if (andRefresh) {
        this.refreshSubject.next();
      }

      this.cdr.markForCheck();
    });
  }

  addBaseSubscriptions() {
    this.subscriptions.push(
      this.refreshSubject.pipe(
        debounceTime(100)
      ).subscribe(() => {
        this.refresh();
      })
    );
  }

  protected refresh() {
    // override in child classes
  }

  protected unsubscribeAll() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
      this.subscriptions.length = 0;

      this.addBaseSubscriptions();
    }
  }

  hasReadAccess(path: string): boolean {
    return PermissionUtils.hasReadAccess(this.permissions, path) ||
           PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  hasWriteAccess(path: string): boolean {
    return PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  getPermission(path: string): Permission {
    return PermissionUtils.getPermission(this.permissions, path);
  }

  getPermissionEnvironmentGuidId(path: string): string {
    const permission = PermissionUtils.getPermission(this.permissions, path);
    return permission ? permission.environmentGuidId : null;
  }

}

