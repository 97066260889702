import { SelectItem } from 'primeng/api';

export class EnumUtils {

  static toSelectItemArray(enumerator: any) {
    const result: SelectItem[] = [];
    for (const key in enumerator) {
      if (typeof enumerator[key] === 'number') {
        result.push({ label: key.replace(/_/g, ' '), value: enumerator[key] });
      }
    }

    if (!result.length) {
      // if no results were returned from the above for loop, it means the enum value is a string...
      // so just return all
      for (const key in enumerator) {
        result.push({ label: key.replace(/_/g, ' '), value: enumerator[key] });
      }
    }
    return result;
  }

  static toStringArray(enumerator: any) {
    const result: string[] = [];
    for (const key in enumerator) {
      if (typeof enumerator[key] === 'number') {
        result.push(enumerator[key]);
      }
    }

    if (!result.length) {
      // if no results were returned from the above for loop, it means the enum value is a string...
      // so just return all
      for (const key in enumerator) {
        result.push(enumerator[key]);
      }
    }
    return result;
  }

}
