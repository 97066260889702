import { SolutionTypeMember, SolutionTypeSubMemberType } from 'app/shared/models';
import { CaseUtils } from 'app/shared/utils';


export class WorkspaceSolutionTypeMember extends SolutionTypeMember {

  fallbackRelationTypeName: string;
  isElevated?: boolean;
  sort?: 'asc' | 'desc' | 'not-sortable';

  constructor(item?: Partial<WorkspaceSolutionTypeMember>) {
    super(item);
  }

  getWeb2ObjectPath() {
    const nameSplit = (this.name || '').split(' ');
    const pathSplit = (this.path || '').split('.');
    let result = '';
    if (pathSplit?.length && !!pathSplit[0]) {
      for (let i = 0; i < pathSplit.length; i++) {
        if (this.subMembers[i].subMemberType === SolutionTypeSubMemberType.Relation) {
          result += `relations.${CaseUtils.camelize(pathSplit[i])}.web2Object.`;
        } else {
          result += `subTypes.${pathSplit[i].replace('Logic', 'Web')}.`;
        }
      }

      if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Relation) {
        result += `relations.${CaseUtils.camelize(nameSplit[nameSplit.length - 1])}.web2Object`;
      } else {
        result += 'members.' + CaseUtils.camelize(nameSplit[nameSplit.length - 1]);
      }
    } else if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Relation) {
      result += `relations.${CaseUtils.camelize(this.name)}.web2Object`;
    } else if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Subcast) {
      result += `subTypes.${this.name.replace('Logic', 'Web')}`;
    } else {
      result += 'members.' + CaseUtils.camelize(this.name);
    }
    return result;
  }

}
