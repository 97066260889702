import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AppService } from '../app/app.service';




@Injectable({
  providedIn: 'root'
})
export class PluginLoaderService {

  private plugins = {
    echarts: {
      loaded: false,
      src: [
        'assets/plugins/echarts/echarts.min.js',
      ],
    },
    leaflet: {
      loaded: false,
      src: [
        'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js',
        'https://unpkg.com/leaflet-draw@1.0.4/dist/leaflet.draw.js',
        'assets/plugins/leaflet/svg-icon.js',
        'assets/plugins/leaflet/paginator.js',
        'assets/plugins/leaflet/gps-trace.js',
        'assets/plugins/leaflet/rastercoords.js',
      ],
    },
    stimulsoft: {
      loaded: false,
      src: [
        'assets/plugins/stimulsoft/stimulsoft.reports.js',
        'assets/plugins/stimulsoft/stimulsoft.viewer.js',
        'assets/plugins/stimulsoft/stimulsoft.designer.js',
      ],
    },
  };

  constructor(
    private appService: AppService,
  ) {

  }

  load(pluginKey: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const plugin = this.plugins[pluginKey];
      if (!plugin.loaded) {
        plugin.loaded = true;
  
        const hostElement = document.getElementById('plugin-scripts');
  
        if (typeof plugin.src === 'string') {
          this.loadScripts(hostElement, [plugin.src], observer);
        } else {
          this.loadScripts(hostElement, plugin.src, observer);
        }
      } else {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

  private loadScripts(hostElement: any, scripts: string[], observer: Observer<boolean>): void {
    if (scripts?.length) {
      this.appService.setBusy(true);

      const src = scripts.splice(0, 1)[0];
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = src;
      scriptElement.onload = () => {
        console.log(src);
        this.loadScripts(hostElement, scripts, observer);
      }
      scriptElement.onerror = (error: any) => {
        console.error(`error loading ${src}`);
        this.appService.setBusy(false);
        observer.next(false);
        observer.complete();
      };
      hostElement.appendChild(scriptElement);
    } else {
      this.appService.setBusy(false);
      observer.next(true);
      observer.complete();
    }
  }
}
